import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  tableCellClasses,
  CircularProgress,
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import SelectField from "components/Select";
import { PrimaryButton } from "components/Buttons";
import Colors from "assets/Style/Colors";
import { FontFamily } from "assets";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import InputField from "components/Input";
import { useForm } from "react-hook-form";
import { ErrorToaster } from "components/Toaster";
import moment from "moment";
import ClientServices from "services/Client";
import { Check, Close } from "@mui/icons-material";
import { useAuth } from "context/UseContext";
import FinanceServices from "services/Finance";
import { SuccessToaster } from "components/Toaster";
import { useNavigate } from "react-router-dom";
import CurrencyServices from "services/Currency";
import { useSelector } from "react-redux";
import ExportFinanceServices from "services/ExportFinance";
import ExportServices from "services/Export";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DatePicker from "components/DatePicker";
import BankServices from "services/Bank";
import SimpleDialog from "components/Dialog/SimpleDialog";
import UploadFile from "components/UploadFile";
import Uploading from "components/Uploading";
import { allowedRolesForCash, CleanTypes, CommaSeparator, getFileSize, numberRegex } from "utils";
import { v4 as uuidv4 } from "uuid";
import instance from "config/axios";
import routes from "services/System/routes";
import Pagination from "components/Pagination";
// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    padding: "15px",
    textAlign: "center",
    whiteSpace: "nowrap",
    backgroundColor: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",

    padding: "5px !important",
    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));

const useStyles = makeStyles({
  loaderWrap: {
    display: "flex",
    height: 100,
    "& svg": {
      width: "40px !important",
      height: "40px !important",
    },
  },
});

function ContainerPayment() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { usdExchangeRate, cadExchangeRate } = useSelector(
    (state) => state.navigationReducer
  );

  // *For Total Amount
  const [totalAmount, setTotalAmount] = useState(0);

  const [allSums, setAllSums] = useState();

  // *For Client Dropdown
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());

  // *for Payment Type

  const [paymentType, setPaymentType] = useState("aed");

  const [cashierDisbled, setCashierDisbled] = useState(false);

  // *For Cashier Account
  const [cashierAccounts, setCashierAccounts] = useState([]);
  const [selectedCashierAccount, setSelectedCashierAccount] = useState(null);

  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const [clientCosting, setClientCosting] = useState([]);
  const [copyClientCosting, setCopyClientCosting] = useState([]);
  const [selectedClientBooking, setSelectedClientBooking] = useState([]);

  const [brokers, setBrokers] = useState([]);
  const [agents, setAgents] = useState([]);

  // *Cashier Details
  const [cashierDetails, setCashierDetails] = useState(false);

  // *For Vin and Lot
  const [vin, setVin] = useState();
  const [selectedVin, setSelectedVin] = useState();
  const [lot, setLot] = useState();
  const [selectedLot, setSelectedLot] = useState();

  // *For Filters
  const [filters, setFilters] = useState({});

  const [showTotal, setShowTotal] = useState(false);

  const [walletBalance, setWalletBalance] = useState();

  const [totalPayment, setTotalPayment] = useState();

  // *wallet Id
  const [walletId, setWalletId] = useState();

  // *For Currencies
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState();

  // *for button Disable
  const [buttonState, setButtonState] = useState(false);

  const [brokerBalance, setBrokerBalance] = useState(0);
  const [agentBalance, setAgentBalance] = useState(0);

  const [totalReceiving, setTotalReceiving] = useState(0);

  const [brokerOptions, setBrokerOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [brokerVault, setbrokerVault] = useState(false);
  const [agentVault, setagentVault] = useState(false);
  // loss
  const [loss, setLoss] = useState(0);
  const [items, setItems] = useState([]);
  const [balances, setBalances] = useState({});
  // *for details
  const [sendDetails, setSendDetails] = useState();

  const [agentId, setAgentId] = useState(null);
  const [brokerId, setbrokerId] = useState(null);
  const [vaultBalance, setVaultBalance] = useState();
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedVaultAccount, setSelectedVaultAccount] = useState(null);
  const [vaultAccounts, setVaultAccounts] = useState([]);
  const [lowBalanceError, setLowBalanceError] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [currencies, setCurrencies] = useState();
  const [exchangeLoss, setExchangeLoss] = useState(0);
  const [openRateDialog, setOpenRateDialog] = useState(false);
  const [exchangeRate, setExchangeRate] = useState();
  const [aedTotalAmount, setAedTotalAmount] = useState(0);
  // *For Accounts
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const classes = useStyles();
  const allowFilesType = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']
  const [progress, setProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [documentDetail, setDocumentDetail] = useState([]);
  const [documentLink, setDocumentLink] = useState('')
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue,
    getValues: getValues2,
    formState: { errors: errors2 },
    reset,
  } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    getValues: getValues3,
    formState: { errors3 },
  } = useForm();
  const tableHead = [
    "Checkbox",
    "Inv.#",
    "Container No.",
    "Name",
    "Applied Status",
    "Net Due",
    "Receiving Amount",
    "Paid",
    "Balance",
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [visibleColumns, setVisibleColumns] = useState([
    ...Array(tableHead.length).keys(),
  ]);

  const handleColumnChange = (event) => {
    const selectedColumns = event.target.value;
    // Sort the selected columns to maintain the correct order
    const sortedColumns = selectedColumns.sort((a, b) => a - b);
    setVisibleColumns(sortedColumns);
  };
  // *For Get Account
  // const getPaymentAccounts = async (search) => {
  //   try {
  //     let params = {
  //       page: 1,
  //       limit: 50,
  //       name: search,
  //     };
  //     const { data } = await ExportFinanceServices.getPaymentAccounts(params);
  //     setAccounts([
  //       ...data?.cashierAccounts?.rows,
  //       ...data?.bankAccounts?.rows,
  //     ]);
  //     setCashierAccounts(data?.cashierAccounts?.rows);
  //   } catch (error) {
  //     ErrorToaster(error);
  //   }
  // };

  // *For Clients
  const getClientDropdown = async () => {
    let params = {
      limit: 1000,
    };
    try {
      const { data } = await ExportServices.getExportCustomers(params);
      setClients(data?.customers?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleAppliedAmountChange = () => {
    handleCalc2();

    if (walletBalance < getValues2("appliedAmountWallet")) {
      setValue("appliedAmountWallet", 0);
    }
    if (isNaN(parseFloat(getValues2("appliedAmountCashier")))) {
      setValue("appliedAmountCashier", "");
    }
    if (isNaN(parseFloat(getValues2("appliedAmountWallet")))) {
      setValue("appliedAmountWallet", "");
    }

    let sum =
      parseFloat(getValues2("appliedAmountCashier") || 0) +
      parseFloat(getValues2("appliedAmountWallet") || 0) +
      parseFloat(getValues2("appliedAmountWallet2") || 0);
    setValue("remainingAmount", parseFloat(totalReceiving) - parseFloat(sum));
    if (parseFloat(sum).toFixed(2) == totalReceiving) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  //*For get Broker
  const getBroker = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 100,
        search: search,
        broker_type: "2",
      };
      const { data } = await ExportServices.getExportCustomers(params);
      setBrokerOptions(data?.customers?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //*For get Agents
  const getAgent = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 100,
        search: search,
        broker_type: "1",
      };
      const { data } = await ExportServices.getExportCustomers(params);
      setAgentOptions(data?.customers?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Vin Lot
  const getVin = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await ClientServices.getTTVin(params);
      const arrayOfObjects = data?.details?.vins.map((value, index) => ({
        id: value?.vin, // Adding 1 to start the id from 1
        name: value?.vin,
      }));
      const arrayOfObjects1 = data?.details?.lots.map((value, index) => ({
        id: value?.lot, // Adding 1 to start the id from 1
        name: value?.lot,
      }));

      setVin(arrayOfObjects);
      setLot(arrayOfObjects1);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Currencies
  const getCurrencies = async (currency) => {
    try {
      let params = {
        detailed: true,
      };
      const { data } = await CurrencyServices.getCurrencies(params);
      setCurrencies(data?.currencies);
      setCurrencyExchangeRate(data.currencies[2].conversion_rate);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Vendor Center
  const getVendorCenter = async () => {
    setLoader(true);
    let params = {
      limit: 1000,
    };
    try {
      const { data } = await ExportServices.getVendorCenter(params);
      setBrokers(data?.brokers);
      setAgents(data?.agents);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  // *For Get Vault Customers
  const getVaultCustomers = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        customer_id: selectedClient?.id,
        type: 'Vehicle'
      };
      const { data } = await FinanceServices.getVaultCustomers(params);
      if (data?.customers?.rows[0]?.accounts?.length > 0) {
        const filterData = data?.customers?.rows[0]?.accounts?.filter(
          (e) => e.unit === "Vehicle"
        );
        // setVaultAccounts(filterData);
      } else {
        // setVaultAccounts([]);
      }
      // let findAccount = data?.customers?.rows[0].accounts.find(
      //   (account) =>
      //     account.type_code === "L2" && account.primary_series === 50005
      // );

      // setWalletBalance(findAccount?.total_cr - findAccount?.total_dr);
      // setWalletId(data?.customers?.rows[0].accounts[1].id);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const getBanks = async () => {
    try {
      let params = {
        page: 1,
        limit: 1000,
      };
      const { data } = await ExportFinanceServices.getBanks(params);
      setBankAccounts(data?.banks?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Payment Accounts

  const getPaymentAccounts = async () => {
    try {
      let params = {
        page: 1,
        limit: 1000,
      };
      const { data } = await ExportFinanceServices.getPaymentAccounts(params);
      setAccounts(
        data?.cashierAccounts?.rows,
      );
      setVaultAccounts(data?.walletAccounts?.rows)

    } catch (error) {
      ErrorToaster(error);
    }
  };
  const [balances1, setBalances1] = useState([]);
  // *For Handle Total Calculation
  const handleCalc = (fieldName, id, currency, val, item, balance) => {
    let sendBalance;
    if (val > 0) {
      setCashierDetails(true);
    } else {
      setCashierDetails(false);
    }

    if (fieldName === "discount") {
      const discountValue = parseFloat(val) || 0;
      const newBalance = parseFloat(item?.price) + parseFloat(item?.other_charges || 0) - parseFloat(item?.discount) - parseFloat(discountValue || 0);
      // Create a new object with 'id' and 'balance' fields
      const updatedBalanceObj = {
        id: id,
        balance: newBalance,
      };
      try {
        const shallowCopy = [...selectedClientBooking];
        const currentIndex = selectedClientBooking.findIndex((e) => e.id === id);
        if (parseFloat(val) > parseFloat(balance)) {
          shallowCopy[currentIndex].applied_amount = parseFloat(balance);
          // setValue(`receiving${id}`, parseFloat(balance).toFixed(2));
        } else {
          shallowCopy[currentIndex].applied_amount = val;
        }
        setSelectedClientBooking(shallowCopy);
      } catch (error) {
        ErrorToaster(error);
      }
      // Store the updated array in a variable
      const updatedBalancesArray = [...balances1, updatedBalanceObj];
      setBalances1(updatedBalancesArray);

      function filterUniqueById(arr) {
        const uniqueObjects = {};

        // Iterate over the array and store the latest object for each id
        arr.forEach((obj) => {
          uniqueObjects[obj.id] = obj;
        });

        // Convert the object back to an array
        const resultArray = Object.values(uniqueObjects);

        return resultArray;
      }
      const resultArray = filterUniqueById(updatedBalancesArray);
      sendBalance = resultArray;

      setBalances((prevBalances) => ({
        ...prevBalances,
        [id]: parseFloat(newBalance).toFixed(2),
      }));
    }

    let indexToUpdate = copyClientCosting.findIndex((obj) => obj.id === id);

    // Update the "amount" key if the uid is found
    if (indexToUpdate !== -1) {
      copyClientCosting[indexToUpdate].amount = val;
    } else {
      console.log(`Object with uid  not found`);
    }

    let Total = copyClientCosting.reduce(
      (acc, obj) => acc + parseFloat(obj.amount),
      0
    );

    setCopyClientCosting(copyClientCosting);
    setTotalReceiving(Total);

    const resultObjects = copyClientCosting.filter((obj) =>
      selectedClientBooking.includes(obj.id)
    );

    const uniqueAgentIds = [
      ...new Set(resultObjects.map((obj) => obj.agent_id)),
    ];

    // Check if there is only one unique agent_id
    if (uniqueAgentIds.length === 1) {
      if (!uniqueAgentIds[0]) {
        setagentVault(false);
      } else {
        let selectedAccount = agents.find(
          (item) => item?.id == uniqueAgentIds[0]
        );
        let account = selectedAccount?.accounts.find(
          (item) => item?.primary_account_id == "11200017"
        );

        setAgentId(account?.id);
        setAgentBalance(
          account?.nature === "credit"
            ? parseFloat(account?.total_cr_cur ? account?.total_cr_cur : 0) -
            parseFloat(account?.total_dr_cur ? account?.total_dr_cur : 0)
            : parseFloat(account?.total_dr_cur ? account?.total_dr_cur : 0) -
            parseFloat(account?.total_cr_cur ? account?.total_cr_cur : 0)
        );
        setValue(
          "walletBalance",
          account?.nature === "credit"
            ? parseFloat(account?.total_cr_cur ? account?.total_cr_cur : 0) -
            parseFloat(account?.total_dr_cur ? account?.total_dr_cur : 0)
            : parseFloat(account?.total_dr_cur ? account?.total_dr_cur : 0) -
            parseFloat(account?.total_cr_cur ? account?.total_cr_cur : 0)
        );
        setagentVault(true);
      }
    } else {
      setagentVault(false);
    }

    const uniqueBrokerIds = [
      ...new Set(resultObjects.map((obj) => obj.broker_id)),
    ];

    // Check if there is only one unique agent_id
    if (uniqueBrokerIds.length === 1) {
      if (!uniqueBrokerIds[0]) {
        setbrokerVault(false);
      } else {
        let selectedAccount = brokers.find(
          (item) => item?.id == uniqueBrokerIds[0]
        );

        let account = selectedAccount?.accounts.find(
          (item) => item?.primary_account_id == "11200017"
        );

        setbrokerId(account?.id);
        setBrokerBalance(
          account?.nature === "credit"
            ? parseFloat(account?.total_cr_cur ? account?.total_cr_cur : 0) -
            parseFloat(account?.total_dr_cur ? account?.total_dr_cur : 0)
            : parseFloat(account?.total_dr_cur ? account?.total_dr_cur : 0) -
            parseFloat(account?.total_cr_cur ? account?.total_cr_cur : 0)
        );
        setValue(
          "walletBalance2",
          account?.nature === "credit"
            ? parseFloat(account?.total_cr_cur ? account?.total_cr_cur : 0) -
            parseFloat(account?.total_dr_cur ? account?.total_dr_cur : 0)
            : parseFloat(account?.total_dr_cur ? account?.total_dr_cur : 0) -
            parseFloat(account?.total_cr_cur ? account?.total_cr_cur : 0)
        );
        setbrokerVault(true);
      }
    } else {
      setbrokerVault(false);
    }

    try {
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const addItem = (item) => {
    setItems([item, ...items]);

    setSendDetails([item, ...items]);
  };
  const handleSelectClientBooking = (item, id) => {

    try {
      const shallowCopy = [...selectedClientBooking];
      const currentIndex = selectedClientBooking.findIndex(
        (e) => e.id === item?.id
      );
      if (currentIndex === -1) {
        shallowCopy.push(item);
      } else {
        shallowCopy.splice(currentIndex, 1);
      }

      setSelectedClientBooking(shallowCopy);

      const updatedItem = { ...item, applied_amount: 0 };
      addItem(updatedItem);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Client Costing
  const getContainerList = async (page, limit, filter) => {
    setLoader(true);
    try {
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const Filter = { ...filters, ...filter };
      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);
      let params = {
        page: Page,
        limit: Limit,
        invoice: true,
      };
      params = { ...params, ...Filter };
      const { data } = await ExportFinanceServices.getContainerPayments(params);

      let totalShippingCharges = 0;
      let totalLateFee = 0;
      let totalStorage = 0;
      let totalCategoryA = 0;
      let totalBrokerFee = 0;
      let totalTitleFee = 0;
      let totalOtherCharges = 0;
      let totalCustomDuty = 0;
      let totalVAT5Percent = 0;
      let totalReceivingAmount = 0;
      let subtotal = 0;
      let total = 0;
      let balance = 0;

      // Iterate through the array and accumulate the shipping charges
      const updatedArray = data?.containers?.rows.map((obj) => ({
        ...obj,
        amount: 0, // Add your new property and its value here
      }));
      setCopyClientCosting(updatedArray);
      data?.vehicles?.rows.forEach((item) => {
        totalShippingCharges += parseFloat(item?.shipping_charges);
        totalLateFee += parseFloat(item?.late_fee);
        totalStorage += parseFloat(item?.storage);
        totalCategoryA += parseFloat(item?.category_a);
        totalBrokerFee += parseFloat(item?.broker_fee);
        totalTitleFee += parseFloat(item?.title_fee);
        totalOtherCharges += parseFloat(item?.other_charge);
        totalCustomDuty += parseFloat(
          item?.custom_duty ? parseFloat(item?.custom_duty) : 0
        );
        totalVAT5Percent += parseFloat(item?.vat ? parseFloat(item?.vat) : 0);
        totalReceivingAmount += parseFloat(item?.receiving_amount);
        subtotal += parseFloat(item?.subtotal);
        balance += parseFloat(item?.invoice?.balance);
        total += parseFloat(item?.total);
      });

      const obj = [
        { value: totalShippingCharges || 0, flag: false },
        { value: totalLateFee || 0, flag: false },
        { value: totalStorage || 0, flag: false },
        { value: totalCategoryA || 0, flag: false },
        { value: totalBrokerFee || 0, flag: false },
        { value: totalTitleFee || 0, flag: false },
        { value: totalOtherCharges || 0, flag: false },

        { value: subtotal || 0, flag: false },
        { value: null || 0, flag: false },
        { value: null || 0, flag: false },
        { value: total || 0, flag: true },
      ];

      setAllSums(obj);

      const shallowCopy = [];
      const clientCostArray = [];

      data?.containers?.rows.forEach((e) => {
        let val =
          parseFloat(e?.shipping_charges) +
          parseFloat(e?.late_fee) +
          parseFloat(e?.storage) +
          parseFloat(e?.category_a) +
          parseFloat(e?.broker_fee) +
          parseFloat(e?.title_fee) +
          parseFloat(e?.other_charge) +
          parseFloat(e?.custom_duty);
        let copyClientCost = {
          costing_id: e?.id,
        };
        let obj = {
          id: e?.id,
          shipping_charges: e?.shipping_charges,
          late_fee: e?.late_fee,
          storage: e?.storage,
          category_a: e?.category_a,
          broker_fee: e?.broker_fee,
          title_fee: e?.title_fee,
          other_charge: e?.other_charge,
          custom_duty: e?.custom_duty,
          balance: e?.invoice?.balance,
        };

        obj.amount = val.toFixed(2);

        if (true) {
          copyClientCost.shipping_charges = e?.shipping_charges;
          copyClientCost.late_fee = e?.late_fee;
          copyClientCost.storage = e?.storage;
          copyClientCost.category_a = e?.category_a;
          copyClientCost.broker_fee = e?.broker_fee;
          copyClientCost.title_fee = e?.title_fee;
          copyClientCost.other_charge = e?.other_charge;
          copyClientCost.custom_duty = e?.custom_duty;
          copyClientCost.balance = e?.invoice?.balance;
        }

        shallowCopy.push(obj);
        clientCostArray.push(copyClientCost);
      });
      reset();
      // setTotalAmount(shallowCopy);

      setClientCosting(data?.containers?.rows);
      setTotalCount(data?.containers?.count);
      getVaultCustomers();
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (selectedClientBooking.length > 0) {
      const exRateDefault = currencies[2]?.conversion_rate
      let totalAmount = 0.00
      selectedClientBooking.forEach(e => {
        if (e?.applied_amount) {
          totalAmount += parseFloat(e?.applied_amount);
        }
      })
      const totalAed = totalAmount * (currencyExchangeRate ? currencyExchangeRate : exRateDefault)
      // const totalExchangeLoss = totalAmount * (currencyExchangeRate ? currencyExchangeRate : exRateDefault) - totalAed;
      const totalExchangeLoss = (exRateDefault - currencyExchangeRate) * totalAmount;
      setTotalAmount(totalAmount)
      setAedTotalAmount(totalAed)
      setExchangeLoss(totalExchangeLoss)

    }
  }, [selectedClientBooking, currencyExchangeRate]);

  // *For Apply Filters
  const applyFilter = async () => {
    try {
      let data = {
        customer_id: selectedClient?.id,
        vendor_id: selectedAgent ? selectedAgent?.id : selectedBroker?.id,
        // vin: selectedVin?.id,
        // lot: selectedLot?.id,
        // container: getValues("container"),
      };
      if (selectedClient || selectedAgent || selectedBroker) {
        getContainerList(1, "", data);
      } else {
        ErrorToaster("Please Select Vendor Or Client");
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleCalc2 = (e) => {
    setLoss(
      parseFloat(
        usdExchangeRate * getValues2("appliedAmountCashier") -
        getValues2("exChangeRate") * getValues2("appliedAmountCashier")
      ).toFixed(2)
    );
  };

  // *For Update client Costing
  const updateClientCosting = async (formData) => {
    const resultObjects = copyClientCosting?.filter((obj) => {
      const isActive = selectedClientBooking.some(selectedItem => {
        if (selectedItem !== null) {
          return selectedItem.id === obj.id;
        } else {
          return selectedItem === obj.id;
        }
      });
      return isActive;
    });
    if (selectedPaymentMethod === null) {
      ErrorToaster('Please select Payment metod')
      return
    }
    if (copyClientCosting?.length === 0) {
      ErrorToaster('Please select data')
      return
    }
    if (selectedVaultAccount?.balance < totalAmount) {
      return ErrorToaster('Your wallet balance is low please recharge your wallet')
    }
    // const resultObjects = copyClientCosting?.filter((obj) =>{
    //  return selectedClientBooking.includes(obj.id)
    // });

    const transformedData = resultObjects.map((item) => ({
      ec_id: item?.id,
      amount: item?.amount,
      container_number: item?.container_number,
      customer_id: item?.customer_id,
      payment_medium: selectedPaymentMethod?.id
    }));

    setLoading(true);
    try {
      let obj = {
        // vault_amount: getValues2("appliedAmountWallet"),
        // cashier_amount: getValues2("appliedAmountCashier"),
        shipping_due: totalReceiving,
        round_off: 0,
        balance_due: totalReceiving,
        customer_id: selectedClient?.id,
        cashier_account_id: selectedCashierAccount?.id,
        // agent_amount: getValues2("appliedAmountWallet") || 0,
        containers: transformedData,
        payment_date: selectedDate,
        ex_loss: exchangeLoss,
        attachment: documentLink
      };
      if (selectedPaymentMethod?.id === "bank") {
        obj.bank_id = selectedBankAccount?.id;
        obj.bank_name = selectedBankAccount?.name;
        obj.bank_account_id = selectedBankAccount?.gwc_account_id;
        obj.bank_amount = totalAmount;
      }
      if (selectedPaymentMethod?.id === "cash") {
        obj.cashier_account_id = selectedCashierAccount?.id;
        obj.account_name = selectedCashierAccount?.name;
        obj.cash_amount = totalAmount;
      }
      if (selectedPaymentMethod?.id === "vault") {
        obj.vault_account_id = selectedVaultAccount?.id;
        obj.account_name = selectedVaultAccount?.name;
        // obj.vault_account_id= walletId;
        obj.agent_vault_id = agentId;
        obj.vault_amount = totalAmount;
      }
      const { message } = await ExportServices.addContainerPayment(obj);
      SuccessToaster(message);

      navigate('/export-container-payment-list')
      let data = {
        container_id: selectedClient?.id,
      };
      getContainerList(1, "", data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };
  // *For Handle Select Vault Detail
  const handleSelectVault = (data) => {
    setSelectedVaultAccount(data);
    const debit = data?.total_dr_cur ? data?.total_dr_cur : 0;
    const credit = data?.total_cr_cur ? data?.total_cr_cur : 0;
    const balance =
      data?.nature === "credit"
        ? parseFloat(credit) - parseFloat(debit)
        : parseFloat(debit) - parseFloat(credit);
    setValue("vaultBalance", parseFloat(balance).toFixed(2));
    setVaultBalance(balance);
  };
  const renderCellContent = (colIndex, item, isActive) => {
    switch (colIndex) {
      case 0:
        return (
          <Checkbox
            checked={isActive}
            onChange={() => handleSelectClientBooking(item, item?.id)}
          />
        );
      case 1:
        return " GEVOTW-" + item?.id ?? "-";
      case 2:
        return item?.container_number ?? "-";

      case 3:
        return item?.customer?.name ?? "-";
      case 4:
        return item?.paid_amount == 0 ? (
          <Box>
            <Box
              sx={{
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${Colors.danger}`,
                borderRadius: "50%",
              }}
            >
              <Close
                sx={{
                  fontSize: "18px",
                  color: Colors.danger,
                }}
              />
            </Box>
            Unpaid
          </Box>
        ) : parseFloat(item?.paid_amount) > 0 &&
          parseFloat(item?.paid_amount) < (parseFloat(item?.price || 0) + parseFloat(item?.other_charges || 0)) ? (

          <Box>
            <Box
              sx={{
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${Colors.danger}`,
                borderRadius: "50%",
              }}
            >
              <Close
                sx={{
                  fontSize: "18px",
                  color: Colors.danger,
                }}
              />
            </Box>
            Unpaid
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${Colors.primary}`,
                borderRadius: "50%",
              }}
            >
              <Check
                sx={{
                  fontSize: "18px",
                  color: "#25ABE1",
                }}
              />
            </Box>
            Paid
          </Box>
        );
      case 5:
        return (
          CommaSeparator(
            (parseFloat(item?.price || 0) + parseFloat(item?.other_charges || 0) - parseFloat(item?.discount || 0)).toFixed(2)
          ) ?? "-"
        ); case 6:
        return isActive ? (
          <InputField
            size={"small"}
            type={"number"}
            defaultValue={0.0}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            register={register2(`${item?.id}-discount`, {
              onChange: (e) => {
                if (
                  e.target.value >
                  parseFloat(item?.price || 0) + parseFloat(item?.other_charges || 0)) {

                  setValue(
                    `${item?.id}-discount`,
                    parseFloat(
                      parseFloat(item?.price || 0) + parseFloat(item?.other_charges || 0) - parseFloat(item?.discount || 0)).toFixed(2)
                  );
                }
                //   handleReceive(
                //     e.target.value,
                //     item?.id,
                //     balances[item?.id] ??
                // parseFloat(item?.price - parseFloat(item?.paid_amount)).toFixed(
                //   2
                // )
                // ),
                handleCalc(
                  "discount",
                  item?.id,
                  item?.shipping_currency,
                  e.target.value,
                  item,
                  (parseFloat(item?.price || 0) + parseFloat(item?.other_charges || 0) - parseFloat(item?.discount || 0)).toFixed(2)
                );
              },
            })}
            inputStyle={{
              width: "100px",
            }}
          />
        ) : (
          0.0 ?? "-"
        );
      case 7:
        return (
          <b>
            USD{" "}
            {item?.paid_amount
              ? CommaSeparator(parseFloat(item?.paid_amount).toFixed(2))
              : parseFloat(0).toFixed(2)}
          </b>
        );
      case 8:
        return (
          <b>
            USD{" "}
            {balances[item?.id] ??
              CommaSeparator(
                (parseFloat(item?.price || 0) + parseFloat(item?.other_charges || 0) - parseFloat(item?.paid_amount || 0) - parseFloat(item?.discount || 0)).toFixed(2)
              )
            }
          </b>
        );

      default:
        return "-";
    }
  };

  const downloadExcel = () => {
    const headers = tableHead?.filter((item) => item !== "Checkbox");
    const rows = clientCosting?.map((item) => [
      " GEVOTW-" + item?.id ?? "-",
      item?.container_number ?? "-",
      item?.customer?.name ?? "-",
      item?.paid_amount == 0
        ? "Unpaid"
        : parseFloat(item?.paid_amount) > 0 &&
          parseFloat(item?.paid_amount) < parseFloat(item?.price)
          ? "Partial"
          : "Paid",
      parseFloat(item?.price).toFixed(2) ?? "-",
      0.0 ?? "-",
      `$ ${item?.paid_amount
        ? parseFloat(item?.paid_amount).toFixed(2)
        : parseFloat(0).toFixed(2)
      }`,
      `USD ${balances[item?.id] ??
      parseFloat(item?.price - parseFloat(item?.paid_amount)).toFixed(2)
      }`,
    ]);
    const totalRow = [
      "",
      "",
      "",
      "",
      "",
      "",
      "Total Due",
      totalReceiving ? totalReceiving : parseFloat(0).toFixed(2),
    ];
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows, totalRow]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(new Blob([buf]), "data.xlsx");
  };

  useEffect(() => {
    if (paymentType) {
      const cashierDetail = cashierAccounts.find(
        (e) => e.currency === paymentType
      );
      setValue("cash", cashierDetail?.name);
      setSelectedCashierAccount(cashierDetail);
    }
  }, [paymentType]);

  // *For Handle Filter
  const handleFilter = (data) => {
    getContainerList(1, "", data);
  };

  useEffect(() => {
    getClientDropdown();
    getVin();
    getBroker();
    getAgent();
    getPaymentAccounts();
    getCurrencies();
    getVendorCenter();
    getBanks()
    getVaultCustomers()
  }, []);
  const handleExchangeRate = () => {
    setOpenRateDialog(false);

    const currentExchangeRate = parseFloat(getValues3('rate'));
    setCurrencyExchangeRate(currentExchangeRate)
    const calculatedLoss = parseFloat((currentExchangeRate * totalAmount - aedTotalAmount).toFixed(2));
    let exLoss;
    if (calculatedLoss === 0) {
      exLoss = 0;
    } else if (currentExchangeRate < currencyExchangeRate) {
      exLoss = Math.abs(calculatedLoss);
    } else {
      exLoss = -Math.abs(calculatedLoss);
    }

    setExchangeLoss(exLoss);
  }

  const handleDate = (newDate) => {
    try {
      if (newDate === 'Invalid Date') {
        setSelectedDate('invalid');
        return;
      }
      setSelectedDate(new Date(newDate));
      //   setValue(new Date(newDate));
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const removeDoc = () => {
    try {
      setDocumentDetail([])
      setDocumentLink('')
      // setValue2('scanned', '')
    } catch (error) {
      ErrorToaster(error)
    }
  }
  const handleUploadDocument = async (e) => {
    try {
      e.preventDefault();
      const file = e.target.files[0];
      let arr = [
        {
          id: uuidv4(),
          name: file?.name,
          file: "",
          type: file?.type.split("/")[1],
          size: getFileSize(file.size),
          isUpload: false,
        },
      ];
      if (allowFilesType.includes(file.type)) {
        setDocumentDetail(arr);
        handleUpload(file, arr);
      } else {
        ErrorToaster(`Only ${CleanTypes(allowFilesType)} formats is supported`);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleUpload = async (file, docs) => {
    setProgress(0);
    try {
      const formData = new FormData();
      formData.append("document", file);
      const { data } = await instance.post(routes.uploadDocuments, formData, {
        onUploadProgress: (progressEvent) => {
          const uploadedBytes = progressEvent.loaded;
          const percentCompleted = Math.round(
            (uploadedBytes * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
          setUploadedSize(getFileSize(uploadedBytes));
        },
      });
      if (data) {
        docs[0].isUpload = true;
        docs[0].file = data?.data?.nations;
        setDocumentDetail(docs);
        setDocumentLink(data?.data?.nations);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  return (
    <Fragment>
      <SimpleDialog
        open={openRateDialog}
        onClose={() => setOpenRateDialog(false)}
        title={"Update Exchange Rate"}

      >
        <Box component="form" onSubmit={handleSubmit3(handleExchangeRate)}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <InputField
                size={"small"}
                label={"Exchange Rate"}
                placeholder={"Exchange Rate"}
                defaultValue={currencyExchangeRate}
                register={register3("rate", {
                  required: "Please enter rate.",
                  // onChange: (e)=>{
                  //   handleExchangeRate()
                  // }
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ mt: 2, textAlign: "right" }}>
              <PrimaryButton title="Save" type="submit" />
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item xs={12} sm={12} md={11}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 4,
              mr: 4,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: Colors.charcoalGrey,
                fontFamily: FontFamily.NunitoRegular,
              }}
            >
              Container Payment
            </Typography>
            {clientCosting?.length > 0 && (
              <PrimaryButton
                title={"Download Excel"}
                onClick={() => downloadExcel()}
              />
            )}
          </Box>
        </Grid>

        <Grid item md={12}>
          <Box
            sx={{
              m: "20px 0 20px 0",
              p: "20px",
              bgcolor: Colors.feta,
              border: `1px solid ${Colors.iron}`,
              borderRadius: "9px",
              gap: 5
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              component={"form"}
              onSubmit={handleSubmit(applyFilter)}
              spacing={3}
            >
              <Grid item xs={12} md={3}>
                <InputField
                  size={"small"}
                  label={"Search"}
                  placeholder={"Search"}
                  register={register("search", {
                    onChange: (e) => handleFilter({ search: e.target.value }),
                  })}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SelectField
                  size="small"
                  label={"Client"}
                  options={clients}
                  selected={selectedClient}
                  onSelect={(value) => {
                    setSelectedClient(value);
                    setSelectedAgent(null);
                    setSelectedBroker(null);
                  }}
                  error={errors?.client?.message}
                  register={register("client")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SelectField
                  size={"small"}
                  label={"Select Agent"}
                  options={agentOptions}
                  onSearch={(v) => getAgent(v)}
                  selected={selectedAgent}
                  onSelect={(value) => {
                    setSelectedAgent(value);
                    setSelectedClient(null);
                    setSelectedBroker(null);
                  }}
                  error={errors?.Agent?.message}
                  register={register("Agent")}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    mt: "12px",
                  }}
                >
                  <PrimaryButton type="submit" title="Search" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item md={11}>
          {clientCosting.length > 0 && (
            <Grid container mb={2}>
              <Grid item xs={5}>
                <FormControl>
                  <InputLabel>Columns</InputLabel>
                  <Select
                    size={"small"}
                    multiple
                    label={"Columns"}
                    value={visibleColumns}
                    onChange={handleColumnChange}
                    renderValue={() => "Show/Hide"}
                  >
                    {tableHead.map((column, index) => {
                      if (
                        column !== "Checkbox" &&
                        column !== "Arrived Date" &&
                        column !== "SHIPPING CHARGE" &&
                        column !== "LATE FEE" &&
                        column !== "STORAGE" &&
                        column !== "CATEGORY A" &&
                        column !== "BROKER FEE" &&
                        column !== "TITLE FEE" &&
                        column !== "OTHER CHARGE" &&
                        column !== "CUSTOM DUTY" &&
                        column !== "VAT" &&
                        column !== "TOTAL" &&
                        column !== "Discount" &&
                        column !== "Net Due" &&
                        column !== "Receiving Amount" &&
                        column !== "Applied Status" &&
                        column !== "Paid" &&
                        column !== "Balance"
                      ) {
                        return (
                          <MenuItem key={index} value={index}>
                            <Checkbox
                              checked={visibleColumns.includes(index)}
                            />
                            <ListItemText primary={column} />
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Box>
            {clientCosting.length > 0 && (
              <TableContainer
                // component={Paper}
                sx={{
                  // boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                  borderRadius: 2,
                  maxWidth: "calc(100vw - 330px)",
                }}
              >
                <Table stickyHeader sx={{ minWidth: 500 }}>
                  <TableHead>
                    <TableRow>
                      {visibleColumns.map((index) => (
                        <Cell
                          key={index}
                          sx={{
                            bgcolor: `${index < 9 ? Colors.primary : Colors.blue
                              } !important`,
                          }}
                        >
                          {tableHead[index]}
                        </Cell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {!loader ? (
                      clientCosting?.length > 0 ? (
                        <Fragment>
                          {clientCosting.map((item, rowIndex) => {
                            const itemId = item?.id;
                            const isActive = selectedClientBooking.some(selectedItem => selectedItem.id === itemId);
                            return (
                              <Row
                                key={rowIndex}
                                sx={{
                                  bgcolor: rowIndex % 2 !== 0 && "#EFF8E7",
                                }}
                              >
                                {visibleColumns.map((colIndex) => (
                                  <Cell key={colIndex}>
                                    {renderCellContent(
                                      colIndex,
                                      item,
                                      isActive
                                    )}
                                  </Cell>
                                ))}
                              </Row>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={visibleColumns.length + 1}
                            align="center"
                            sx={{ fontWeight: 600 }}
                          >
                            No Data Found
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={visibleColumns.length + 2}
                          align="center"
                          sx={{ fontWeight: 600 }}
                        >
                          <Box className={classes.loaderWrap}>
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                    {/* <Row sx={{ bgcolor: "#EEFBEE", p: "10px" }}>
											{[...Array(visibleColumns?.length - 15)].map((_, index) => (
												<Cell key={index} />
											))}
											<Cell>
												<Box
													sx={{
														bgcolor: "#747474",
														border: "1px solid #747474",
														p: "10px 20px",
														textAlign: "center",
														color: Colors.white,
													}}
												>
													Total Due
												</Box>
											</Cell>
											{allSums &&
												allSums.length > 0 &&
												allSums.map((item, index) => (
													<Cell key={index}>
														{item.flag && (
															<Box
																sx={{
																	bgcolor: "#E3E3E3",
																	border: "1px solid #747474",
																	p: "10px 20px",
																	textAlign: "center",
																}}
															>
																{parseFloat(item.value).toFixed(2)}
															</Box>
														)}
													</Cell>
												))}
											<Cell>

												<Box
													sx={{
														bgcolor: "#E3E3E3",
														border: "1px solid #747474",
														p: "10px 20px",
														textAlign: "center",
													}}
												>
													{totalPayment ? totalPayment : 0}
												</Box>

											</Cell>
										</Row> */}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Pagination
              currentPage={currentPage}
              pageSize={pageLimit}
              onPageSizeChange={(size) => getContainerList(1, size.target.value)}
              tableCount={clientCosting.length}
              totalCount={totalCount}
              onPageChange={(page) => getContainerList(page, "")}
            />
            {clientCosting?.length > 0 &&
              <Box sx={{ my: 4, py: 2, bgcolor: Colors.whiteSmoke }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2.5}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: FontFamily.NunitoRegular }}
                      >
                        Total
                      </Typography>
                      <Box
                        sx={{
                          textAlign: "center",
                          p: 1,
                          width: "130px",
                          bgcolor: Colors.flashWhite,
                          border: "1px solid #B2B5BA",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: Colors.smokeyGrey }}
                        >

                          {CommaSeparator(parseFloat(totalAmount || 0)?.toFixed(2))}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: FontFamily.NunitoRegular }}
                      >
                        Exchange Rate to AED
                      </Typography>
                      <Box
                        sx={{
                          textAlign: "center",
                          p: 1,
                          width: "130px",
                          bgcolor: Colors.white,
                          border: "1px solid #B2B5BA",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          onClick={() => setOpenRateDialog(true)}
                          sx={{ cursor: "pointer", color: Colors.smokeyGrey }}
                        >
                          {currencyExchangeRate
                            ? parseFloat(currencyExchangeRate)?.toFixed(3)
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: FontFamily.NunitoRegular }}
                      >
                        Total AED
                      </Typography>
                      <Box
                        sx={{
                          textAlign: "center",
                          p: 1,
                          width: "130px",
                          bgcolor: Colors.flashWhite,
                          border: "1px solid #B2B5BA",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: Colors.smokeyGrey }}
                        >
                          {CommaSeparator(parseFloat(aedTotalAmount || 0).toFixed(2))}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: FontFamily.NunitoRegular }}
                      >
                        Exchange Loss/Gain
                      </Typography>
                      <Box
                        sx={{
                          textAlign: "center",
                          p: 1,
                          width: "130px",
                          bgcolor: Colors.flashWhite,
                          border: "1px solid #B2B5BA",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: Colors.smokeyGrey }}
                        >
                          {exchangeLoss.toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            }
            {/* <Box sx={{ my: 4, py: 2, bgcolor: Colors.whiteSmoke }}>
              <Grid container spacing={1} justifyContent={"flex-end"}>
                <Grid item xs={12} sm={2.5}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontFamily: FontFamily.NunitoRegular }}
                    >
                      Total Due
                    </Typography>
                    <Box
                      sx={{
                        textAlign: "center",
                        p: 1,
                        width: "130px",
                        bgcolor: Colors.flashWhite,
                        border: "1px solid #B2B5BA",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ color: Colors.smokeyGrey }}
                      >
                        {totalReceiving
                          ? totalReceiving
                          : parseFloat(0).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
          </Box>
        </Grid>
        {clientCosting?.length > 0 &&
          <Box
            component={"form"}
            sx={{ my: 4 }}
            // onSubmit={handleSubmit()}
            style={{ width: "100%" }}
            mx={9}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3.3}>
                <SelectField
                  size={"small"}
                  label={"Payment Method"}
                  options={[
                    {
                      id: "cash",
                      name: "Cash",
                    },
                    {
                      id: "bank",
                      name: "Bank",
                    },
                    {
                      id: "vault",
                      name: "Wallet",
                    },
                  ]}
                  selected={selectedPaymentMethod}
                  onSelect={(value) => setSelectedPaymentMethod(value)}
                  error={errors?.paymentMethod?.message}
                  register={register("paymentMethod", {
                    required: "Please select payment method.",
                  })}
                />
              </Grid>
              {selectedPaymentMethod?.id === "cash" && (
                <Fragment>
                  <Grid item xs={12} sm={3}>
                    <InputLabel>Cash in Hand</InputLabel>
                    <FormControl>
                      <RadioGroup
                        row
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                      >
                        <FormControlLabel
                          value="aed"
                          control={<Radio />}
                          label="AED"
                        />
                        <FormControlLabel
                          value="usd"
                          control={<Radio />}
                          label="USD"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SelectField
                      disabled={allowedRolesForCash.includes(user?.role_id) ? true : false}
                      size={"small"}
                      label={"Cashier Account"}
                      options={accounts}
                      selected={selectedCashierAccount}
                      onSelect={(value) => setSelectedCashierAccount(value)}
                      error={errors2?.cash?.message}
                      register={register2("cash", {
                        required: cashierDisbled
                          ? "Please select cashier."
                          : false,
                      })}
                    />
                  </Grid>
                </Fragment>
              )}
              {selectedPaymentMethod?.id === "bank" && (
                <Grid item xs={12} sm={3}>
                  <SelectField
                    size={"small"}
                    label={"Bank Account"}
                    options={bankAccounts}
                    selected={selectedBankAccount}
                    onSelect={(value) => setSelectedBankAccount(value)}
                    error={errors?.bank?.message}
                    register={register("bank", {
                      required:
                        selectedPaymentMethod?.id === "bank"
                          ? "Please select bank account."
                          : false,
                    })}
                  />
                </Grid>
              )}
              {selectedPaymentMethod?.id === "vault" && (
                <Fragment>
                  <Grid item xs={12} sm={3}>
                    <SelectField
                      size={"small"}
                      label={"Wallet Account"}
                      options={vaultAccounts}
                      selected={selectedVaultAccount}
                      onSelect={(value) => handleSelectVault(value)}
                      error={errors?.vault?.message}
                      register={register("vault", {
                        required:
                          selectedPaymentMethod?.id === "vault"
                            ? "Please select wallet account."
                            : false,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <InputField
                      disabled={true}
                      size={"small"}
                      label={"Vault Balance (USD)"}
                      placeholder={"Vault Balance(USD)"}
                      register={register("vaultBalance")}
                      value={selectedVaultAccount?.balance !== null ? selectedVaultAccount?.balance : 0}
                    />
                  </Grid>
                </Fragment>
              )}
              {lowBalanceError && (
                <Grid item xs={12} sm={12}>
                  <Typography
                    color="error"
                    sx={{ fontSize: 12, textAlign: "left" }}
                  >
                    Low Balance (please top up your wallet account)
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={3} sm={3}>
                <DatePicker
                  label={'Payment Date'}
                  size='small'
                  value={selectedDate}
                  error={errors?.etaDateVal?.message}
                  register={register('etaDateVal')}
                  onChange={(date) => handleDate(date)}
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <Typography variant="body1" sx={{ color: Colors.charcoalGrey, }}>
                  Attachment
                </Typography>
                <UploadFile
                  custom={true}
                  multiple={true}
                  style={{ backgroundColor: "#e7efeb", width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', padding: '20px', border: '1px dashed #aeb1b0', borderRadius: '10px' }}
                  accept={allowFilesType}
                  error={errors?.scanned2?.message}
                  register={register("scanned2", {
                    // required: "Please attach Decelaration.",
                    onChange: (e) => handleUploadDocument(e)
                  })}
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                {documentDetail.length > 0 &&
                  <Typography variant="body1" sx={{ color: Colors.charcoalGrey, mb: 1 }}>
                    Uploaded Files
                  </Typography>
                }
                <Box sx={{ maxHeight: 300, overflow: 'auto', pr: 1 }}>
                  {documentDetail?.map((item, index) => (
                    <Uploading key={index} data={item} uploadedSize={uploadedSize} progress={progress} removeDoc={() => removeDoc()} />
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField
                  label={"Comment"}
                  multiline={true}
                  rows={2}
                  placeholder={"comment"}
                  register={register("comment")}
                  inputStyle={{ width: "350px" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} sx={{ textAlign: "right" }}>
                <PrimaryButton
                  disabled={
                    selectedClientBooking.length > 0 && !lowBalanceError
                      ? false
                      : true
                  }
                  title="Receive Payment"
                  type="submit"
                  loading={loading}
                  onClick={handleSubmit2(updateClientCosting)}

                />
              </Grid>
            </Grid>
          </Box>
        }
        {/* {cashierDetails && (
          <Grid item md={12} m={4} mt={0}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: Colors.charcoalGrey,
                      fontFamily: FontFamily.NunitoRegular,
                      mt: 4,
                    }}
                  >
                    Cashier
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <SelectField
                    disabled={allowedRolesForCash.includes(user?.role_id) ? true : false}
                    size={"small"}
                    label={"Cashier Account"}
                    options={cashierAccounts}
                    selected={selectedCashierAccount}
                    onSelect={(value) => setSelectedCashierAccount(value)}
                    error={errors2?.cash?.message}
                    register={register2("cash", {
                      required: cashierDisbled
                        ? "Please select cashier."
                        : false,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputField
                    size="small"
                    label={"Applied Amount"}
                    type={"number"}
                    defaultValue={"0.00"}
                    error={errors2?.appliedAmountCashier?.message}
                    register={register2("appliedAmountCashier", {
                      onChange: (e) => {
                        handleAppliedAmountChange();
                        setCashierDisbled(e.target.value);
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputField
                    size="small"
                    disabled={true}
                    label={"Remaining Amount"}
                    type={"number"}
                    defaultValue={"0.00"}
                    error={errors2?.remainingAmount?.message}
                    register={register2("remainingAmount")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePicker
                    label={"payment date"}
                    selected={selectedDate}
                    onChange={handleDateChange}
                  />
                </Grid>
              </Grid>

              {!selectedClient ? (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: Colors.charcoalGrey,
                        fontFamily: FontFamily.NunitoRegular,
                        mt: 4,
                      }}
                    >
                      Wallet
                    </Typography>
                  </Grid>
                  {agentVault && selectedAgent && (
                    <>
                      {" "}
                      <Grid item xs={12} md={3}>
                        <InputField
                          label={"Agent balance "}
                          disabled={true}
                          register={register2("walletBalance", {
                            onChange: (e) => {
                              handleAppliedAmountChange();
                            },
                          })}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputField
                          label={"Applied Amount"}
                          type={"number"}
                          size="small"
                          defaultValue={"0.00"}
                          error={errors2?.appliedAmountWallet?.message}
                          register={register2("appliedAmountWallet", {
                            onChange: (e) => {
                              handleAppliedAmountChange();
                              if (
                                e.target.value > getValues2("walletBalance")
                              ) {
                                setValue("appliedAmountWallet", 0);
                              }
                            },
                          })}
                        />
                      </Grid>{" "}
                    </>
                  )}

                  {brokerVault && selectedBroker && (
                    <>
                      <Grid item xs={12} md={3}>
                        <InputField
                          label={"Broker balance "}
                          disabled={true}
                          register={register2("walletBalance2", {
                            onChange: (e) => {
                              handleAppliedAmountChange();
                            },
                          })}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputField
                          label={"Applied Amount"}
                          type={"number"}
                          size="small"
                          defaultValue={"0.00"}
                          error={errors2?.appliedAmountWallet2?.message}
                          register={register2("appliedAmountWallet2", {
                            onChange: (e) => {
                              handleAppliedAmountChange();
                              if (
                                e.target.value > getValues2("walletBalance2")
                              ) {
                                setValue("appliedAmountWallet2", 0);
                              }
                            },
                          })}
                        />
                      </Grid>{" "}
                    </>
                  )}
                </Grid>
              ) : (
                ""
              )}
            </Box>
            <Grid container sm={12}>
              <Box
                sx={{
                  p: 2,

                  borderRadius: 3,

                  width: "50%",
                }}
              >
                <Grid container>
                  <Grid xs={12}>
                    {" "}
                    <InputField label={"Notes"} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={11}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <PrimaryButton
                  type={"submit"}
                  disabled={!buttonState}
                  onClick={handleSubmit2(updateClientCosting)}
                  title="Update"
                />
              </Box>
            </Grid>
          </Grid>
        )} */}
      </Grid>
    </Fragment>
  );
}

export default ContainerPayment;
