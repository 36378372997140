import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  tableCellClasses,
  InputLabel,
  FormControl,
  TableCell,
  Paper,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import SelectField from "components/Select";
import { PrimaryButton } from "components/Buttons";
import Colors from "assets/Style/Colors";
import { FontFamily } from "assets";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import InputField from "components/Input";
import { Controller, useForm } from "react-hook-form";
import { ErrorToaster } from "components/Toaster";
import moment from "moment";
import ClientServices from "services/Client";
import { Check, Close } from "@mui/icons-material";
import { useAuth } from "context/UseContext";
import FinanceServices from "services/Finance";
import { SuccessToaster } from "components/Toaster";
import { useNavigate } from "react-router-dom";
import CurrencyServices from "services/Currency";
import { useSelector } from "react-redux";
import { CircleLoading } from "components/Loaders";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ExportServices from "services/Export";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CommaSeparator, handleExportWithComponent } from "utils";
import { PDFExport } from "@progress/kendo-react-pdf";
import ExportPDF from "components/Pdf/ExportPdf";
import DatePicker from "components/DatePicker";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    padding: "15px",
    textAlign: "center",
    whiteSpace: "nowrap",
    background: Colors.primary,
    color: Colors.white,
    paddingInline: "18px",

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: "5px !important",

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));

const useStyles = makeStyles({
  loaderWrap: {
    display: "flex",
    height: 100,
    "& svg": {
      width: "40px !important",
      height: "40px !important",
    },
  },
});

function ExportShippingSOA() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { usdExchangeRate, cadExchangeRate } = useSelector(
    (state) => state.navigationReducer
  );
  let TotalBalance = 0;
  // *For Total Amount
  const [totalAmount, setTotalAmount] = useState([]);

  const [allSums, setAllSums] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [totalNetDue, setTotalNetDue] = useState(0);

  // *For Client Dropdown
  const [selectedClient, setSelectedClient] = useState(null);

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  // *for Payment Type

  const [paymentType, setPaymentType] = useState("aed");

  const [totalVCC, settotalVCC] = useState();

  // *For Paid Status
  const [selectedStatus, setSelectedStatus] = useState(null);

  // *For Cashier Account
  const [cashierAccounts, setCashierAccounts] = useState([]);
  const [selectedCashierAccount, setSelectedCashierAccount] = useState(null);

  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(150);
  const [currentPage, setCurrentPage] = useState(1);

  const [shippingSOA, setShippingSOA] = useState();

  const [shippingVault, setShippingVault] = useState();
  const [vehicleVault, setVehicleVault] = useState();
  // const [vehicleStatus, setVehicleStatus] = useState('')
  // const [particularStatus, setParticularStatus] = useState('')

  // *For Vin and Lot
  const [selectedVin, setSelectedVin] = useState();
  const [selectedLot, setSelectedLot] = useState();

  // *For Filters
  const [filters, setFilters] = useState({});
  const [mergedOptions, setMergedOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);

  // Handle Date Filter
  const [fromDate, setFromDate] = useState(moment().subtract(3, 'months').startOf('day').toDate());
  const [toDate, setToDate] = useState(moment().endOf('day').toDate());

  const [totalBalance, setTotalBalance] = useState(0);

  // *For Currencies
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0);
  const [particularStatus, setParticularStatus] = useState({ id: false, name: "Vehicle" });
  const [vehicleStatus, setVehicleStatus] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);
  const [vehicleStatuses, setVehicleStatuses] = useState([])
  const [sortColumn, setSortColumn] = useState('CMR Date')
  const [sortOrder, setSortOrder] = useState("asc")
  const [walletBalance, setWalletBalance] = useState(0)
  // Vehicle-specific statuses
  // const vehicleStatuses = [
  //   { id: false, name: "Booked" },
  //   { id: false, name: "With GWS Yard" },
  //   { id: false, name: "At GWS Port" },
  //   { id: false, name: "Loaded by GWS" },
  //   { id: false, name: "Broker Port" },
  //   { id: false, name: "Broker Border" },
  //   { id: false, name: "On The Way to Destination" },
  //   { id: false, name: "Arrived At Dist." },
  //   { id: false, name: "Handover" },
  // ];

  // Container-specific statuses
  const containerStatuses = [
    { id: false, name: "Booked" },
    { id: false, name: "With GWS Yard" },
    { id: false, name: "At GWS Port" },
    { id: false, name: "Loaded By GWS" },
    { id: false, name: "Handover" },
  ];

  // Handle Particular (Vehicle/Container) selection
  const handleParticularSelect = (value) => {
    setParticularStatus(value);
    setVehicleStatus('');
    if (value?.name == "Vehicle") {
      setStatusOptions(vehicleStatuses);
      setVisibleColumns(vehicleTableHead)
    } else if (value?.name == "Container") {
      setStatusOptions(containerStatuses);
      setVisibleColumns(containerTableHead)

    }
  };
  // *for button Disable
  const [showTable, setShowTable] = useState(false);
  const contentRef = useRef(null);

  const classes = useStyles();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue,
    getValues: getValues2,
    formState: { errors: errors2 },
    reset,
  } = useForm();

  const vehicleTableHead = [
    "SL. No",
    "Invoice #",
    "CMR Date",
    "CMR No.",
    "Model",
    "Make",
    "Vin/Container.#",
    "COLOR",
    "Status",
    "Final Destination",
    // "Final Dest.Date",
    "Applied Status",
    "SHIPPING CHARGE",
    "Other Charges",
    "Discount",
    "Net Due",
    "Paid Amount",
    "Balance Due",
    "Final Paid Date",
    "Past Due Days"
  ];

  const containerTableHead = [
    "SL. No",
    "Invoice #",
    "Vin/Container.#",
    "Status",
    "Final Destination",
    "SHIPPING CHARGE",
    "Other Charges",
    "Discount",
    "Net Due",
    "Paid Amount",
    "Balance Due",
    "Final Paid Date",
    "Past Due Days"
  ];

  const [visibleColumns, setVisibleColumns] = useState([
    ...Array(vehicleTableHead?.length).keys(),
  ]);
  let NoOfVCC = 0;
  useEffect(() => {
    getStatus()
  }, [])
  ///For get Customers
  const getCustomers = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 100,
        name: search,
      };
      const { data } = await ExportServices.getExportCustomers(params);
      const customerOptions = data?.customers?.rows || [];
      setMergedOptions((prev) => [...prev, ...customerOptions]);
      // setCustomersOptions(data?.customers?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //*For get Broker
  const getBroker = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 100,
        search: search,
        broker_type: "2",
      };
      const { data } = await ExportServices.getExportCustomers(params);
      setBrokerOptions(data?.customers?.rows);
      const brokerOptions = data?.customers?.rows || [];
      setMergedOptions((prev) => [...prev, ...brokerOptions]);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //*For get Agents
  const getAgent = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 100,
        search: search,
        broker_type: "1",
      };
      const { data } = await ExportServices.getExportCustomers(params);
      setAgentOptions(data?.customers?.rows);
      const agentOptions = data?.customers?.rows || [];
      setMergedOptions((prev) => [...prev, ...agentOptions]);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Currencies
  const getCurrencies = async (currency) => {
    try {
      let params = {
        detailed: true,
      };
      const { data } = await CurrencyServices.getCurrencies(params);

      setCurrencyExchangeRate(data.currencies[2].conversion_rate);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Client Costing
  const getClientCosting = async (page, limit, filter) => {
    setLoader(true);
    try {
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const Filter = { ...filters, ...filter };
      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);
      let params = {
        page: Page,
        limit: 20000,
        invoice: true,
      };
      params = { ...params, ...Filter };
      const { data } = await ExportServices.getExportVehicles(params);
      setPageLimit(data?.vehicles?.count);
      settotalVCC(0);
      let totalShippingCharges = 0;
      let totalLateFee = 0;
      let totalStorage = 0;
      let totalCategoryA = 0;
      let totalBrokerFee = 0;
      let totalTitleFee = 0;
      let totalOtherCharges = 0;
      let totalCustomDuty = 0;
      let totalVAT5Percent = 0;
      let totalReceivingAmount = 0;
      let subtotal = 0;
      let total = 0;
      let balance = 0;
      let totalPaidAmount = 0;
      let totalNetDue = 0;

      // Iterate through the array and accumulate the shipping charges

      data?.vehicles?.rows.forEach((item) => {
        if (item?.vehicle?.vcc) {
          NoOfVCC++;
        }
        settotalVCC(NoOfVCC);
        totalShippingCharges += parseFloat(item?.shipping_charges);
        totalLateFee += parseFloat(item?.late_fee);
        totalStorage += parseFloat(item?.storage);
        totalCategoryA += parseFloat(item?.category_a);
        totalBrokerFee += parseFloat(item?.broker_fee);
        totalTitleFee += parseFloat(item?.title_fee);
        totalOtherCharges += parseFloat(item?.other_charge);
        totalCustomDuty += parseFloat(item?.custom_duty);
        totalVAT5Percent += parseFloat(item?.vat);
        totalReceivingAmount += parseFloat(item?.receiving_amount);
        subtotal += parseFloat(item?.subtotal);
        balance +=
          parseFloat(item?.final_price) - parseFloat(item?.vendor_paid);
        total += parseFloat(item?.total);
        totalPaidAmount += parseFloat(item?.vendor_paid)
        totalNetDue += parseFloat(item?.final_price)
      });
      setTotalBalance(balance);
      setAllSums(balance);
      setPaidAmount(totalPaidAmount)
      setTotalNetDue(totalNetDue)
      setShippingSOA(data?.vehicles?.rows);

      setTotalCount(data?.vehicles?.count);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  // *For Apply Filters
  const applyFilter = async () => {
    try {
      let data = {
        customer_id: selectedClient?.id,
        status: selectedStatus?.id,
        vin: selectedVin?.id,
        lot: selectedLot?.id,
        container: getValues("container"),
        particular: "Vehicle",
        from_date: fromDate,
        to_date: toDate,
        booked_status: vehicleStatus.name,
        vendor_id: selectedClient?.id
      };
      getClientCosting(1, 20000, data);
      getVaultDashboard(1, 500, { vendor_id: selectedClient?.id });
    } catch (error) {
      ErrorToaster(error);
    }
  };
  // *For Get Vault Dashboard
  const getVaultDashboard = async (page, limit, filter) => {
    setLoader(true);
    try {
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const Filter = { ...filters, ...filter };
      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);
      let params = {
        page: Page,
        limit: Limit,
      };
      params = { ...params, ...Filter };
      const {
        data: { detail },
      } = await FinanceServices.getVehicleSumLedgerExport(params);
      setShippingVault(
        detail?.rows[0].accounts?.find(
          (account) =>
            account.type_code === "L2" && account.primary_series === 50005
        )
      );
      const filterCustomer = detail?.clientVault?.filter((i) => {
        return (
          i?.id == selectedClient?.id
        )
      })
      const filteredWallet = filterCustomer[0]?.accounts[0]
      setVehicleVault(filteredWallet);
      setWalletBalance(filteredWallet?.nature == 'credit' ? parseFloat(filteredWallet?.total_credit - filteredWallet?.total_debit).toFixed(2) : parseFloat(filteredWallet?.total_debit - filteredWallet?.total_credit).toFixed(2))
      setTotalCount(detail?.count);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };
  const getStatusText = (text) => {
    if (text === "Handover") {
      text = "Shipment Release";
    }
    return text;
  }
  const getStatus = async () => {
    try {
      const { data } = await ExportServices.getVehicleStatus();
      // const dataWithCheckStatusText = data.statuses.map((item)=>{
      //   item.name = getStatusText(item.name)
      //   return item;
      // })

      setVehicleStatuses(data?.statuses)
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const sortData = (e, type, item) => {
    e.preventDefault();
    if (item === "CMR Date") {
      const sortedData = shippingSOA?.sort((a, b) =>
        shippingSOA === "ascending"
          ? new Date(a?.cmr_date) - new Date(b?.cmr_date)
          : new Date(b?.cmr_date) - new Date(a?.cmr_date)
      )
      // const sortedData = shippingSOA?.sort((a, b) => {
      //   console.log('a is------', a);
      //   if (type === "ascending") {

      //     return new Date(a?.cmr_date) - new Date(b?.cmr_date);
      //   }
      //   if (type === "descending") {

      //     return new Date(b?.cmr_date) - new Date(a?.cmr_date);
      //   }
      //   return 0;
      // });

      setShippingSOA(sortedData);
    }

    if (type === "ascending" && item == "Model") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.veh_model?.name.localeCompare(
          b.booking?.veh_model?.name
        );
      });

      setShippingSOA(sortedData);
    }

    if (type === "descending" && item == "Model") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.veh_model?.name.localeCompare(
          a.booking?.veh_model?.name
        );
      });

      setShippingSOA(sortedData);
    }
    if (type === "ascending" && item == "Make") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.veh_make?.name.localeCompare(
          b.booking?.veh_make?.name
        );
      });

      setShippingSOA(sortedData);
    }
    if (type === "descending" && item == "Make") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.veh_make?.name.localeCompare(
          a.booking?.veh_make?.name
        );
      });

      setShippingSOA(sortedData);
    }

    if (type === "ascending" && item === "LOT") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        const costA = parseFloat(a.booking?.lot_number) || 0;
        const costB = parseFloat(b.booking?.lot_number) || 0;
        return costA - costB;
      });

      setShippingSOA(sortedData);
    }
    if (type === "descending" && item === "LOT") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        const costA = parseFloat(a.booking?.lot_number) || 0;
        const costB = parseFloat(b.booking?.lot_number) || 0;
        return costB - costA;
      });

      setShippingSOA(sortedData);
    }

    if (type === "ascending" && item == "VIN") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.vin.localeCompare(b.booking?.vin);
      });

      setShippingSOA(sortedData);
    }

    if (type === "descending" && item == "VIN") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.vin.localeCompare(a.booking?.vin);
      });

      setShippingSOA(sortedData);
    }

    if (type === "ascending" && item == "Color") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.color.localeCompare(b.booking?.color);
      });

      setShippingSOA(sortedData);
    }

    if (type === "descending" && item == "Color") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.color.localeCompare(a.booking?.color);
      });

      setShippingSOA(sortedData);
    }
  };

  const handleColumnChange = (event) => {
    const selectedColumns = event.target.value;
    // Sort the selected columns to maintain the correct order
    const sortedColumns = selectedColumns.sort((a, b) => a - b);
    setVisibleColumns(sortedColumns);
  };
  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const handleToDate = (date) => {
    setToDate(date);
  };
  const renderCellContent = (colIndex, item, isActive, rowIndex) => {
    TotalBalance +=
      parseFloat(item?.final_price) - parseFloat(item?.vendor_paid);
    switch (colIndex) {
      case 0:
        return rowIndex + 1;
      case 1:
        return item && parseFloat(item?.paymentDetails?.amount || 0) > 0
          ? `GSI${item?.id}`
          : `GEVOTW${item?.id}`
      case 2:
        return item?.cmr_date
          ? moment(item?.cmr_date || item?.cmr_date).format("DD-MMM-YYYY")
          : "-";
      case 3:
        return item?.cmr_number ?? "-";
      case 4:
        return item?.model?.name ?? "-";
      case 5:
        return item?.make.name ?? "-";
      case 6:
        return item?.vin ?? "-";
      case 7:
        return item?.color ?? "-";
      case 8:
        return item?.status?.name ?? "-";
      case 9:
        return item?.destination?.name ?? "-";
      // case 10:
      //   return item?.eta
      //     ? moment(item?.eta || item?.created_at).format("DD-MMM-YYYY")
      //     : "-";
      case 10:
        return item?.vendor_paid == 0 ? (
          <Box>
            <Box
              sx={{
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${Colors.danger}`,
                borderRadius: "50%",
              }}
            >
              <Close
                sx={{
                  fontSize: "18px",
                  color: Colors.danger,
                }}
              />
            </Box>
            Unpaid
          </Box>
        ) : parseFloat(item?.vendor_paid) > 0 && parseFloat(item?.vendor_paid) < parseFloat(item?.final_price) ? (
          <Box>
            <Box
              sx={{
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${Colors.danger}`,
                borderRadius: "50%",
              }}
            >
              <Close
                sx={{
                  fontSize: "18px",
                  color: Colors.danger,
                }}
              />
              {/* <Check
                sx={{
                  fontSize: "18px",
                  color: "#25ABE1",
                }}
              /> */}
            </Box>
            Unpaid
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${Colors.primary}`,
                borderRadius: "50%",
              }}
            >
              <Check
                sx={{
                  fontSize: "18px",
                  color: Colors.primary,
                }}
              />
            </Box>
            Paid
          </Box>
        );
      case 11:
        return CommaSeparator(parseFloat(item?.price).toFixed(2)) ?? "-";
      case 12:
        return CommaSeparator(parseFloat(item?.other_charges).toFixed(2)) ?? "-";
      case 13:
        return CommaSeparator(parseFloat(item?.discount).toFixed(2)) ?? "-";
      case 14:
        return CommaSeparator(parseFloat(item?.final_price).toFixed(2)) ?? "-";
      case 15:
        return <b>USD {CommaSeparator(parseFloat(item?.vendor_paid).toFixed(2))}</b>;
      case 16:
        return (
          <b>
            USD{" "}
            {CommaSeparator(parseFloat(
              parseFloat(item?.final_price) - parseFloat(item?.vendor_paid)
            ).toFixed(2))}
          </b>
        );
      default:
        return "-";
      case 17:
        return item?.date
          ? moment(item?.date || item?.created_at).format("DD-MMM-YYYY")
          : "-";
      case 18:
        if (item?.date) {
          const daysPast = moment().diff(moment(item?.date), 'days');
          return `${daysPast} days past`;
        } else {
          return "-";
        }
    }
  };

  const downloadExcel = () => {
    const headers = vehicleTableHead;
    const rows = shippingSOA?.map((item, colIndex) => {
      TotalBalance +=
        parseFloat(item?.final_price) - parseFloat(item?.vendor_paid);
      return [
        colIndex + 1, // ColIndex 0: Row index (1-based)
        item && parseFloat(item?.paymentDetails?.amount || 0) > 0
          ? `GSI${item?.id}` // ColIndex 1
          : `GEVOTW${item?.id}`,
        item?.cmr_date
          ? moment(item?.cmr_date || item?.cmr_date).format("DD-MMM-YYYY") // ColIndex 2
          : "-",
        item?.cmr_number ?? "-", // ColIndex 3
        item?.model?.name ?? "-", // ColIndex 4
        item?.make?.name ?? "-", // ColIndex 5
        item?.vin ?? "-", // ColIndex 6
        item?.color ?? "-", // ColIndex 7
        item?.status?.name ?? "-", // ColIndex 8
        item?.destination?.name ?? "-", // ColIndex 9
        item?.vendor_paid == 0 // ColIndex 10: Payment status
          ? "Unpaid"
          : item?.vendor_paid > 0 && item?.vendor_paid < item?.final_price
            ? "Partial"
            : "Paid",
        CommaSeparator(parseFloat(item?.price).toFixed(2)) ?? "-", // ColIndex 11
        CommaSeparator(parseFloat(item?.other_charges).toFixed(2)) ?? "-", // ColIndex 12
        CommaSeparator(parseFloat(item?.discount).toFixed(2)) ?? "-", // ColIndex 13
        CommaSeparator(parseFloat(item?.final_price).toFixed(2)) ?? "-", // ColIndex 14 (duplicate logic of ColIndex 11)
        `USD ${CommaSeparator(parseFloat(item?.vendor_paid).toFixed(2))}`, // ColIndex 15
        `USD ${CommaSeparator(
          parseFloat(item?.final_price) - parseFloat(item?.vendor_paid)
        )}`, // ColIndex 16
        item?.date
          ? moment(item?.date || item?.created_at).format("DD-MMM-YYYY") // ColIndex 17
          : "-",
        item?.date // ColIndex 18: Days past
          ? `${moment().diff(moment(item?.date), "days")} days past`
          : "-",
      ];
    });

    const totalRows1 = [
      "",
      "",
      "",
      "",
      "",
      "Total Balance Due",
      "",
      "",
      "",
      `USD ${CommaSeparator(parseFloat(totalNetDue).toFixed(2))}`,
    ];
    const totalRows2 = [
      "",
      "",
      "",
      "",
      "",
      "Total Paid Amount",
      "",
      "",
      "",
      `USD ${CommaSeparator(parseFloat(paidAmount).toFixed(2))}`,
    ];
    const totalRows3 = [
      "",
      "",
      "",
      "",
      "",
      "Total Balance Net Due",
      "",
      "",
      "",
      `USD ${CommaSeparator(parseFloat(totalBalance).toFixed(2))}`,
    ];
    const totalRows4 = [
      "",
      "",
      "",
      "",
      "",
      "Wallet Balance",
      "",
      "",
      "",
      `USD ${CommaSeparator(parseFloat(walletBalance / currencyExchangeRate).toFixed(2))}`,
    ];
    const totalRows5 = [
      "",
      "",
      "",
      "",
      "",
      "Final Net Due",
      "",
      "",
      "",
      `USD ${CommaSeparator(parseFloat(totalBalance - walletBalance / currencyExchangeRate).toFixed(2))}`,
    ];

    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows, totalRows1, totalRows2, totalRows3, totalRows4, totalRows5]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(new Blob([buf]), "data.xlsx");
  };

  useEffect(() => {
    if (paymentType) {
      const cashierDetail = cashierAccounts.find(
        (e) => e.currency === paymentType
      );
      setValue("cash", cashierDetail?.name);
      setSelectedCashierAccount(cashierDetail);
    }
  }, [paymentType]);

  useEffect(() => {
    getCustomers();
    getBroker();
    getAgent();
    getCurrencies();
  }, []);
  const handleSort = (column) => {
    const newSortOrder = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(newSortOrder);
    setShippingSOA(
      shippingSOA?.sort((a, b) =>
        sortOrder === "asc"
          ? new Date(a?.date) - new Date(b?.date)
          : new Date(b?.date) - new Date(a?.date)
      )
    );
  };
  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <PDFExport
            ref={contentRef}
            paperSize={["210mm", "400mm"]}
            fileName="ExportShippingSOA"
            dpi={96}
            landscape={true}
            keepTogether=".bottom-box"
            margin={{ bottom: 80 }}
          >
            <Box className="pdf-show" display="none">
              <ExportPDF
                invoiceName="Export Shipping Statement Of Account"
                selectedCustomer={selectedClient}
                mode="landscape"
                // fromdate={fromDate}
                totalBalance={totalBalance}
                // todate={toDate}
                tableHead={vehicleTableHead}
                resultData={shippingSOA}
                selectedStatus={selectedStatus}
                shippingVault={shippingVault}
                vehicleVault={vehicleVault}
                totalNetDue={totalNetDue}
                paidAmount={paidAmount}
                walletBalance={walletBalance}
                currencyExchangeRate={currencyExchangeRate}
              // totalPaid={totalPaid}
              // otherCharges={otherCharges}
              // totalVehicleAmount={totalVehicleAmount}
              // netBalance={allsums}
              // dueBreakup={dueBreakup}
              />
            </Box>
          </PDFExport>
        </Box>

        <Grid item xs={12} sm={12} md={11}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mr: 4,
              my: 4,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: Colors.charcoalGrey,
                fontFamily: FontFamily.NunitoRegular,
                ml: "5px",
              }}
            >
              Export Shipping SOA
            </Typography>
            {shippingSOA?.length > 0 && (
              <Box >
                <PrimaryButton
                  title="Download PDF"
                  type="button"
                  style={{ backgroundColor: Colors.bluishCyan, marginRight: 8 }}
                  onClick={() => handleExportWithComponent(contentRef)}
                />
                <PrimaryButton
                  title={"Download Excel"}
                  onClick={() => downloadExcel()}
                />

              </Box>
            )}
          </Box>
        </Grid>

        <Grid item md={11}>
          <Box
            sx={{
              m: "20px 0 20px 0",
              p: "20px",
              bgcolor: Colors.feta,
              border: `1px solid ${Colors.iron}`,
              borderRadius: "9px",
            }}
          >
            <Grid
              container
              spacing={1}
              alignItems={"center"}
              component={"form"}
              onSubmit={handleSubmit(applyFilter)}
            >
              <Grid item xs={12} md={4}>
                <SelectField
                  size="small"
                  label={"Select Customer"}
                  options={mergedOptions}
                  selected={selectedClient}
                  onSelect={(value) => {
                    setSelectedClient(value);
                    // applyFilter();
                    setShowTable(true);
                  }}
                  error={errors?.client?.message}
                  register={register("client", {
                    required: "Please select client.",
                  })}
                />
              </Grid>

              {/* <Grid item xs={12} md={3}>
                <SelectField
                  size="small"
                  label={"Particular"}
                  options={[
                    { id: false, name: "Vehicle" },
                  ]}
                  selected={particularStatus}
                  onSelect={handleParticularSelect}
                  error={errors?.vehicleStatus?.message}
                  register={register("particular")}
                />
              </Grid> */}
              <Grid item xs={12} md={4}>
                <SelectField
                  size="small"
                  label={"Vehicle Status"}
                  options={vehicleStatuses}
                  selected={vehicleStatus}
                  onSelect={(value) => setVehicleStatus(value)}
                  error={errors?.vehicleStatus?.message}
                  register={register("vehicleStatus")}
                />
              </Grid>

              <Grid item md={4}>
                <SelectField
                  size="small"
                  label={"Payment Status"}
                  options={[
                    { id: "all", name: "All" },
                    { id: "true", name: "Paid" },
                    { id: "false", name: "Unpaid" },
                  ]}
                  selected={selectedStatus}
                  onSelect={(value) => setSelectedStatus(value)}
                  error={errors?.status?.message}
                  register={register("status")}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="fromDate"
                  render={({ field }) => (
                    <DatePicker
                      size={"small"}
                      label="From Date"
                      maxDate={new Date()}
                      value={fromDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        field.onChange(date);
                        handleFromDate(date);
                      }}
                      selected={field.value}
                    />
                  )}
                />
                {errors.fromDate && (
                  <Typography
                    color="error"
                    sx={{ fontSize: 12, textAlign: "left" }}
                  >
                    {errors.fromDate.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="toDate"
                  render={({ field }) => (
                    <DatePicker
                      size={"small"}
                      label="To Date"
                      value={toDate}
                      minDate={fromDate}
                      maxDate={new Date()}
                      placeholderText="Select date"
                      onChange={(date) => {
                        field.onChange(date);
                        handleToDate(date);
                      }}
                      selected={field.value}
                    />
                  )}
                />
                {errors.toDate && (
                  <Typography
                    color="error"
                    sx={{ fontSize: 12, textAlign: "left" }}
                  >
                    {errors.toDate.message}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    mt: "20px",
                  }}
                >
                  <PrimaryButton type="submit" title="Search" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={11}>
          {shippingSOA && (
            <Box>
              <Grid container mb={2}>
                <Grid item xs={5}>
                  <FormControl>
                    <InputLabel>Columns</InputLabel>
                    <Select
                      size={"small"}
                      multiple
                      value={visibleColumns}
                      label={"Columns"}
                      onChange={handleColumnChange}
                      renderValue={() => "Show/Hide"}
                    >
                      {vehicleTableHead.map((column, index) => {
                        if (
                          column !== "Balance" &&
                          column !== "Past Due Days" &&
                          column !== "Paid"
                        ) {
                          return (
                            <MenuItem key={index} value={index}>
                              <Checkbox
                                checked={visibleColumns.includes(index)}
                              />
                              <ListItemText primary={column} />
                            </MenuItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {shippingSOA && (
                <Box>
                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                      borderRadius: 2,
                      maxHeight: "calc(100vh - 330px)",
                    }}
                  >
                    <Table stickyHeader sx={{ minWidth: 500 }}>
                      {/* Table Header */}
                      <TableHead>
                        <TableRow>
                          {visibleColumns.map((index) => {
                            const columnName = vehicleTableHead[index];

                            // List of column names that should not display any content
                            const excludedColumns = [
                              "S.No",
                              "Past Due Days",
                              "Buy Date",
                              "Other Charges",
                              "Paid",
                              "Balance",
                              "SHIPPING CHARGE",
                              "LATE FEE",
                              "STORAGE",
                              "CATEGORY A",
                              "BROKER FEE",
                              "TITLE FEE",
                              "OTHER CHARGE",
                              "CUSTOM DUTY",
                              "VAT",
                              "TOTAL",
                              "Discount",
                              "Net Due",
                              "BUY DATE",
                              "Arrived Date",
                              "SL. No",
                              "VCC",
                            ];

                            // Check if the column should be excluded
                            if (excludedColumns.includes(columnName)) {
                              return (
                                <Cell key={index}>
                                  {columnName}
                                </Cell>
                              );
                            }

                            return (
                              <Cell key={index}
                                onClick={() =>
                                  (columnName == 'CMR Date') && handleSort(columnName)
                                }
                                style={{
                                  cursor: (columnName == 'CMR Date') ? "pointer" : "default",
                                }}
                              >
                                {columnName}
                                {(columnName == 'CMR Date') && (
                                  sortOrder === "asc" ? (
                                    <ArrowUpward style={{ position: "absolute" }} />

                                  ) : (
                                    <ArrowDownward style={{ position: "absolute", }} />
                                  )
                                )}
                              </Cell>
                            );
                          })}

                        </TableRow>
                      </TableHead>

                      {/* Table Body */}
                      <TableBody>
                        {!loader ? (
                          shippingSOA?.length > 0 ? (
                            <Fragment>
                              {shippingSOA.map((item, rowIndex) => {
                                const isActive = true;
                                return (
                                  <Row
                                    key={rowIndex}
                                    sx={{
                                      bgcolor: rowIndex % 2 !== 0 && "#EFF8E7",
                                    }}
                                  >
                                    {visibleColumns.map((colIndex) => (
                                      <Cell key={colIndex}>
                                        {renderCellContent(
                                          colIndex,
                                          item,
                                          isActive,
                                          rowIndex
                                        )}
                                      </Cell>
                                    ))}
                                  </Row>
                                );
                              })}
                              <Row>
                                <Cell colSpan={visibleColumns.length}>
                                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                    Total Balance Due: <span style={{ marginLeft: '8px' }}>USD {CommaSeparator(parseFloat(totalNetDue).toFixed(2))}</span>
                                  </Typography>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell colSpan={visibleColumns.length}>
                                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                    Total Paid Amount: <span style={{ marginLeft: '8px' }}>USD {CommaSeparator(parseFloat(paidAmount).toFixed(2))}</span>
                                  </Typography>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell colSpan={visibleColumns.length}>
                                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                    Total Balance Net Due: <span style={{ marginLeft: '8px' }}>
                                      USD {CommaSeparator(
                                        parseFloat(
                                          parseFloat(totalBalance) -
                                          parseFloat(
                                            shippingVault?.nature === "credit"
                                              ? isNaN(
                                                parseFloat(
                                                  shippingVault?.total_credit
                                                ) -
                                                parseFloat(
                                                  shippingVault?.total_debit
                                                )
                                              )
                                                ? parseFloat(0).toFixed(2)
                                                : parseFloat(
                                                  parseFloat(
                                                    shippingVault?.total_credit
                                                  ) -
                                                  parseFloat(
                                                    shippingVault?.total_debit
                                                  )
                                                ).toFixed(2)
                                              : isNaN(
                                                parseFloat(
                                                  shippingVault?.total_debit
                                                ) -
                                                parseFloat(
                                                  shippingVault?.total_credit
                                                )
                                              )
                                                ? parseFloat(0).toFixed(2)
                                                : parseFloat(
                                                  parseFloat(
                                                    shippingVault?.total_debit
                                                  ) -
                                                  parseFloat(
                                                    shippingVault?.total_credit
                                                  )
                                                ).toFixed(2)
                                          )
                                        ).toFixed(2)
                                      )}</span>
                                  </Typography>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell colSpan={visibleColumns.length}>
                                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                    Wallet Balance: <span style={{ marginLeft: '8px' }}>
                                      USD {CommaSeparator(parseFloat(walletBalance / currencyExchangeRate).toFixed(2))}</span>
                                  </Typography>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell colSpan={visibleColumns.length}>
                                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                    Final Net Due: <span style={{ marginLeft: '8px' }}>
                                      USD {CommaSeparator(
                                        parseFloat(
                                          parseFloat(totalBalance) -
                                          parseFloat(
                                            shippingVault?.nature === "credit"
                                              ? isNaN(
                                                parseFloat(shippingVault?.total_credit) - parseFloat(shippingVault?.total_debit)
                                              )
                                                ? parseFloat(0).toFixed(2)
                                                : parseFloat(parseFloat(shippingVault?.total_credit) - parseFloat(shippingVault?.total_debit)).toFixed(2)
                                              : isNaN(
                                                parseFloat(shippingVault?.total_debit) - parseFloat(shippingVault?.total_credit)
                                              )
                                                ? parseFloat(0).toFixed(2)
                                                : parseFloat(parseFloat(shippingVault?.total_debit) - parseFloat(shippingVault?.total_credit)).toFixed(2)
                                          ) - parseFloat(walletBalance / currencyExchangeRate)
                                        ).toFixed(2)
                                      )}
                                    </span>
                                  </Typography>
                                </Cell>
                              </Row>
                            </Fragment>
                          ) : (
                            <Row>
                              <Cell
                                colSpan={vehicleTableHead.length + 1}
                                align="center"
                                sx={{ fontWeight: 600 }}
                              >
                                No Data Found
                              </Cell>
                            </Row>
                          )
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={visibleColumns?.length + 2}
                              align="center"
                              sx={{ fontWeight: 600 }}
                            >
                              <Box className={classes.loaderWrap}>
                                <CircularProgress />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              {loader && <CircleLoading />}
            </Box>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default ExportShippingSOA;
