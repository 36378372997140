import html2pdf from "html2pdf.js";
import "jspdf-autotable";
import moment from "moment";
import { CommaSeparator } from "utils";
import HeaderImage from "./pdf_header.png";
import VehicleSOA from "./vehicleSOA.png";
export const CreatePdf = (
  tableHead,
  currency,
  invoiceName,
  currencyExchangeRate,
  mainTotal,
  totalBalance,
  totalPaid,
  shippingVault,
  totalVCC,
  vehicleVault,
  allSums,
  otherCharges,
  totalVehicleAmount,
  TotalBalance,
  selectedVendor,
  fromdate,
  todate,
  selectedStatus,
  selectedCustomer
) => {
  if (!currency?.length) {
    return null;
  }
  let Balance = 0;
  const today = new Date();
  const currentDate = moment(today).format("DD-MMM-YY HH:mm");
  const startDate = fromdate && moment(fromdate).format("DD-MMM-YY");
  const endDate = todate && moment(todate).format("DD-MMM-YY");
  const status = selectedStatus && selectedStatus?.name;

  const trialBalance = `
  <!DOCTYPE html>
                 <html>
                     <head>
                         <meta charset="utf-8">
                         <meta http-equiv="X-UA-Compatible" content="IE=edge">
                         <title></title>
                         <meta name="description" content="">
                         <meta name="viewport" content="width=device-width, initial-scale=1">
                         <link rel="preconnect" href="https://fonts.googleapis.com">
                         <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                         <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap" rel="stylesheet">
                         <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                        <style>
                         *,
                 *::after,
                 *::before{
                     padding: 0;
                     margin: 0;
                     box-sizing: border-box;
                 }
                     body{
                        padding: 0;
                     margin: 0;

                     }
                  .first-row{
         font-weight:700;
         font-size:16px;
         }
                 :root{
                     --blue-color: #0c2f54;
                     --dark-color: black;
                     --white-color: #fff;
                 }
                 
                 ul{
                     list-style-type: none;
                 }
                 ul li{
                     margin: 2px 0;
                 }
                 
                 /* text colors */
                 .text-dark{
                     color: var(--dark-color);
                 }
                 .text-blue{
                     color: var(--blue-color);
                 }
                 .text-end{
                     text-align: right;
                 }
                 .text-center{
                     text-align: center;
                 }
                 .text-start{
                     text-align: left;
                 }
                 .text-bold{
                     font-weight: 600;
                     font-size: 6px;
                 }
                 /* hr line */
                 .hr{
                     height: 1px;
                     background-color: rgba(0, 0, 0, 0.1);
                 }
                 /* border-bottom */
                 .border-bottom{
                     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                 }
                 
                 body{
                     font-family: 'Poppins', sans-serif;
                     color: var(--dark-color);
                     font-size: 10px;
                 }
                 
                 .invoice-wrapper{
                     min-height: 100vh;
                     background-color: rgba(0, 0, 0, 0.1);
                     margin:0px 8px 0px 8px
                 }
                 .invoice{
                     max-width: 100%;
                     background:red;
                     margin-right: auto;
                     margin-left: auto;
                     background-color: var(--white-color);
                 }
                 .invoice-head-top-left img{
                     width: 130px;
                 }
                     .title{
             text-align:center;
                 font-size: 18px;
                 font-weight:700;
             }
                 .invoice-head-top-right h3{
                     font-weight: 500;
                     font-size: 20px;
                     color: var(--blue-color);
                 }
                 .invoice-head-middle, .invoice-head-bottom{
                     padding: 16px 0;
                 }
                 .invoice-body{
                     border: 1px solid rgba(0, 0, 0, 0.1);
                     border-radius: 4px;
                     overflow: hidden;

                 }
                     .due-table-text{
                     font-weight:700;
                     font-size:12px;
                     }
                 .invoice-body table{
                     border-radius: 4px;
                     width: 100%;
                 }
                 .invoice-body table td, .invoice-body table th{
                     padding: 6px;
                 }
                 .invoice-body table tr{
                     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                 }
                 .invoice-body table thead{
                     background-color: rgba(0, 0, 0, 0.02);
                 }
                 .invoice-body-info-item{
                     display: grid;
                 }
                 .invoice-body-info-item .info-item-td{
                     padding: 4px;
                     background-color: rgba(0, 0, 0, 0.02);
                 }
                 .invoice-foot{
                     padding: 30px 0;
                 }
                 .invoice-foot p{
                     font-size: 12px;
                 }
                 .invoice-btns{
                     margin-top: 20px;
                     display: flex;
                     justify-content: center;
                 }
                 .invoice-btn{
                     padding: 3px 9px;
                     color: var(--dark-color);
                     font-family: inherit;
                     border: 1px solid rgba(0, 0, 0, 0.1);
                     cursor: pointer;
                 }
                 
                 
                  .row-last-box{
             border: 1px solid black;
             background:#EFF0F6;
 padding: 6px;
 border-radius: 4px;
 margin-left:6px;
 display: flex;
 justify-content: center;
 text-align: center;
             }
                 .invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
                     display: grid;
                     grid-template-columns: repeat(2, 1fr);
                     padding-bottom: 10px;
                 }
                 
                 .header-img{
                 background-image: url('./pdf_header.png');
                     background-size: contain;
                     width: 100%;
                     height: 200px;
                     background-repeat: no-repeat;
                     background-position: center;
                 }
                 .usd-row{
                 font-weight:700;
                 font-size:16px
                 }
                        .footer-content {
                      margin-top:8px;
                      position:absoulte;
                      bottom:0;
                      top:100;
                      left:0;
                      }
                 .footer-div{
                      width: 100%;
                     height: 80px;
                     color: #FFFFFF;
                     padding:1.5%;
                     background-color: #5abc0e;
                

                 }
               
                   .system-generated{
                     background:rgba(0, 0, 0, 0.1);
                     color:black;
                     padding:8px 8px
                     }
                     .system-text{
                     padding:2px;
                     font-size:10px;
                     font-weight:700;                     }

       .table-green-row{
background-color:#EFF8E7;
color:black;
    text-align: start;
    font-weight: 700;
    font-size:11px;
}
               

                 .footer-div-font{
                     
                     font-size: 11px;
                 }
                 
                 .data-img{
                     width: 30vw;
                     position: relative;
                     right: 100%;
                     top: 70%;
                 }
                 
                 .row-data{
                     font-size: 6px;
                       
     text-align:start
                 }
                   .invoice-body-bottom {
     display: flex;
     justify-content: space-between;
 }
 .invoice-body-info-item {
     flex: 1;
     padding: 0 10px;
 }
 .info-item-td {
     margin: 5px 0;
 }
                 .invoice-title{
                     margin-top:30px;
                     position: relative;
                     left: 500px;
                     font-weight: normal;
                     font-size: 30px;
                 }
                 
             
                 
                 @media screen and (max-width: 576px){
                     .invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
                         grid-template-columns: repeat(1, 1fr);
                     }
                     .invoice-head-bottom-right{
                         margin-top: 12px;
                         margin-bottom: 12px;
                     }
                     .invoice *{
                         text-align: left;
                     }
                    
                 }
                 
                 .overflow-view{
                     overflow-x: scroll;
                 }
                 .invoice-body{
                     min-width: 600px;
                 }
                 .last-row {
 display: flex;
 justify-content: space-between;
 align-items:center;
 width: 100%;
 padding: 10px;
 box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
 .last-row-title{
  display: flex;
  margin-right:6px;
 width: 100%;
 align-items:center;
 }
                 @media print{
                     .print-area{
                         visibility: visible;
                         width: 100%;
                         position: absolute;
                         left: 0;
                         top: 0;
                         overflow: hidden;
                     }
                 
                     .overflow-view{
                         overflow-x: hidden;
                     }
                 
                     .invoice-btns{
                         display: none;
                     }
                 }
                           .main-cont{
                           background-color: rgba(0, 0, 0, 0.1);
         display: flex;
         justify-content: space-between;
         align-items: center;
          grid-template-columns: repeat(12, 1fr);
         gap: 10px;
         padding: 20px;
     }
    
     .col-4 {
         grid-column: span 4;
     }
         .statement{
             border-bottom: 1px solid black;
             font-weight: 700;
             margin-bottom: 12px;
         }
         .client{
             padding: 2px 0px 2px 0px;
             display: flex;
             /* justify-content: space-between */
         }
         .client-font{
             font-size:10px;
             font-weight: 600;
             width: 120px;
         }
         .client-text{
             font-weight: 400;
             font-size:9px;
             color:#5abc0e;
             text-align: start;
             text-transform:capitalize;
         }
         .top-table-row{
border-top: 1px solid;
 border-left: 1px solid;
border-right: 1px solid;
padding: 4px;
font-size:12px;
text-align:center

}
           .top-table-last-row{
border: 1px solid;
padding: 4px;
font-size:12px;

}
.due-table-row{
border: 1px solid;
padding: 4px;
}
.top-table {
width: 100%;
border-collapse: collapse;
}
.table-vehicle{
 font-weight: 700;
 font-size:12px;

 color: red;
}
.unpaid-vehicle{
 width: 300px;
 font-weight: 700;
 font-size:12px;


}
.table-row{
 text-align: start;
 font-weight: 700;
 font-size:12px;


}
.table-date{
 display: flex;
 justify-content: space-evenly;
 align-items: center;
 font-weight: 700;
 font-size:12px;

}
.statement-period{
 font-weight: 700;
 font-size:12px;


}
.from-date{
 color: red;
}
.to-date{
 color:red
}
.generated{
 padding: 8px;
 display: flex;
 justify-content: end;
}
.generated-on{
 width: 120px;
}

.total-row{
font-weight:700;
font-size:12px;

}
.table-head{
font-size:8px;
font-weight:700;
background-color: #5abc0e;
color:white

}
.table-row{
font-size:11px;

}
.due-block{
 display: flex;
             background-color: rgba(0, 0, 0, 0.1);
 justify-content: end;
 align-items: center;
 padding: 8px 56px 8px 8px;
    page-break-inside: avoid;
     page-break-before: auto;
     page-break-after: auto;
}
.due-breakUp{
 width: 150px;
 font-weight: 700;
 font-size: 12px;
    page-break-inside: avoid;
     page-break-before: auto;
     page-break-after: auto;
}
.shhippingyellow{
background: #f3f33a

}

.shhippingred{
background:#ee2d2d
}
.bottom-content{
padding:6px;
margin:8px;
color:black
}
.shopping-bottom{
 padding:6px;
margin:8px;
color:black
}

.shopping-table-row{
heigh:80px;
border: 1px solid;
padding: 6px;
font-size:10px
}  
   .vertical-text {
writing-mode: tb-rl;
 transform: scale(-1);
 width:100px;

}

 thead, th, td {
      padding: 6px !important;
      text-align: left;
      white-space: nowrap; 
      overflow: hidden; 
      text-overflow: ellipsis; 

    }
              
    .table-row-blue{
    background:#27ADDF;
    color:white
    }
   .table-row-green{
    background:#5abc0e;
    color:white
   }
    .total-name{
    text-align:end;
    display:flex;
    justify-content:end
    }

   tr {
      padding:12px !important;
    }
        </style>
                     </head>
                     <body>
                 
                         <div class = "invoice-wrapper" id = "print-area">
                                 <img src=${
                                   invoiceName ===
                                     "Vehicle Statement Of Account" ||
                                   invoiceName === "Customer Vehicle Wallet"
                                     ? VehicleSOA
                                     : HeaderImage
                                 } width="100%" height="195px" />
                                 <div class = "invoice">
                                 <div class = "invoice-container">
                                 <div class="main-cont">
                             <div class="col col-4">
                                 <div> 
                                 <div class="">
                                         <span class="statement">Statement To :-</span>
                                      </div>                                </div>
                                 <div class="client">
                                     <div class="client-font">Client Name: </div>
                                     <div class="client-text">${
                                       selectedCustomer &&
                                       selectedCustomer?.name
                                         ? selectedCustomer?.name
                                         : "N/A"
                                     }</div>
                                 </div>
                                 <div class="client">
                                     <div class="client-font">Customer ID: </div>
                                     <div class="client-text">
                                    ${
                                      selectedCustomer &&
                                      selectedCustomer?.ref_id
                                        ? selectedCustomer?.ref_id
                                        : selectedCustomer?.id
                                        ? `C-${selectedCustomer?.id}`
                                        : "N/A"
                                    }

                                     </div>
                                 </div>
                                 <div class="client">
                                     <div class="client-font">Phone: </div>
                                     <div class="client-text">
                                     ${
                                       selectedCustomer &&
                                       selectedCustomer?.uae_phone
                                         ? selectedCustomer?.uae_phone
                                         : "N/A"
                                     }
                                     </div>
                                 </div>
                                
                             </div>
                           <div class="col col-4">
                                                           <h2 class="title"> ${invoiceName}</h2>

                           </div>
                             <div class="unpaid-vehicle col col-4">
                             ${
                               startDate && endDate
                                 ? ` <table class="top-table">
     <tr class="table-row">
         ${
           invoiceName === "Shipping Statement Of Account" ||
           invoiceName === "Vehicle Statement Of Account"
             ? `<td class="top-table-row">
             <span class="table-vehicle">${status ? status : "All"} ${
                 invoiceName === "Shipping Statement Of Account"
                   ? "Shipping"
                   : "Vehicle"
               }</span>
         </td>`
             : ``
         }
     </tr>
     <tr class="table-row">
         <td class="top-table-row">
         <span  class="statement-period"></span>Statement Period</span>
       </td>
     </tr>
     <tr>
         <td class="top-table-last-row">
        <div class="table-date"> <span >From:</span>
         <span class="from-date">${startDate}</span>
         <span>To:</span>
         <span class="to-date">${endDate}</span></div>

       </td>
     </tr>
   </table>  `
                                 : ""
                             }
<div class="generated">
 <span class="generated-on">
     Generated on: 
   </span>
   <span>${currentDate}</span>
</div>

</div>
                         </div>
                                                                                  
                                     <div class = "overflow-view">
                                         <div class = "invoice-body">
                                          <table>
  <thead>
    <tr class="table-head">
          ${tableHead
            ?.map(
              (item) =>
                `<th class="table-head" colspan="${
                  item === "Name"
                    ? "6"
                    : item === "Code"
                    ? "2"
                    : item === "Sub Category"
                    ? "6"
                    : "1"
                }">${item}</th>`
            )
            .join("")}
        </tr>
  </thead>
  <tbody>
   ${
     currencyExchangeRate === "trial_summary"
       ? currency
           ?.map((values, index) => {
             let Total = 0;
             let Total2 = 0;
             let GrandTotal = 0;
             let TotalEquity = 0;
             let GrandTotal2 = 0;
             return `
            <tr class="table-row" key=${index}>
              <td colspan="2">${values?.name}</td>
            </tr>
            ${
              values?.sub
                ? values?.sub
                    ?.map(
                      (val) => `
                <tr>
                  <td colspan="3">${val?.name}</td>
                </tr>
                ${
                  val?.accounts
                    ? val?.accounts
                        .map((data, index2) => {
                          let childTotal = 0; // Initialize childTotal here
                          let childTotal2 = 0; // Initialize childTotal here

                          if (data?.childAccounts?.length > 0) {
                            const initialValue = { credit: 0, debit: 0 };
                            const result = data?.childAccounts?.reduce(
                              (accumulator, transaction) => {
                                const credit = isNaN(transaction?.total_credit)
                                  ? 0
                                  : transaction?.total_credit;
                                const debit = isNaN(transaction?.total_debit)
                                  ? 0
                                  : transaction?.total_debit;
                                return {
                                  credit:
                                    parseFloat(accumulator.credit) +
                                    parseFloat(credit),
                                  debit:
                                    parseFloat(accumulator.debit) +
                                    parseFloat(debit),
                                };
                              },
                              initialValue
                            );
                            childTotal =
                              data?.nature === "debit"
                                ? parseFloat(result?.debit) -
                                  parseFloat(result?.credit)
                                : parseFloat(result?.credit) -
                                  parseFloat(result?.debit);
                            childTotal2 =
                              data?.nature === "debit" || data?.nature === null
                                ? parseFloat(result?.debit) -
                                  parseFloat(result?.credit)
                                : parseFloat(0).toFixed(2);
                          } else {
                            childTotal =
                              data?.nature === "debit"
                                ? parseFloat(data?.total_debit) -
                                  parseFloat(data?.total_credit)
                                : parseFloat(data?.total_credit) -
                                  parseFloat(data?.total_debit);

                            childTotal2 =
                              data?.nature === "debit" || data?.nature === null
                                ? parseFloat(data?.total_debit) -
                                  parseFloat(data?.total_credit)
                                : parseFloat(data?.total_credit) -
                                  parseFloat(data?.total_debit);
                          }
                          if (["credit", "combine"].includes(data?.nature)) {
                            Total += parseFloat(childTotal);
                            GrandTotal += parseFloat(childTotal);
                            if (index !== 0) {
                              TotalEquity += parseFloat(childTotal);
                            }
                          }

                          if (
                            ["debit", "combine", null].includes(data?.nature)
                          ) {
                            Total2 += parseFloat(childTotal2);

                            GrandTotal2 += parseFloat(childTotal2);
                          }
                          return `
                    <tr class="table-row table-row-sub">
                      <td colspan="2">${data?.account_code}</td>
                      <td colspan="6">${data?.account_name}</td>
                      <td colspan="1">${data?.account_category}</td>
                      <td colspan="6">${data?.account_subcategory}</td>
                      <td colspan="1">$
                      ${
                        data.nature === "debit" ||
                        data?.nature === null ||
                        data?.nature === "combine"
                          ? CommaSeparator(parseFloat(childTotal2).toFixed(2))
                          : parseFloat(0).toFixed(2)
                      }
                          </td>
                          <td colspan="1">
                          $${
                            data.nature === "credit" ||
                            data?.nature === "combine"
                              ? CommaSeparator(
                                  parseFloat(childTotal).toFixed(2)
                                )
                              : parseFloat(0).toFixed(2)
                          }
                           </td>
                    </tr>
                     ${
                       data?.childAccounts
                         ? data.childAccounts
                             .map((childData, childIndex) => {
                               const credit = isNaN(childData?.total_credit)
                                 ? 0
                                 : childData?.total_credit;
                               const debit = isNaN(childData?.total_debit)
                                 ? 0
                                 : childData?.total_debit;
                               let subTotal =
                                 childData?.nature === "debit"
                                   ? (
                                       parseFloat(debit) - parseFloat(credit)
                                     ).toFixed(2)
                                   : (
                                       parseFloat(credit) - parseFloat(debit)
                                     ).toFixed(2);
                               return `
            <tr class="${"table-green-row table-row"}>
              <td colspan="2"></td>
              <td colspan="2">${childData?.account_code}</td>
              <td colspan="6">${childData?.account_name}</td>
              <td colspan="3">${childData?.account_category}</td>
              <td colspan="3">${childData?.account_subcategory}</td>
                               <td colspan="2">$${CommaSeparator(subTotal)}</td>
                              
            </tr>
          `;
                             })
                             .join("")
                         : ""
                     }
          
                  `;
                        })
                        .join("")
                    : ""
                }
                               ${
                                 val?.accounts?.[0]?.type_code
                                   ? ` <tr class="table-row">
                <td colspan="2">Total Of ${val?.accounts[0]?.type_code}</td>
                         <td colspan="4"></td>
                            <td colspan="2" class="total-name">Total ${
                              val?.name
                            }</td>
                              <td colspan="8"></td>
                                <td colspan="1">$${CommaSeparator(
                                  parseFloat(Total2).toFixed(2)
                                )}</td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(Total).toFixed(2)
                )}</td>
            </tr>
            
           
            `
                                   : ""
                               }
     
              `
                    )
                    .join("")
                : ""
            }
               <tr class="table-row-blue">
              <td colspan="2">Total</td>
                 <td colspan="4"></td>
                <td colspan="2" class="total-name">Total ${values?.name}</td>
                 <td colspan="8"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(GrandTotal2).toFixed(2)
                )}</td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(GrandTotal).toFixed(2)
                )}</td>

            </tr> 
          `;
           })
           .join("")
       : currencyExchangeRate === "trial_simple"
       ? currency
           ?.map((values, index) => {
             let Total = 0;
             let Total2 = 0;
             let GrandTotal = 0;
             let TotalEquity = 0;
             let GrandTotal2 = 0;
             return `
            <tr class="table-row" key=${index}>
              <td colspan="2">${values?.name}</td>
            </tr>
            ${
              values?.sub
                ? values?.sub
                    ?.map(
                      (val) => `
                <tr>
                  <td colspan="3">${val?.name}</td>
                </tr>
                ${
                  val?.accounts
                    ? val?.accounts
                        .map((data, index2) => {
                          let childTotal = 0; // Initialize childTotal here
                          let childTotal2 = 0; // Initialize childTotal here

                          if (data?.childAccounts?.length > 0) {
                            const initialValue = { credit: 0, debit: 0 };
                            const result = data?.childAccounts?.reduce(
                              (accumulator, transaction) => {
                                const credit = isNaN(transaction?.total_credit)
                                  ? 0
                                  : transaction?.total_credit;
                                const debit = isNaN(transaction?.total_debit)
                                  ? 0
                                  : transaction?.total_debit;
                                return {
                                  credit:
                                    parseFloat(accumulator.credit) +
                                    parseFloat(credit),
                                  debit:
                                    parseFloat(accumulator.debit) +
                                    parseFloat(debit),
                                };
                              },
                              initialValue
                            );
                            childTotal =
                              data?.nature === "debit"
                                ? parseFloat(result?.debit) -
                                  parseFloat(result?.credit)
                                : parseFloat(result?.credit) -
                                  parseFloat(result?.debit);
                            childTotal2 =
                              data?.nature === "debit" || data?.nature === null
                                ? parseFloat(result?.debit) -
                                  parseFloat(result?.credit)
                                : parseFloat(0).toFixed(2);
                          } else {
                            childTotal =
                              data?.nature === "debit"
                                ? parseFloat(data?.total_debit) -
                                  parseFloat(data?.total_credit)
                                : parseFloat(data?.total_credit) -
                                  parseFloat(data?.total_debit);

                            childTotal2 =
                              data?.nature === "debit" || data?.nature === null
                                ? parseFloat(data?.total_debit) -
                                  parseFloat(data?.total_credit)
                                : parseFloat(data?.total_credit) -
                                  parseFloat(data?.total_debit);
                          }
                          if (["credit", "combine"].includes(data?.nature)) {
                            Total += parseFloat(childTotal);
                            GrandTotal += parseFloat(childTotal);
                            if (index !== 0) {
                              TotalEquity += parseFloat(childTotal);
                            }
                          }

                          if (
                            ["debit", "combine", null].includes(data?.nature)
                          ) {
                            Total2 += parseFloat(childTotal2);

                            GrandTotal2 += parseFloat(childTotal2);
                          }
                          return `
                    <tr class=${"table-row"}>
                      <td colspan="2">${data?.account_code}</td>
                      <td colspan="6">${data?.account_name}</td>
                      <td colspan="1">${data?.account_category}</td>
                      <td colspan="6">${data?.account_subcategory}</td>
                      <td colspan="1">
                      $${
                        data.nature === "debit" ||
                        data?.nature === null ||
                        data?.nature === "combine"
                          ? CommaSeparator(parseFloat(childTotal2).toFixed(2))
                          : parseFloat(0).toFixed(2)
                      }
                          </td>
                          <td colspan="1">
                          $${
                            data.nature === "credit" ||
                            data?.nature === "combine"
                              ? CommaSeparator(
                                  parseFloat(childTotal).toFixed(2)
                                )
                              : parseFloat(0).toFixed(2)
                          }
                           </td>
                    </tr>
                   
                  `;
                        })
                        .join("")
                    : ""
                }
                    ${
                      val?.accounts?.[0]?.type_code
                        ? `                <tr class="table-row">
                <td colspan="2">Total Of ${val?.accounts[0]?.type_code}</td>
                         <td colspan="4"></td>
                            <td colspan="2" class="total-name">Total ${
                              val?.name
                            }</td>
                              <td colspan="8"></td>
                                <td colspan="1">$${CommaSeparator(
                                  parseFloat(Total2).toFixed(2)
                                )}</td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(Total).toFixed(2)
                )}</td>
            </tr>
            
           
            `
                        : ""
                    }
              `
                    )
                    .join("")
                : ""
            }
                  <tr class="table-row-blue">
              <td colspan="2">Total</td>
                 <td colspan="4"></td>
                <td colspan="2" class="total-name">Total ${values?.name}</td>
                 <td colspan="8"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(GrandTotal2).toFixed(2)
                )}</td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(GrandTotal).toFixed(2)
                )}</td>

            </tr> 
          `;
           })
           .join("")
       : currencyExchangeRate === "balance_summary"
       ? currency
           ?.map((values, index) => {
             let Total = 0;
             let Total2 = 0;
             let GrandTotal = 0;
             let TotalEquity = 0;
             let GrandTotal2 = 0;
             let childFinalTotal = 0;

             return `
            <tr class="table-row" key=${index}>
              <td colspan="2">${values?.name}</td>
            </tr>
            ${
              values?.sub
                ? values?.sub
                    ?.map(
                      (val) => `
                <tr>
                  <td colspan="3">${val?.name}</td>
                </tr>
                ${
                  val?.accounts
                    ? val?.accounts
                        .map((data, index2) => {
                          let childFinalTotal = 0;
                          const credit = isNaN(data?.total_credit)
                            ? 0
                            : data?.total_credit;
                          const debit = isNaN(data?.total_debit)
                            ? 0
                            : data?.total_debit;
                          let childTotal =
                            data?.nature === "debit"
                              ? parseFloat(debit) - parseFloat(credit)
                              : parseFloat(credit) - parseFloat(debit);

                          if (data?.childAccounts?.length > 0) {
                            const initialValue = {
                              credit: 0,
                              debit: 0,
                            };

                            const result = data?.childAccounts?.reduce(
                              (accumulator, transaction) => {
                                const credit = isNaN(transaction?.total_credit)
                                  ? 0
                                  : transaction?.total_credit;
                                const debit = isNaN(transaction?.total_debit)
                                  ? 0
                                  : transaction?.total_debit;
                                return {
                                  credit:
                                    parseFloat(accumulator.credit) +
                                    parseFloat(credit),
                                  debit:
                                    parseFloat(accumulator.debit) +
                                    parseFloat(debit),
                                };
                              },
                              initialValue
                            );
                            childTotal =
                              data?.nature === "debit"
                                ? parseFloat(result?.debit) -
                                  parseFloat(result?.credit)
                                : parseFloat(result?.credit) -
                                  parseFloat(result?.debit);
                          }
                          Total += parseFloat(childTotal);
                          GrandTotal += parseFloat(childTotal);
                          if (index !== 0) {
                            TotalEquity += parseFloat(childTotal);
                          }
                          return `
                    <tr class=${"table-row"}>
                      <td colspan="2">${data?.account_code}</td>
                      <td colspan="6">${data?.account_name}</td>
                      <td colspan="1">${data?.account_category}</td>
                      <td colspan="6">${data?.account_subcategory}</td>
                      <td colspan="1">
                     
                          </td>
                          <td colspan="1">
                          $${
                            data.nature === "credit" ||
                            data?.nature === "combine"
                              ? CommaSeparator(
                                  parseFloat(childTotal).toFixed(2)
                                )
                              : parseFloat(0).toFixed(2)
                          }
                           </td>
                    </tr>
                     ${
                       data?.childAccounts
                         ? data.childAccounts
                             .map((childData, childIndex) => {
                               const credit = isNaN(childData?.total_credit)
                                 ? 0
                                 : childData?.total_credit;
                               const debit = isNaN(childData?.total_debit)
                                 ? 0
                                 : childData?.total_debit;
                               let subTotal =
                                 childData?.nature === "debit"
                                   ? (
                                       parseFloat(debit) - parseFloat(credit)
                                     ).toFixed(2)
                                   : (
                                       parseFloat(credit) - parseFloat(debit)
                                     ).toFixed(2);

                               childFinalTotal += parseFloat(subTotal);
                               return `
            <tr class="${"table-green-row table-row"}>
              <td colspan="2"></td>
              <td colspan="2">${childData?.account_code}</td>
              <td colspan="6">${childData?.account_name}</td>
              <td colspan="3">${childData?.account_category}</td>
              <td colspan="3">${childData?.account_subcategory}</td>
                               <td colspan="2">$${CommaSeparator(subTotal)}</td>
                              
            </tr>
          `;
                             })
                             .join("")
                         : ""
                     }
          
                  `;
                        })
                        .join("")
                    : ""
                }
                               ${
                                 val?.accounts?.[0]?.type_code
                                   ? `                <tr class="table-row">
                <td colspan="2">Total Of ${val?.accounts[0]?.type_code}</td>
                         <td colspan="4"></td>
                            <td colspan="2" class="total-name">Total ${
                              val?.name
                            }</td>
                              <td colspan="8"></td>
                                <td colspan="1"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(Total).toFixed(2)
                )}</td>
            </tr>
            
           
            `
                                   : ""
                               }
     
              `
                    )
                    .join("")
                : ""
            }
               <tr class="table-row-blue">
              <td colspan="2">Total</td>
                 <td colspan="4"></td>
                <td colspan="2" class="total-name">Total ${values?.name}</td>
                 <td colspan="8"></td>
                <td colspan="1"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(GrandTotal).toFixed(2)
                )}</td>

            </tr> 
          `;
           })
           .join("")
       : currency
           ?.map((values, index) => {
             let Total = 0;
             let Total2 = 0;
             let GrandTotal = 0;
             let TotalEquity = 0;
             let GrandTotal2 = 0;
             return `
            <tr class="table-row" key=${index}>
              <td colspan="2">${values?.name}</td>
            </tr>
            ${
              values?.sub
                ? values?.sub
                    ?.map(
                      (val) => `
                <tr>
                  <td colspan="3">${val?.name}</td>
                </tr>
                ${
                  val?.accounts
                    ? val?.accounts
                        .map((data, index2) => {
                          let childFinalTotal = 0;
                          const credit = isNaN(data?.total_credit)
                            ? 0
                            : data?.total_credit;
                          const debit = isNaN(data?.total_debit)
                            ? 0
                            : data?.total_debit;
                          let childTotal =
                            data?.nature === "debit"
                              ? parseFloat(debit) - parseFloat(credit)
                              : parseFloat(credit) - parseFloat(debit);

                          if (data?.childAccounts?.length > 0) {
                            const initialValue = {
                              credit: 0,
                              debit: 0,
                            };

                            const result = data?.childAccounts?.reduce(
                              (accumulator, transaction) => {
                                const credit = isNaN(transaction?.total_credit)
                                  ? 0
                                  : transaction?.total_credit;
                                const debit = isNaN(transaction?.total_debit)
                                  ? 0
                                  : transaction?.total_debit;
                                return {
                                  credit:
                                    parseFloat(accumulator.credit) +
                                    parseFloat(credit),
                                  debit:
                                    parseFloat(accumulator.debit) +
                                    parseFloat(debit),
                                };
                              },
                              initialValue
                            );
                            childTotal =
                              data?.nature === "debit"
                                ? parseFloat(result?.debit) -
                                  parseFloat(result?.credit)
                                : parseFloat(result?.credit) -
                                  parseFloat(result?.debit);
                          }
                          Total += parseFloat(childTotal);
                          GrandTotal += parseFloat(childTotal);
                          if (index !== 0) {
                            TotalEquity += parseFloat(childTotal);
                          }
                          return `
                    <tr class=${"table-row"}>
                      <td colspan="2">${data?.account_code}</td>
                      <td colspan="6">${data?.account_name}</td>
                      <td colspan="1">${data?.account_category}</td>
                      <td colspan="6">${data?.account_subcategory}</td>
                      <td colspan="1">
                    
                          </td>
                          <td colspan="1">
                          $${CommaSeparator(parseFloat(childTotal).toFixed(2))}
                           </td>
                    </tr>
                   
                  `;
                        })
                        .join("")
                    : ""
                }
                  ${
                    val?.accounts?.[0]?.type_code
                      ? `                <tr class="table-row">
                <td colspan="2">Total Of ${val?.accounts[0]?.type_code}</td>
                         <td colspan="4"></td>
                            <td colspan="2" class="total-name">Total ${
                              val?.name
                            }</td>
                              <td colspan="8"></td>
                                <td colspan="1"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(Total).toFixed(2)
                )}</td>
            </tr>
            `
                      : ""
                  }
              `
                    )
                    .join("")
                : ""
            }
              <tr class="table-row-blue">
              <td colspan="2">Total</td>
                 <td colspan="4"></td>
                <td colspan="2" class="total-name">Total ${values?.name}</td>
                 <td colspan="8"></td>
                <td colspan="1"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(GrandTotal).toFixed(2)
                )}</td>
            </tr> 
          `;
           })
           .join("")
   }
       ${
         invoiceName === "Balance Sheet"
           ? ` <tr class="table-row-green">
              <td colspan="2">Total</td>
                 <td colspan="4"></td>
                <td colspan="2" class="total-name">  Owner Capital + Liabilities</td>
                 <td colspan="8"></td>
                <td colspan="1"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(
                    parseFloat(mainTotal) + parseFloat(totalBalance)
                  ).toFixed(2)
                )}
                </td>

            </tr> `
           : ""
       }
  </tbody>
</table>

                                 
                                 </div>
                                      <div class='footer-content'>
                                   <div class='system-generated'>
                                   <p class="system-text">
                                  1- This is a system-generated statement and does not require a signature. Any discrepancies in this SOA should be reported within 2 days. 
                                   </p>
                                   <p class="system-text">
                                   2- Relist payments should be made on the next day of the relist. Otherwise, your account will be Locked on the second day and a 15% penalty will be charged on relists.						
                                   </p>
                                   <p class="system-text">
                                   	3- Kindly settle the payment due at the earliest, we would like to remind you to be in touch with our customer service support team. 	

                                   </p>
                                   </div>
                                        <div class = "footer-div">
                                       <small class="footer-div-font">
                                           Customer care Contact: Mohammed husni - +971523195682 (Arabic & English ) Ardamehr Shoev - +971545836028 (English ,Arabic, Tajik & Farsi)Ravin abdul kareem - +971528293801 (Kurdish , Arabic & English) Magsat Gylyjov - +97158666403 (Turken , Russian & English)
                                       </small>
                                   </div> 
                                   </div> 
                             </div>
                            
                         </div>
                         <script src = "script.js"></script>
                     </body>
                 </html>
`;

  const vhecicleSOA = `
     <!DOCTYPE html>
                    <html>
                        <head>
                            <meta charset="utf-8">
                            <meta http-equiv="X-UA-Compatible" content="IE=edge">
                            <title></title>
                            <meta name="description" content="">
                            <meta name="viewport" content="width=device-width, initial-scale=1">
                            <link rel="preconnect" href="https://fonts.googleapis.com">
                            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap" rel="stylesheet">
                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                           <style>
                            *,
                    *::after,
                    *::before{
                        padding: 0;
                        margin: 0;
                        box-sizing: border-box;
                    }
                        body{
                           padding: 0;
                        margin: 0;
                                                background:red;

                        }
                     .first-row{
            font-weight:700;
            font-size:16px;
            }
                    :root{
                        --blue-color: #0c2f54;
                        --dark-color: black;
                        --white-color: #fff;
                    }
                    
                    ul{
                        list-style-type: none;
                    }
                    ul li{
                        margin: 2px 0;
                    }
                    
                    /* text colors */
                    .text-dark{
                        color: var(--dark-color);
                    }
                    .text-blue{
                        color: var(--blue-color);
                    }
                    .text-end{
                        text-align: right;
                    }
                    .text-center{
                        text-align: center;
                    }
                    .text-start{
                        text-align: left;
                    }
                    .text-bold{
                        font-weight: 600;
                        font-size: 6px;
                    }
                    /* hr line */
                    .hr{
                        height: 1px;
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                    /* border-bottom */
                    .border-bottom{
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    }
                    
                    body{
                        font-family: 'Poppins', sans-serif;
                        color: var(--dark-color);
                        font-size: 10px;
                    }
                    
                    .invoice-wrapper{
                        min-height: 100vh;
                    }
                    .invoice{
                        max-width: 100%;
                        background:red;
                        background-color: var(--white-color);
                    }
                    .invoice-head-top-left img{
                        width: 130px;
                    }
                        .title{
                text-align:center;
                    font-size: 18px;
                    font-weight:700;
                    text-transform:capitalize
                }
                    .invoice-head-top-right h3{
                        font-weight: 500;
                        font-size: 20px;
                        color: var(--blue-color);
                    }
                    .invoice-head-middle, .invoice-head-bottom{
                        padding: 16px 0;
                    }
                    .invoice-body{
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        overflow: hidden;

                    }
                        .due-table-text{
                        font-weight:700;
                        font-size:12px;
                        }
                    .invoice-body table{
                        border-radius: 4px;
                        width: 100%;
                    }
                    .invoice-body table, .invoice-body table th{
                        padding: 6px;
                    }
                        td{
                        padding:6px 3px;
                        }
                    .invoice-body table tr{
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    }
                    .invoice-body table thead{
                        background-color: rgba(0, 0, 0, 0.02);
                    }
                    .invoice-body-info-item{
                        display: grid;
                    }
                    .invoice-body-info-item .info-item-td{
                        padding: 4px;
                        background-color: rgba(0, 0, 0, 0.02);
                    }
                    .invoice-foot{
                        padding: 30px 0;
                    }
                    .invoice-foot p{
                        font-size: 12px;
                    }
                    .invoice-btns{
                        margin-top: 20px;
                        display: flex;
                        justify-content: center;
                    }
                    .invoice-btn{
                        padding: 3px 9px;
                        color: var(--dark-color);
                        font-family: inherit;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                    }
                    
                    
                     .row-last-box{
                border: 1px solid black;
                background:#EFF0F6;
    padding: 6px;
    border-radius: 4px;
    margin-left:6px;
    display: flex;
    justify-content: center;
    text-align: center;
                }
                    .invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        padding-bottom: 10px;
                    }
                    
                    .header-img{
                    background-image: url('./pdf_header.png');
                        background-size: contain;
                        width: 100%;
                        height: 200px;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                    .usd-row{
                    font-weight:700;
                    font-size:16px
                    }
                    .footer-div{
                         width: 100%;
                        color: #FFFFFF;
                        padding:1.5%;
                        background-color: #5abc0e;
                    }
                        
                    .footer-div2{
                        width: 100%;
                        color: #FFFFFF;
                        padding:1.5%;
                        background-color: #5abc0e;

                    }
                        
                    .footer-div-font{
                        
                        font-size: 11px;
                    }
                   
                    .data-img{
                        width: 30vw;
                        position: relative;
                        right: 100%;
                        top: 70%;
                    }
                    
                    .row-data{
                        font-size: 6px;
                          
        text-align:start
                    }
                      .invoice-body-bottom {
        display: flex;
        justify-content: space-between;
    }
    .invoice-body-info-item {
        flex: 1;
        padding: 0 10px;
    }
    .info-item-td {
        margin: 5px 0;
    }
                    .invoice-title{
                        margin-top:30px;
                        position: relative;
                        left: 500px;
                        font-weight: normal;
                        font-size: 30px;
                    }
                    
                   
                    
                    @media screen and (max-width: 576px){
                        .invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
                            grid-template-columns: repeat(1, 1fr);
                        }
                        .invoice-head-bottom-right{
                            margin-top: 12px;
                            margin-bottom: 12px;
                        }
                        .invoice *{
                            text-align: left;
                        }
                        
                    }
                    
                    .overflow-view{
                        overflow-x: scroll;
                    }
                    .invoice-body{
                        min-width: 600px;
                    }
                    .last-row {
    display: flex;
    justify-content: space-between;
    align-items:center;
    width: 100%;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
       page-break-inside: avoid;
        page-break-before: auto;
        page-break-after: auto;
}
    .last-row-title{
     display: flex;
     margin-right:6px;
    width: 100%;
    align-items:center;
    }
                    @media print{
                        .print-area{
                            visibility: visible;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            overflow: hidden;
                        }
                    
                        .overflow-view{
                            overflow-x: hidden;
                        }
                    
                        .invoice-btns{
                            display: none;
                        }
                    }
                              .main-cont{
                              background-color: rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: space-between;
            align-items: center;
             grid-template-columns: repeat(12, 1fr);
            gap: 10px;
            padding: 20px;
        }
       
        .col-4 {
            grid-column: span 4;
        }
            .statement{
                border-bottom: 1px solid black;
                font-weight: 700;
                margin-bottom: 12px;
            }
            .client{
                padding: 2px 0px 2px 0px;
                display: flex;
                /* justify-content: space-between */
            }
            .client-font{
                font-size:10px;
                font-weight: 600;
                width: 120px;
            }
            .client-text{
                font-weight: 400;
                font-size:9px;
                color:#5abc0e;
                text-align: start;
                text-transform:capitalize;
            }
            .top-table-row{
  border-top: 1px solid;
    border-left: 1px solid;
  border-right: 1px solid;
  padding: 4px;
  font-size:12px;
  text-align:center

}
              .top-table-last-row{
  border: 1px solid;
  padding: 4px;
  font-size:12px;

}
.due-table-row{
 border: 1px solid;
  padding: 4px;
}
.top-table {
  width: 100%;
  border-collapse: collapse;
}
.table-vehicle{
    font-weight: 700;
    font-size:12px;

    color: red;
}
.unpaid-vehicle{
    width: 300px;
    font-weight: 700;
    font-size:12px;


}
.table-row{
    text-align: start;
    font-weight: 700;
    font-size:12px;


}
.table-date{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 700;
    font-size:12px;

}
.statement-period{
    font-weight: 700;
    font-size:12px;


}
.from-date{
    color: red;
}
.to-date{
    color:red
}
.generated{
    padding: 8px;
    display: flex;
    justify-content: end;
}
.generated-on{
    width: 120px;
}

.total-row{
font-weight:700;
font-size:12px;

}
.table-head{
font-size:8px;
font-weight:700;
background-color: #5abc0e;
color:white

}
.table-row{
font-size:11px;

}
.due-block{
    display: flex;
                background-color: rgba(0, 0, 0, 0.1);
    justify-content: end;
    align-items: center;
    padding: 8px 56px 8px 8px;
       page-break-inside: avoid;
        page-break-before: auto;
        page-break-after: auto;
}
.due-breakUp{
    width: 150px;
    font-weight: 700;
    font-size: 12px;
       page-break-inside: avoid;
        page-break-before: auto;
        page-break-after: auto;
}
   .shhippingyellow{
   background: #f3f33a
   
   }

   .shhippingred{
   background:#ee2d2d
   }
   .bottom-content{
   padding:6px;
   margin:8px;
   color:black
   }
   .shopping-bottom{
    padding:6px;
   margin:8px;
   color:black
   }

   .shopping-table-row{
   heigh:80px;
 border: 1px solid;
  padding: 6px;
  font-size:10px
}  
      .vertical-text {
writing-mode: tb-rl;
    transform: scale(-1);
    width:100px;

}
.table-green-row{
background-color:#EFF8E7;
color:black;
    text-align: start;
    font-weight: 700;
    font-size:11px;
}

  .system-generated{
                     background:rgba(0, 0, 0, 0.1);
                     color:black;
                     padding:8px 8px
                     }
                     .system-text{
                     padding:2px;
                     font-size:10px;
                     font-weight:700;
                     }
                      .footer-content {
                     margin-top:8px;
                      position:absoulte;
                     bottom:0;
                     left:0;
                      }
           .footer-content2 {
                   margin-top:8px;

                      }
           </style>
                        </head>
                        <body>
                    
                            <div class = "invoice-wrapper" id = "print-area">
                                    <img src=${
                                      invoiceName ===
                                        "Vehicle Statement Of Account" ||
                                      invoiceName === "Vehicle Wallet Statement"
                                        ? VehicleSOA
                                        : HeaderImage
                                    } width="100%" height="195px" />
                                    <div class = "invoice">
                                    <div class = "invoice-container">
                                    <div class="main-cont">
                                <div class="col col-4">
                                    <div> 
                                    <div class="">
                                            <span class="statement">Statement To :-</span>
                                         </div>                                </div>
                                    <div class="client">
                                        <div class="client-font">Client Name: </div>
                                        <div class="client-text">${
                                          selectedCustomer &&
                                          selectedCustomer?.name
                                            ? selectedCustomer?.name
                                            : "N/A"
                                        }</div>
                                    </div>
                                    <div class="client">
                                        <div class="client-font">Customer Ident No: </div>
                                        <div class="client-text">
                                       ${
                                         selectedCustomer &&
                                         selectedCustomer?.ref_id
                                           ? selectedCustomer?.ref_id
                                           : selectedCustomer?.id
                                           ? `C-${selectedCustomer?.id}`
                                           : "N/A"
                                       }

                                        </div>
                                    </div>
                                    <div class="client">
                                        <div class="client-font">Phone: </div>
                                        <div class="client-text">
                                        ${
                                          selectedCustomer &&
                                          selectedCustomer?.uae_phone
                                            ? selectedCustomer?.uae_phone
                                            : "N/A"
                                        }
                                        </div>
                                    </div>
                                   
                                </div>
                              <div class="col col-4">
                                                              <h2 class="title"> ${invoiceName}</h2>

                              </div>
                                <div class="unpaid-vehicle col col-4">
                                ${
                                  startDate && endDate
                                    ? ` <table class="top-table">
        <tr class="table-row">
            ${
              invoiceName === "Shipping Statement Of Account" ||
              invoiceName === "Vehicle Statement Of Account"
                ? `<td class="top-table-row">
                <span class="table-vehicle">${status ? status : "All"} ${
                    invoiceName === "Shipping Statement Of Account"
                      ? "Shipping"
                      : "Vehicle"
                  }</span>
            </td>`
                : ``
            }
        </tr>
        <tr class="table-row">
            <td class="top-table-row">
            <span  class="statement-period"></span>Statement Period</span>
          </td>
        </tr>
        <tr>
            <td class="top-table-last-row">
           <div class="table-date"> <span >From:</span>
            <span class="from-date">${startDate}</span>
            <span>To:</span>
            <span class="to-date">${endDate}</span></div>

          </td>
        </tr>
      </table>  `
                                    : ""
                                }
   <div class="generated">
    <span class="generated-on">
        Generated on: 
      </span>
      <span>${currentDate}</span>
   </div>
   
</div>
                            </div>
                                                                                     
                                        <div class = "overflow-view">
                                            <div class = "invoice-body">
                                                <table>
                                                 <thead>
                                                        <tr class="table-head">
                                                    ${
                                                      invoiceName ===
                                                        "Shipping Statement Of Account" ||
                                                      invoiceName ===
                                                        "VAT Due Statement"
                                                        ? `   <td class = "table-head">Sr.No</td>`
                                                        : ""
                                                    }
                                                        ${tableHead
                                                          ?.map((item) => {
                                                            return `
                                 <td class = "table-head">${item}</td>
                                                      `;
                                                          })
                                                          .join("")}
                                                                           
                                                        </tr>
                                                    </thead> 
                                                  
                                        ${
                                          invoiceName ===
                                          "Vehicle Statement Of Account"
                                            ? currency
                                                ?.map((item, index) => {
                                                  return `
                                            <tbody>
                                                <tr class=${
                                                  index % 2 !== 0
                                                    ? "table-green-row table-row"
                                                    : "table-row"
                                                }>
                                                    <td class="row-data usd-row">${
                                                      index + 1
                                                    }</td>
                                                    <td class="row-data usd-row">${
                                                      item?.booking?.buyer
                                                        ?.name || ""
                                                    }</td>
                                                     <td class="row-data usd-row">${
                                                       moment(
                                                         item?.created_at
                                                       ).format("DD-MM-YY") ||
                                                       ""
                                                     }</td>
                                                    <td class="row-data usd-row">${
                                                      item?.booking?.veh_model
                                                        ?.name || ""
                                                    }</td>
                                                   
                                                    <td class="row-data usd-row">${
                                                      item?.booking?.veh_make
                                                        ?.name || ""
                                                    }</td>
                                                    
                                                    <td class="row-data usd-row">${
                                                      item?.booking
                                                        ?.lot_number || ""
                                                    }</td>
                                                    <td class="row-data usd-row">${
                                                      item?.booking?.vin || ""
                                                    }</td>
                                                    <td class="row-data usd-row">${
                                                      item?.booking?.color || ""
                                                    }</td>
                                                    <td class="row-data usd-row">$${
                                                      CommaSeparator(
                                                        parseFloat(
                                                          item?.booking?.value
                                                        ).toFixed(2)
                                                      ) || ""
                                                    }</td>


                                                     <td class="row-data usd-row">$${
                                                       CommaSeparator(
                                                         parseFloat(
                                                           item?.booking
                                                             ?.other_charges
                                                         ).toFixed(2)
                                                       ) || ""
                                                     }</td>


                                                      <td class="row-data usd-row">$${
                                                        CommaSeparator(
                                                          parseFloat(
                                                            item?.paid
                                                          ).toFixed(2)
                                                        ) || ""
                                                      }</td>


                                                      <td class="row-data usd-row">$${
                                                        CommaSeparator(
                                                          parseFloat(
                                                            item?.balance
                                                          ).toFixed(2)
                                                        ) || ""
                                                      }</td>


                                                        <td class="row-data usd-row" colSpan=2>${
                                                          moment().diff(
                                                            item?.created_at,
                                                            "days"
                                                          ) || ""
                                                        }</td>
                                                </tr>
                                             
                                            </tbody>
                                            
                                        `;
                                                })
                                                .join("")
                                            : invoiceName ===
                                              "Shipping Statement Of Account"
                                            ? `
                                                  ${currency
                                                    ?.map((item, index) => {
                                                      return `
                                                <tbody>
                                                <tr class=${
                                                  index % 2 !== 0
                                                    ? "table-green-row table-row"
                                                    : "table-row"
                                                }>
                                                <td class="row-data usd-row">
                                                ${index + 1}
                                                </td>
                                                <td class="row-data">${moment(
                                                  item?.booking?.purchase_date
                                                ).format("DD-MMM-YY")}</td>
                                                        <td class="row-data">${
                                                          item?.booking
                                                            ?.veh_model?.name ??
                                                          "-"
                                                        }</td>
                                                         <td class="row-data">${
                                                           item?.booking
                                                             ?.veh_make?.name ??
                                                           "-"
                                                         }</td>
                                                        <td class="row-data">${
                                                          item?.booking
                                                            ?.lot_number ?? "-"
                                                        }</td>
                                                       
                                                        <td class="row-data">${
                                                          item?.booking?.vin ??
                                                          "-"
                                                        }</td>
                                                        
                                                        <td class="row-data">${
                                                          item?.booking
                                                            ?.color ?? "-"
                                                        }</td>
                                                        <td class="text-end row-data">${
                                                          item?.vehicle?.vcc
                                                            ? "Yes"
                                                            : "No" ?? "-"
                                                        }</td>
                                                        <td class="text-end row-data">${
                                                          item?.invoice &&
                                                          item?.invoice?.paid ==
                                                            0
                                                            ? "Unpaid"
                                                            : item?.invoice &&
                                                              item?.invoice
                                                                ?.paid > 0 &&
                                                              item?.invoice
                                                                ?.paid <
                                                                item?.invoice
                                                                  ?.total
                                                            ? "Partial"
                                                            : "Paid"
                                                        }</td>
                                                        <td class="text-end row-data">${
                                                          item?.vehicle
                                                            ?.arrived_port_date
                                                            ? moment(
                                                                item?.vehicle
                                                                  ?.arrived_port_date
                                                              ).format(
                                                                "DD-MMM-YY"
                                                              )
                                                            : "-"
                                                        }</td>


                                                         <td class="text-end row-data">$${
                                                           CommaSeparator(
                                                             parseFloat(
                                                               item?.shipping_charges
                                                             ).toFixed(2)
                                                           ) ?? "-"
                                                         }</td>


                                                          <td class="text-end row-data">$${
                                                            CommaSeparator(
                                                              parseFloat(
                                                                item?.late_fee
                                                              ).toFixed(2)
                                                            ) ?? "-"
                                                          }</td>


                                                          <td class="text-end row-data">$${
                                                            CommaSeparator(
                                                              parseFloat(
                                                                item?.storage
                                                              ).toFixed(2)
                                                            ) ?? "-"
                                                          }</td>


                                                            <td class="text-end row-data">$${
                                                              CommaSeparator(
                                                                parseFloat(
                                                                  item?.category_a
                                                                ).toFixed(2)
                                                              ) ?? "-"
                                                            }</td>


                                                            <td class="text-end row-data">$${
                                                              CommaSeparator(
                                                                parseFloat(
                                                                  item?.broker_fee
                                                                ).toFixed(2)
                                                              ) ?? "-"
                                                            }</td>


                                                            <td class="text-end row-data">$${
                                                              CommaSeparator(
                                                                parseFloat(
                                                                  item?.title_fee
                                                                ).toFixed(2)
                                                              ) ?? "-"
                                                            }</td>


                                                         
                                                             <td class="text-end row-data">$${
                                                               CommaSeparator(
                                                                 parseFloat(
                                                                   item?.other_charge
                                                                 ).toFixed(2)
                                                               ) ?? "-"
                                                             }
                                                               </td>
                                                                <td class="text-end row-data">$${CommaSeparator(
                                                                  parseFloat(
                                                                    item
                                                                      ?.vehicle
                                                                      ?.vcc
                                                                      ?.custom_due
                                                                      ? item?.custom_duty ==
                                                                        0
                                                                        ? item
                                                                            ?.vehicle
                                                                            ?.vcc
                                                                            ?.custom_charges_aed /
                                                                          currencyExchangeRate
                                                                        : item?.custom_duty
                                                                      : 0
                                                                  ).toFixed(2)
                                                                )}
    </td>


                                                             <td class="text-end row-data">$${CommaSeparator(
                                                               parseFloat(
                                                                 item?.vehicle
                                                                   ?.vcc
                                                                   ?.vat_due
                                                                   ? item?.vat ==
                                                                     0
                                                                     ? item
                                                                         ?.vehicle
                                                                         ?.vcc
                                                                         ?.vat_charges_aed /
                                                                       currencyExchangeRate
                                                                     : item?.vat
                                                                   : 0
                                                               ).toFixed(2)
                                                             )}</td>


                                                              <td class="text-end row-data">&${
                                                                CommaSeparator(
                                                                  (
                                                                    parseFloat(
                                                                      item?.shipping_charges
                                                                    ) +
                                                                    parseFloat(
                                                                      item?.late_fee
                                                                    ) +
                                                                    parseFloat(
                                                                      item?.storage
                                                                    ) +
                                                                    parseFloat(
                                                                      item?.category_a
                                                                    ) +
                                                                    parseFloat(
                                                                      item?.broker_fee
                                                                    ) +
                                                                    parseFloat(
                                                                      item?.title_fee
                                                                    ) +
                                                                    parseFloat(
                                                                      item?.other_charge
                                                                    )
                                                                  ).toFixed(2)
                                                                ) ?? "-"
                                                              }</td>


                                                               <td class="text-end row-data">$${
                                                                 CommaSeparator(
                                                                   parseFloat(
                                                                     item?.discount
                                                                   ).toFixed(2)
                                                                 ) ?? "-"
                                                               }</td>

                                                                <td class="row-data">$${
                                                                  CommaSeparator(
                                                                    parseFloat(
                                                                      item
                                                                        ?.invoice
                                                                        ?.total
                                                                    ).toFixed(2)
                                                                  ) ?? "-"
                                                                }</td>

                                                                         <td class="text-end row-data usd-row">$${CommaSeparator(
                                                                           parseFloat(
                                                                             item
                                                                               ?.invoice
                                                                               ?.paid
                                                                           ).toFixed(
                                                                             2
                                                                           )
                                                                         )}</td>


                                                                         <td class="text-end row-data usd-row">$${CommaSeparator(
                                                                           parseFloat(
                                                                             item
                                                                               ?.invoice
                                                                               ?.balance
                                                                           ).toFixed(
                                                                             2
                                                                           )
                                                                         )}</td>

                                                                         
                                                                         <td class="text-end row-data usd-row">${moment().diff(
                                                                           item?.createdAt,
                                                                           "days"
                                                                         )} Days</td>

                                                    </tr>
                                                    
                                                </tbody>
                                            `;
                                                    })
                                                    .join("")}
                                            `
                                            : invoiceName ===
                                              "VAT Due Statement"
                                            ? currency
                                                ?.map((item, index) => {
                                                  return `
                                            <tbody>
                                                <tr class=${
                                                  index % 2 !== 0
                                                    ? "table-green-row table-row"
                                                    : "table-row"
                                                }>
                                                <td class="row-data">${
                                                  index + 1
                                                }</td>
                                                    <td class="row-data">${
                                                      item?.customer_id
                                                    }</td>
                                                    <td class="row-data">${
                                                      item?.customer?.name
                                                    }</td>
                                                     <td class="row-data">${
                                                       item?.vin ?? "-"
                                                     }</td>
                                                    <td class="row-data">${
                                                      item?.lot_number ?? "-"
                                                    }</td>
                                                   
                                                    <td class="row-data">${
                                                      item?.veh_make?.name ??
                                                      "-"
                                                    }</td>
                                                    
                                                    <td class="row-data">${
                                                      item?.veh_model?.name ??
                                                      "-"
                                                    }</td>
                                                    <td class="row-data">${
                                                      item?.color ?? "-"
                                                    }</td>
                                                     <td class="text-start row-data">$${
                                                       CommaSeparator(
                                                         parseFloat(
                                                           item?.vcc
                                                             ?.vat_charges_aed
                                                         ).toFixed(2)
                                                       ) ?? "-"
                                                     }</td>
                                                  
                                                   
                                                </tr>
                                                
                                            </tbody>
                             `;
                                                })
                                                .join("")
                                            : invoiceName ===
                                              "Vendor Payable Due Report"
                                            ? currency
                                                ?.map((item, index) => {
                                                  return `
                                        <tbody>
                                             <tr class=${
                                               index % 2 !== 0
                                                 ? "table-green-row table-row"
                                                 : "table-row"
                                             }>
                                                <td class="row-data">${
                                                  item?.booking?.purchase_date
                                                    ? moment(
                                                        item?.booking
                                                          ?.purchase_date
                                                      ).format("DD-MMM-YY")
                                                    : "-"
                                                }</td>
                                                <td class="row-data">${
                                                  item?.booking?.veh_model
                                                    ?.name ?? "-"
                                                }</td>
                                                 <td class="row-data">${
                                                   item?.booking?.veh_make
                                                     ?.name ?? "-"
                                                 }</td>
                                                <td class="row-data">${
                                                  item?.booking?.vin ?? "-"
                                                }</td>
                                               
                                                <td class="row-data">${
                                                  item?.booking?.lot_number ??
                                                  "-"
                                                }</td>
                                                
                                                <td class="row-data">${
                                                  item?.booking?.color ?? "-"
                                                }</td>
                                                <td class="row-data">${
                                                  item?.shipping?.loading_port
                                                    ?.name ?? "-"
                                                }</td>
                                                <td class="row-data">${
                                                  item?.shipping
                                                    ?.container_no ?? "-"
                                                }</td>
                                                <td class="row-data usd-row">${
                                                  item?.vehicle
                                                    ?.arrived_galaxy_date
                                                    ? moment(
                                                        item?.vehicle
                                                          ?.arrived_galaxy_date
                                                      ).format("DD-MMM-YY")
                                                    : "-"
                                                }
                                                    </td>
                                                <td class="row-data usd-row">${
                                                  selectedVendor?.type ===
                                                  "shipping"
                                                    ? item?.shipping_vendor_total
                                                    : selectedVendor?.type ===
                                                      "towing"
                                                    ? item?.towing_vendor_total
                                                    : item?.clearance_vendor_total
                                                }</td>

                                                    <td class="row-data">${
                                                      selectedVendor?.type ===
                                                      "shipping"
                                                        ? item?.shipping_vendor_paid
                                                        : selectedVendor?.type ===
                                                          "towing"
                                                        ? item?.towing_vendor_paid
                                                        : item?.clearance_vendor_paid
                                                    }</td>

 <td class="row-data">$${CommaSeparator(
   parseFloat(
     selectedVendor?.type === "shipping"
       ? item?.shipping_vendor_total
       : selectedVendor?.type === "towing"
       ? item?.towing_vendor_total
       : item?.clearance_vendor_total
   ) -
     parseFloat(
       selectedVendor?.type === "shipping"
         ? item?.shipping_vendor_paid
         : selectedVendor?.type === "towing"
         ? item?.towing_vendor_paid
         : item?.clearance_vendor_paid
     )
 )}</td>

                                            </tr>
                                            
                                        </tbody>
                                    `;
                                                })
                                                .join("")
                                            : invoiceName ===
                                              "Shipping Wallet Statement"
                                            ? currency
                                                ?.map((item, index) => {
                                                  return `
                                        <tbody>
                                            <tr class=${
                                              index % 2 !== 0
                                                ? "table-green-row table-row"
                                                : "table-row"
                                            }>
                                                <td class="row-data">${
                                                  item?.created_at
                                                    ? moment(
                                                        item?.created_at
                                                      ).format("DD/MM/YY")
                                                    : "-"
                                                }</td>
                                                <td class="row-data">${
                                                  item?.journal_id
                                                    ? item?.series_id +
                                                      item?.journal_id
                                                    : "-"
                                                }</td>
                                                 <td class="row-data">${
                                                   item?.entry?.reference_no ??
                                                   "-"
                                                 }</td>
                                                <td class="row-data">${
                                                  item?.type?.type_name ?? "-"
                                                }</td>
                                               
                                                <td class="row-data">${
                                                  item?.description ?? "-"
                                                }</td>
                                                
                                                <td class="row-data">${
                                                  item?.comment ?? "-"
                                                }</td>
                                                <td class="row-data">$${CommaSeparator(
                                                  parseFloat(
                                                    item?.debit_cur
                                                  ).toFixed(2)
                                                )}</td>
                                                <td class="row-data">$${CommaSeparator(
                                                  parseFloat(
                                                    item?.credit_cur
                                                  ).toFixed(2)
                                                )}</td>
                                                <td class="row-data usd-row">$${(Balance +=
                                                  parseFloat(
                                                    item?.account?.nature ===
                                                      "credit"
                                                      ? (
                                                          parseFloat(
                                                            item?.credit_cur
                                                          ).toFixed(2) -
                                                          parseFloat(
                                                            item?.debit_cur
                                                          ).toFixed(2)
                                                        ).toFixed(2)
                                                      : (
                                                          parseFloat(
                                                            item?.debit_cur
                                                          ).toFixed(2) -
                                                          parseFloat(
                                                            item?.credit_cur
                                                          ).toFixed(2)
                                                        ).toFixed(2)
                                                  )).toFixed(2)}</td>
                                            </tr>
                                            
                                        </tbody>
                                    `;
                                                })
                                                .join("")
                                            : invoiceName ===
                                              "Vehicle Wallet Statement"
                                            ? currency
                                                ?.map((item, index) => {
                                                  return `
                                        <tbody>
                                                                               <tr>
                                                <td class="row-data">${
                                                  item?.created_at
                                                    ? moment(
                                                        item?.created_at
                                                      ).format("DD/MM/YY")
                                                    : "-"
                                                }</td>
                                                <td class="row-data">${
                                                  item?.journal_id
                                                    ? item?.series_id +
                                                      item?.journal_id
                                                    : "-"
                                                }</td>
                                                 <td class="row-data">${
                                                   item?.entry?.reference_no ??
                                                   "-"
                                                 }</td>
                                                <td class="row-data">${
                                                  item?.type?.type_name ?? "-"
                                                }</td>
                                               
                                                <td class="row-data">${
                                                  item?.description ?? "-"
                                                }</td>
                                                
                                                <td class="row-data">${
                                                  item?.comment ?? "-"
                                                }</td>
                                                <td class="row-data">$${CommaSeparator(
                                                  parseFloat(
                                                    item?.debit_cur
                                                  ).toFixed(2)
                                                )}</td>
                                                <td class="row-data">$${CommaSeparator(
                                                  parseFloat(
                                                    item?.credit_cur
                                                  ).toFixed(2)
                                                )}</td>
                                                <td class="row-data usd-row">$${(Balance +=
                                                  parseFloat(
                                                    item?.account?.nature ===
                                                      "credit"
                                                      ? (
                                                          parseFloat(
                                                            item?.credit_cur
                                                          ).toFixed(2) -
                                                          parseFloat(
                                                            item?.debit_cur
                                                          ).toFixed(2)
                                                        ).toFixed(2)
                                                      : (
                                                          parseFloat(
                                                            item?.debit_cur
                                                          ).toFixed(2) -
                                                          parseFloat(
                                                            item?.credit_cur
                                                          ).toFixed(2)
                                                        ).toFixed(2)
                                                  )).toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    `;
                                                })
                                                .join("")
                                            : invoiceName ===
                                              "Shipping RA Aging"
                                            ? currency
                                                ?.map((item, index) => {
                                                  return `
                                        <tbody>
                                            <tr class=${
                                              index % 2 !== 0
                                                ? "table-green-row table-row"
                                                : "table-row"
                                            }>
                                                <td class="row-data">${
                                                  index + 1
                                                }</td>
                                                <td class="row-data">${
                                                  item?.ref_id
                                                }</td>
                                                 <td class="row-data">${
                                                   item?.nationality_country ??
                                                   "-"
                                                 }</td>
                                                <td class="row-data">${
                                                  item?.name
                                                }</td>
                                               
                                                <td class="row-data">$${
                                                  CommaSeparator(
                                                    parseFloat(
                                                      item?.total_count
                                                    ).toFixed(2)
                                                  ) ?? "-"
                                                }</td>
                                                
                                                <td class="row-data">$${
                                                  CommaSeparator(
                                                    parseFloat(
                                                      item?.sum_15
                                                    ).toFixed(2)
                                                  ) ?? "-"
                                                }</td>
                                                <td class="row-data ${
                                                  parseFloat(item?.sum_16_30) >
                                                  0
                                                    ? "shhippingyellow"
                                                    : ""
                                                }">$${
                                                    CommaSeparator(
                                                      parseFloat(
                                                        item?.sum_16_30
                                                      ).toFixed(2)
                                                    ) ?? "-"
                                                  }
                                                }</td>
                                                <td class="row-data ${
                                                  parseFloat(item?.sum_30) > 0
                                                    ? "shhippingred"
                                                    : ""
                                                }">$${
                                                    CommaSeparator(
                                                      parseFloat(
                                                        item?.sum_30
                                                      ).toFixed(2)
                                                    ) ?? "-"
                                                  }</td>
                                                <td class="row-data usd-row">$${
                                                  CommaSeparator(
                                                    parseFloat(
                                                      parseFloat(item?.sum_15) +
                                                        parseFloat(
                                                          item?.sum_16_30
                                                        ) +
                                                        parseFloat(item?.sum_30)
                                                    ).toFixed(2)
                                                  ) ?? "-"
                                                }</td>
                                               
                                            </tr>
                                            
                                        </tbody>
                                    `;
                                                })
                                                .join("")
                                            : currency
                                                ?.map((item, index) => {
                                                  return `
                                            <tbody>
                                                <tr class=${
                                                  index % 2 !== 0
                                                    ? "table-green-row table-row"
                                                    : "table-row"
                                                }>
                                                    <td class="row-data">${
                                                      item?.booking?.customer
                                                        ?.id ?? "-"
                                                    }</td>
                                                    <td class="row-data">${
                                                      item?.booking?.customer
                                                        ?.name ?? "-"
                                                    }</td>
                                                     <td class="row-data">${
                                                       item?.booking
                                                         ?.auctioneer ?? "-"
                                                     }</td>
                                                    <td class="row-data">${
                                                      item?.booking?.buyer
                                                        ?.name ?? "-"
                                                    }</td>
                                                   
                                                    <td class="row-data">${
                                                      item?.booking?.vin ?? "-"
                                                    }</td>
                                                    
                                                    <td class="row-data">${
                                                      item?.booking
                                                        ?.lot_number ?? "-"
                                                    }</td>
                                                    <td class="row-data">${
                                                      item?.booking?.veh_make
                                                        ?.name ?? "-"
                                                    }</td>
                                                    <td class="row-data">${
                                                      item?.booking?.veh_model
                                                        ?.name
                                                    }</td>
                                                    <td class="row-data usd-row">${
                                                      item?.booking?.color ??
                                                      "-"
                                                    }</td>
                                                    <td class="row-data usd-row">$${
                                                      CommaSeparator(
                                                        parseFloat(
                                                          item?.remaining_due
                                                        ).toFixed(2)
                                                      ) ?? "-"
                                                    }
   </td>

                                                </tr>
                                                
                                            </tbody>
                                        `;
                                                })
                                                .join("")
                                        }
                                        ${
                                          invoiceName ===
                                          "Vehicle Statement Of Account"
                                            ? `
                                             <tr class="total-row">
                                                <td class="text-center" colSpan=${
                                                  tableHead?.length - 5
                                                }>
                                                Total:
                                                
                                                </td>
                                                <td>
                                                 $${CommaSeparator(
                                                   parseFloat(
                                                     totalVehicleAmount
                                                   ).toFixed(2)
                                                 )}
                                                 </td>
                                                 <td>
                                                $${CommaSeparator(
                                                  parseFloat(
                                                    otherCharges
                                                  ).toFixed(2)
                                                )}
                                                 </td>
                                                 <td>
                                                  $${CommaSeparator(
                                                    parseFloat(
                                                      totalPaid
                                                    ).toFixed(2)
                                                  )}
                                                 </td>


                                                 <td>
                                                  $${CommaSeparator(
                                                    parseFloat(
                                                      totalBalance
                                                    ).toFixed(2)
                                                  )}
                                                 </td>
                                                 <td>
                                                 </td>
                                                </tr>
                                                <tr class="total-row">
                                                <td class="text-center" colSpan=${
                                                  tableHead?.length - 5
                                                }>
                                                Net Due Total:
                                                
                                                </td>
                                                <td>
                                               
                                                 </td>
                                                 <td colSpan=4>
                                                   $${CommaSeparator(
                                                     parseFloat(
                                                       parseFloat(
                                                         TotalBalance
                                                       ) -
                                                         parseFloat(
                                                           vehicleVault?.nature ===
                                                             "credit"
                                                             ? isNaN(
                                                                 parseFloat(
                                                                   vehicleVault?.total_credit
                                                                 ) -
                                                                   parseFloat(
                                                                     vehicleVault?.total_debit
                                                                   )
                                                               )
                                                               ? parseFloat(
                                                                   0
                                                                 ).toFixed(2)
                                                               : parseFloat(
                                                                   parseFloat(
                                                                     vehicleVault?.total_credit
                                                                   ) -
                                                                     parseFloat(
                                                                       vehicleVault?.total_debit
                                                                     )
                                                                 ).toFixed(2)
                                                             : isNaN(
                                                                 parseFloat(
                                                                   vehicleVault?.total_debit
                                                                 ) -
                                                                   parseFloat(
                                                                     vehicleVault?.total_credit
                                                                   )
                                                               )
                                                             ? parseFloat(
                                                                 0
                                                               ).toFixed(2)
                                                             : parseFloat(
                                                                 parseFloat(
                                                                   vehicleVault?.total_debit
                                                                 ) -
                                                                   parseFloat(
                                                                     vehicleVault?.total_credit
                                                                   )
                                                               )
                                                         )
                                                     ).toFixed(2)
                                                   )}
                                                 </td>
                                                 <td>
                                               
                                                 </td>


                                                 <td>
                                               
                                                 </td>
                                                 <td></td>
                                                </tr>
                                          `
                                            : invoiceName ===
                                              "Shipping RA Aging"
                                            ? `
                                               <tr class="total-row">
                                                <td class="text-center" colSpan=${
                                                  tableHead?.length - 4
                                                }>
                                                Total:
                                                
                                                </td>
                                                  <td>
                                                 $${CommaSeparator(
                                                   parseFloat(
                                                     otherCharges
                                                   ).toFixed(2)
                                                 )}
                                                 </td>
                                                   <td>
                                                 $${CommaSeparator(
                                                   parseFloat(
                                                     totalBalance
                                                   ).toFixed(2)
                                                 )}
                                                 </td>
                                                <td>
                                                 $${CommaSeparator(
                                                   parseFloat(
                                                     totalPaid
                                                   ).toFixed(2)
                                                 )}
                                                 </td>
<td>
                                                $${CommaSeparator(
                                                  parseFloat(
                                                    totalVehicleAmount
                                                  ).toFixed(2)
                                                )}
                                                 </td>
                                               
                                               

                                               
                                                </tr>
                                                
                                            `
                                            : invoiceName ===
                                              "Shipping Statement Of Account"
                                            ? `
                                             <tr class="total-row">
                                                <td class="text-center" colSpan=${
                                                  tableHead?.length - 4
                                                }>
                                                Total:
                                                
                                                </td>
                                                <td>
                                                $${CommaSeparator(
                                                  parseFloat(mainTotal).toFixed(
                                                    2
                                                  )
                                                )}
                                                 </td>
                                                 <td>
                                                $${CommaSeparator(
                                                  parseFloat(totalPaid).toFixed(
                                                    2
                                                  )
                                                )}
                                                 </td>
                                                 <td>
                                                 $${CommaSeparator(
                                                   parseFloat(
                                                     totalBalance
                                                   ).toFixed(2)
                                                 )}
                                                 </td>


                                              
                                                 <td>
                                                 </td>
                                                </tr>
                                                <tr class="total-row">
                                                <td class="text-center" colSpan=${
                                                  tableHead?.length - 5
                                                }>
                                                Net Due Total:
                                                
                                                </td>
                                                <td>
                                               
                                                 </td>
                                                 <td colSpan=4>
                                                   $${CommaSeparator(allSums)}
                                                 </td>
                                                 <td>
                                               
                                                 </td>


                                                 <td>
                                               
                                                 </td>
                                                 <td></td>
                                                </tr>
                                          `
                                            : ""
                                        }
                                               
                                                </table>  

                                           ${
                                             invoiceName === "VAT Due Statement"
                                               ? `<div class="last-row">
                                                <div class="last-row-title">Total Vat Due <span class="row-last-box">$${CommaSeparator(
                                                  currencyExchangeRate
                                                )}
                                                <span>
                                                </div>
                                                </div>
                                            `
                                               : invoiceName ===
                                                 "Vendor Payable Due Report"
                                               ? `
                                            <div class="last-row text-end d-flex justify-content-end align-items-center">
                                            <div class="last-row-title">Balance <span class="row-last-box">$
                                            ${CommaSeparator(
                                              parseFloat(TotalBalance).toFixed(
                                                2
                                              )
                                            )}
                                              <span>
                                              </div>
                                               </div>
                                              `
                                               : invoiceName === "Auction House"
                                               ? `
                                               <div class="last-row">
                                            <div class="last-row-title">Total Remaining Due <span class="row-last-box">$${currencyExchangeRate}
                                            <span>
                                             </div>
                                            </div>
                                            `
                                               : invoiceName ===
                                                 "Vehicle Statement Of Account"
                                               ? `
                                               <div class="last-row">
                                                 <div class="last-row-title">Shipping Wallet <span class="row-last-box">
                                                 $${
                                                   shippingVault?.nature ===
                                                   "credit"
                                                     ? isNaN(
                                                         parseFloat(
                                                           shippingVault?.total_credit
                                                         ) -
                                                           parseFloat(
                                                             shippingVault?.total_debit
                                                           )
                                                       )
                                                       ? parseFloat(0).toFixed(
                                                           2
                                                         )
                                                       : CommaSeparator(
                                                           parseFloat(
                                                             parseFloat(
                                                               shippingVault?.total_credit
                                                             ) -
                                                               parseFloat(
                                                                 shippingVault?.total_debit
                                                               )
                                                           ).toFixed(2)
                                                         )
                                                     : isNaN(
                                                         parseFloat(
                                                           shippingVault?.total_debit
                                                         ) -
                                                           parseFloat(
                                                             shippingVault?.total_credit
                                                           )
                                                       )
                                                     ? parseFloat(0).toFixed(2)
                                                     : CommaSeparator(
                                                         parseFloat(
                                                           parseFloat(
                                                             shippingVault?.total_debit
                                                           ) -
                                                             parseFloat(
                                                               shippingVault?.total_credit
                                                             )
                                                         ).toFixed(2)
                                                       )
                                                 }
                                                       <span>
                                                       </div>
                                                       <div class="last-row-title">Vehicle Wallet 
                                                       <span class="row-last-box">
                                                       $${
                                                         vehicleVault?.nature ===
                                                         "credit"
                                                           ? isNaN(
                                                               parseFloat(
                                                                 vehicleVault?.total_credit
                                                               ) -
                                                                 parseFloat(
                                                                   vehicleVault?.total_debit
                                                                 )
                                                             )
                                                             ? parseFloat(
                                                                 0
                                                               ).toFixed(2)
                                                             : CommaSeparator(
                                                                 parseFloat(
                                                                   parseFloat(
                                                                     vehicleVault?.total_credit
                                                                   ) -
                                                                     parseFloat(
                                                                       vehicleVault?.total_debit
                                                                     )
                                                                 ).toFixed(2)
                                                               )
                                                           : isNaN(
                                                               parseFloat(
                                                                 vehicleVault?.total_debit
                                                               ) -
                                                                 parseFloat(
                                                                   vehicleVault?.total_credit
                                                                 )
                                                             )
                                                           ? parseFloat(
                                                               0
                                                             ).toFixed(2)
                                                           : CommaSeparator(
                                                               parseFloat(
                                                                 parseFloat(
                                                                   vehicleVault?.total_debit
                                                                 ) -
                                                                   parseFloat(
                                                                     vehicleVault?.total_credit
                                                                   )
                                                               ).toFixed(2)
                                                             )
                                                       }
                                                             <span>
                                                             </div>
                                                              </div>
                                           
                                            `
                                               : invoiceName ===
                                                 "Shipping Statement Of Account"
                                               ? `
                                                 <div class="last-row">
                                                  <div class="last-row-title">No.Of Vcc <span class="row-last-box">
                                                  ${CommaSeparator(totalVCC)}
                                                  <span>
                                                  </div>
                                                 <div class="last-row-title">Shipping Wallet <span class="row-last-box">
                                                $${
                                                  shippingVault?.nature ===
                                                  "credit"
                                                    ? isNaN(
                                                        parseFloat(
                                                          shippingVault?.total_credit
                                                        ) -
                                                          parseFloat(
                                                            shippingVault?.total_debit
                                                          )
                                                      )
                                                      ? parseFloat(0).toFixed(2)
                                                      : CommaSeparator(
                                                          parseFloat(
                                                            parseFloat(
                                                              shippingVault?.total_credit
                                                            ) -
                                                              parseFloat(
                                                                shippingVault?.total_debit
                                                              )
                                                          ).toFixed(2)
                                                        )
                                                    : isNaN(
                                                        parseFloat(
                                                          shippingVault?.total_debit
                                                        ) -
                                                          parseFloat(
                                                            shippingVault?.total_credit
                                                          )
                                                      )
                                                    ? parseFloat(0).toFixed(2)
                                                    : CommaSeparator(
                                                        parseFloat(
                                                          parseFloat(
                                                            shippingVault?.total_debit
                                                          ) -
                                                            parseFloat(
                                                              shippingVault?.total_credit
                                                            )
                                                        ).toFixed(2)
                                                      )
                                                }
  <span>
  </div>
  <div class="last-row-title">Vehicle Wallet 
  <span class="row-last-box">
  $${
    vehicleVault?.nature === "credit"
      ? isNaN(
          parseFloat(vehicleVault?.total_credit) -
            parseFloat(vehicleVault?.total_debit)
        )
        ? parseFloat(0).toFixed(2)
        : CommaSeparator(
            parseFloat(
              parseFloat(vehicleVault?.total_credit) -
                parseFloat(vehicleVault?.total_debit)
            ).toFixed(2)
          )
      : isNaN(
          parseFloat(vehicleVault?.total_debit) -
            parseFloat(vehicleVault?.total_credit)
        )
      ? parseFloat(0).toFixed(2)
      : CommaSeparator(
          parseFloat(
            parseFloat(vehicleVault?.total_debit) -
              parseFloat(vehicleVault?.total_credit)
          ).toFixed(2)
        )
  }
  <span>
  </div>
  <div class="last-row-title">Total Due <span class="row-last-box">$${CommaSeparator(
    parseFloat(allSums).toFixed(2)
  )}<span></div>
                                            </div>
                                           
                                            `
                                               : ""
                                           }
                                           ${
                                             invoiceName ===
                                             "Vehicle Statement Of Account"
                                               ? `
                                             <div class="due-block">
                                             <div class="due-breakUp">Due Break Up:</div>
                                             <div class="due-breakup-table">
                                             <table>
        <tr >
            <td class="due-table-row">
                <span class="table-due due-table-text">Below 7 Days</span>
            </td>
            <td class="due-table-row">
                <span class="table-due due-table-text">8 - 14 Days</span>
            </td>
            <td class="due-table-row">
                <span class="table-due due-table-text">Over 15 Days</span>
            </td > 
            <td class="due-table-row" >
                <span class="table-due due-table-text">Total</span>
            </td >
        </tr>
        <tr >
          <td class="due-table-row">

            <span  class="statement-period"></span>USD $${CommaSeparator(
              currencyExchangeRate?.below7DaysTotal
            )}</span>

          </td>
          <td class="due-table-row">
          
            <span  class="statement-period"></span>USD $${CommaSeparator(
              currencyExchangeRate?.below8To14DaysTotal
            )}</span>

          </td>
          <td class="due-table-row">
         
            <span  class="statement-period"></span>USD $${CommaSeparator(
              currencyExchangeRate?.over15DaysTotal
            )}</span>

          </td>
          <td class="due-table-row">
            
            <span  class="statement-period"></span>USD $${CommaSeparator(
              currencyExchangeRate?.total
            )}</span>

          </td>
        </tr>
       
      </table>
</div>
                            </div>
                            
                          </div>
                        
                            `
                                               : invoiceName ===
                                                 "Shipping Statement Of Account"
                                               ? `
                                             <div class="due-block">
                                             <div class="due-breakUp">Due Break Up:</div>
                                             <div class="due-breakup-table">
                                             <table>
        <tr >
            <td class="due-table-row">
                <span class="table-due due-table-text">Below 7 Days</span>
            </td>
            <td class="due-table-row">
                <span class="table-due due-table-text">8 - 14 Days</span>
            </td>
            <td class="due-table-row">
                <span class="table-due due-table-text">Over 15 Days</span>
            </td > 
            <td class="due-table-row" >
                <span class="table-due due-table-text">Total</span>
            </td >
        </tr>
        <tr >
          <td class="due-table-row">

            <span  class="statement-period"></span>USD $${CommaSeparator(
              otherCharges?.below7DaysTotal
            )}</span>

          </td>
          <td class="due-table-row">
          
            <span  class="statement-period"></span>USD $${CommaSeparator(
              otherCharges?.below8To14DaysTotal
            )}</span>

          </td>
          <td class="due-table-row">
         
            <span  class="statement-period"></span>USD $${CommaSeparator(
              otherCharges?.over15DaysTotal
            )}</span>

          </td>
          <td class="due-table-row">
            
            <span  class="statement-period"></span>USD $${CommaSeparator(
              otherCharges?.total
            )}</span>

          </td>
        </tr>
       
      </table>
</div>
                            </div>
                        
                            `
                                               : ""
                                           }
                                  
                                       
                                    </div>
                                       
                                     
                                      
                                    
                                </div>
                                  ${
                                    currency?.length <= 30
                                      ? `
                                                <div class='footer-content'>
                                   <div class='system-generated'>
                                   <p class="system-text">
                                  1- This is a system-generated statement and does not require a signature. Any discrepancies in this SOA should be reported within 2 days. 
                                   </p>
                                   <p class="system-text">
                                   2- Relist payments should be made on the next day of the relist. Otherwise, your account will be Locked on the second day and a 15% penalty will be charged on relists.						
                                   </p>
                                   <p class="system-text">
                                   	3- Kindly settle the payment due at the earliest, we would like to remind you to be in touch with our customer service support team. 	

                                   </p>
                                   </div>
                                        <div class = "footer-div">
                                       <small class="footer-div-font">
                                           Customer care Contact: Mohammed husni - +971523195682 (Arabic & English ) Ardamehr Shoev - +971545836028 (English ,Arabic, Tajik & Farsi)Ravin abdul kareem - +971528293801 (Kurdish , Arabic & English) Magsat Gylyjov - +97158666403 (Turken , Russian & English)
                                       </small>
                                   </div> 
                                   </div> 
                                        `
                                      : `
                                               <div class='footer-content2'>
                                   <div class='system-generated'>
                                   <p class="system-text">
                                  1- This is a system-generated statement and does not require a signature. Any discrepancies in this SOA should be reported within 2 days. 
                                   </p>
                                   <p class="system-text">
                                   2- Relist payments should be made on the next day of the relist. Otherwise, your account will be Locked on the second day and a 15% penalty will be charged on relists.						
                                   </p>
                                   <p class="system-text">
                                   	3- Kindly settle the payment due at the earliest, we would like to remind you to be in touch with our customer service support team. 	

                                   </p>
                                   </div>
                                        <div class = "footer-div">
                                       <small class="footer-div-font">
                                           Customer care Contact: Mohammed husni - +971523195682 (Arabic & English ) Ardamehr Shoev - +971545836028 (English ,Arabic, Tajik & Farsi)Ravin abdul kareem - +971528293801 (Kurdish , Arabic & English) Magsat Gylyjov - +97158666403 (Turken , Russian & English)
                                       </small>
                                   </div> 
                                   </div> 
                                      `
                                  }
                            </div>
                    
                            <script src = "script.js"></script>
                        </body>
                    </html>
  `;
  return invoiceName === "Trial Balance" || invoiceName === "Balance Sheet"
    ? trialBalance
    : invoiceName === "Vehicle Statement Of Account" ||
      invoiceName === "VAT Due Statement" ||
      invoiceName === "Auction House" ||
      invoiceName === "Vendor Payable Due Report" ||
      invoiceName === "Shipping Wallet Statement" ||
      invoiceName === "Vehicle Wallet Statement" ||
      invoiceName === "Shipping RA Aging" ||
      invoiceName === "Shipping Statement Of Account"
    ? vhecicleSOA
    : "";
};

export const downloadPDF = async (htmlContent, fileName, data) => {
  try {
    const date = new Date();
    const pageSize = 30;
    const totalPages = Math.ceil(data.length / pageSize);

    for (let page = 0; page < totalPages; page++) {
      const pageData = data.slice(page * pageSize, (page + 1) * pageSize);
      const pageContent = generatePageContent(htmlContent, pageData);

      const options = {
        margin:
          data && data.length >= 32
            ? [3, 0, 8, 0]
            : (data.length >= 16 &&
                data.length <= 30 &&
                fileName === "Vehicle Soa") ||
              (data.length >= 16 &&
                data.length <= 30 &&
                fileName === "Shipping Soa")
            ? [2, 0, 12, 0]
            : fileName === "trial_balance" || fileName === "balance_sheet"
            ? [6, 0, 5.5, 0]
            : (fileName === "trial_balance" && data.length >= 32) ||
              (fileName === "balance_sheet" && data.length >= 32)
            ? [4, 0, 7, 0]
            : data.length <= 30 && fileName === "Shipping Soa"
            ? [4, 0, 10, 0]
            : [0, 0, 0, 0],

        filename: `${fileName}_part${page + 1}_${Math.floor(
          date.getTime() + date.getSeconds() / 2
        )}.pdf`,
        image: { type: "jpeg", quality: 1 },
        pagebreak: {
          mode: ["css", "legacy"],
        },
        html2canvas: {
          scale: 2,
          useCORS: true,
        },

        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation:
            fileName === "Shipping Soa" ||
            fileName === "Vehicle Soa" ||
            fileName === "trial_balance" ||
            fileName === "balance_sheet"
              ? "landscape"
              : "portrait",
          compress: true, // Enable compression
        },
      };

      await html2pdf().set(options).from(pageContent).save();
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

// Function to generate HTML content for each page
const generatePageContent = (htmlContent, pageData) => {
  let tableRows = "";
  let Total = 0;
  let Total2 = 0;
  let GrandTotal = 0;
  let TotalEquity = 0;
  let GrandTotal2 = 0;
  pageData.forEach((values, index) => {
    tableRows += `
    <tr class="table-row" key=${index}>
      <td colspan="2">${values?.name}</td>
    </tr>
    ${
      values?.sub
        ? values?.sub
            ?.map(
              (val) => `
              <tr>
                <td colspan="3">${val?.name}</td>
              </tr>
              ${
                val?.accounts
                  ? val?.accounts
                      .map((data, index2) => {
                        let childTotal = 0;
                        let childTotal2 = 0;

                        if (data?.childAccounts?.length > 0) {
                          // Calculate child totals if child accounts exist
                          const initialValue = { credit: 0, debit: 0 };
                          const result = data?.childAccounts?.reduce(
                            (accumulator, transaction) => {
                              const credit = isNaN(transaction?.total_credit)
                                ? 0
                                : parseFloat(transaction?.total_credit);
                              const debit = isNaN(transaction?.total_debit)
                                ? 0
                                : parseFloat(transaction?.total_debit);
                              return {
                                credit: accumulator.credit + credit,
                                debit: accumulator.debit + debit,
                              };
                            },
                            initialValue
                          );
                          childTotal =
                            data?.nature === "debit"
                              ? parseFloat(result?.debit) -
                                parseFloat(result?.credit)
                              : parseFloat(result?.credit) -
                                parseFloat(result?.debit);
                          childTotal2 =
                            data?.nature === "debit" || data?.nature === null
                              ? parseFloat(result?.debit) -
                                parseFloat(result?.credit)
                              : parseFloat(result?.credit) -
                                parseFloat(result?.debit);
                        } else {
                          // Calculate totals directly from account data
                          childTotal =
                            data?.nature === "debit"
                              ? parseFloat(data?.total_debit) -
                                parseFloat(data?.total_credit)
                              : parseFloat(data?.total_credit) -
                                parseFloat(data?.total_debit);

                          childTotal2 =
                            data?.nature === "debit" || data?.nature === null
                              ? parseFloat(data?.total_debit) -
                                parseFloat(data?.total_credit)
                              : parseFloat(data?.total_credit) -
                                parseFloat(data?.total_debit);
                        }
                        if (["credit", "combine"].includes(data?.nature)) {
                          Total += parseFloat(childTotal);
                          GrandTotal += parseFloat(childTotal);
                          if (index !== 0) {
                            TotalEquity += parseFloat(childTotal);
                          }
                        }

                        if (["debit", "combine", null].includes(data?.nature)) {
                          Total2 += parseFloat(childTotal2);

                          GrandTotal2 += parseFloat(childTotal2);
                        }
                        return `
                          <tr class="${"table-row"}">
                            <td colspan="2">${data?.account_code}</td>
                            <td colspan="6">${data?.account_name}</td>
                            <td colspan="1">${data?.account_category}</td>
                            <td colspan="6">${data?.account_subcategory}</td>
                            <td colspan="1">$${
                              data.nature === "debit" ||
                              data?.nature === null ||
                              data?.nature === "combine"
                                ? CommaSeparator(
                                    parseFloat(childTotal2).toFixed(2)
                                  )
                                : parseFloat(0).toFixed(2)
                            }
                            </td>
                            <td colspan="1">$${
                              data.nature === "credit" ||
                              data?.nature === "combine"
                                ? CommaSeparator(
                                    parseFloat(childTotal).toFixed(2)
                                  )
                                : parseFloat(0).toFixed(2)
                            }
                            </td>
                          </tr>
                          ${
                            data?.childAccounts
                              ? data.childAccounts
                                  .map((childData, childIndex) => {
                                    const credit = isNaN(
                                      childData?.total_credit
                                    )
                                      ? 0
                                      : childData?.total_credit;
                                    const debit = isNaN(childData?.total_debit)
                                      ? 0
                                      : childData?.total_debit;
                                    let subTotal =
                                      childData?.nature === "debit"
                                        ? (
                                            parseFloat(debit) -
                                            parseFloat(credit)
                                          ).toFixed(2)
                                        : (
                                            parseFloat(credit) -
                                            parseFloat(debit)
                                          ).toFixed(2);
                                    return `
                            <tr  class="${"table-green-row table-row"}>
                              <td colspan="2"></td>
                              <td colspan="2">${childData?.account_code}</td>
                              <td colspan="6">${childData?.account_name}</td>
                              <td colspan="3">${
                                childData?.account_category
                              }</td>
                              <td colspan="3">${
                                childData?.account_subcategory
                              }</td>
                               <td colspan="2">$${CommaSeparator(subTotal)}</td>
                            
                               
                            </tr>
                          `;
                                  })
                                  .join("")
                              : ""
                          }
                               <tr class="table-row">
              <td colspan="2">Total Of ${val?.accounts?.[0]?.type_code}</td>
                 <td colspan="6"></td>
                <td colspan="2" class="total-name">Total ${val?.name}</td>
                 <td colspan="6"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(Total2).toFixed(2)
                )}</td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(Total).toFixed(2)
                )}</td>

            </tr> 

      
                        `;
                      })
                      .join("")
                  : ""
              }
                         <tr class="table-row-blue">
              <td colspan="2">Total</td>
                 <td colspan="6"></td>
                <td colspan="2" class="total-name">Total ${values?.name}</td>
                 <td colspan="6"></td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(GrandTotal2).toFixed(2)
                )}</td>
                <td colspan="1">$${CommaSeparator(
                  parseFloat(GrandTotal).toFixed(2)
                )}</td>

            </tr> 
            `
            )
            .join("")
        : ""
    }
  `;
  });

  return htmlContent.replace("<!--PLACEHOLDER_FOR_DATA-->", tableRows);
};
