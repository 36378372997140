import React, { useRef } from 'react';
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  IconButton,
  CircularProgress,
  Paper,
  Chip,
  Tooltip,
  Modal,
  Button,
  Dialog,
} from "@mui/material";
import RowRadioButtonsGroup from "components/Input/RadioGroup";
import Pagination from "components/Pagination";
import { Check, Close, Delete, Edit } from "@mui/icons-material";
import Colors from "assets/Style/Colors";
import DatePicker from "components/DatePicker";
import SelectField from "components/Select";
import { makeStyles } from "@mui/styles";
import { FontFamily } from "assets";
import { PrimaryButton } from "components/Buttons";
import { useState, Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { CircleLoading } from "components/Loaders";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import ExportServices from "services/Export";
import { Icons } from "assets/index";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import { EyeIcon } from "assets";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CommaSeparator, handleExportWithComponent } from "utils";
import { PDFExport } from "@progress/kendo-react-pdf";
import WhatsAppModal from "../VehicleBooking/WhatsApp.modal";
import EmailModal from "../VehicleBooking/Email.modal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import InputField from "components/Input";
import { FiDownload } from "react-icons/fi";
import DeleteIcon from '@mui/icons-material/Delete';
import ExportDropDownMenu from 'components/DropDown/ExportDropDownMenu';

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    padding: '15px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    background: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: 'center',
    textWrap: 'nowrap',

    padding: '5px !important',
    '.MuiBox-root': {
      display: 'flex',
      gap: '6px',
      alignItems: 'center',
      justifyContent: 'center',
      '.MuiBox-root': {
        cursor: 'pointer',
      },
    },
    svg: {
      width: 'auto',
      height: '24px',
    },
    '.MuiTypography-root': {
      textTransform: 'capitalize',
      fontFamily: FontFamily.NunitoRegular,
      textWrap: 'nowrap',
    },
    '.MuiButtonBase-root': {
      padding: '8px',
      width: '28px',
      height: '28px',
    },
  },
}));

const useStyles = makeStyles({
  loaderWrap: {
    display: 'flex',
    height: 100,
    '& svg': {
      width: '40px !important',
      height: '40px !important',
    },
  },
});

const DeleteConfirmationModal = ({ open, setOpen, deleteInvoice }) => {
  const handleDelete = () => {
    setOpen(false);
    deleteInvoice();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='delete-confirmation-title'
        aria-describedby='delete-confirmation-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id='delete-confirmation-title'
            variant='h6'
            component='h2'
          >
            Confirm Reversing Invoice
          </Typography>
          <Typography id='delete-confirmation-description' sx={{ mt: 2 }}>
            Are you sure you want to reverse this invoice?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setOpen(false)}
            >
              No
            </Button>
            <Button variant='contained' color='error' onClick={handleDelete}>
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

function ExportVehiclePayments() {
  const [selectedManifest, setselectedManifest] = useState(null);
  const [manifestOptions, setmanifestOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(null);
  const [paymentsData, setPaymentsData] = useState([])
  const contentRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const tableHead = [
    "Ref No",
    "P.Date",
    // "#. of Inv’s Rcvd",
    "Invoice.No",
    "Customer Name",
    "Customer ID",
    "VIN",
    "Applied Status",
    "$ Total",
    "Received By",
    "Payment Method",
    "Attachments",
    "Action"
  ];

  const [loader, setLoader] = useState(false);

  // *For Dialog Box
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  // *For Filters
  const [filters, setFilters] = useState({});
  const [selectedValue, setSelectedValue] = useState('option1'); // State to manage the selected radio button value

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };
  // *For Auction House
  const [auctionHouses, setAuctionHouses] = useState();
  const [selectedAuctionHouses, setSelectedAuctionHouses] = useState('');

  const [openWhatsAppModal, setOpenWhatsAppModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteInvoiceId, setSelectedDeleteInvoiceId] = useState(0);

  // *For Permissions
  const [permissions, setPermissions] = useState();

  const [ExportDatas, setExportDatas] = useState();
  const [searchTerm, setSearchTerm] = useState();

  // *For Export Vechicles
  const getExportVehicles = async (page, limit, filter) => {
    try {
      const Page = page || currentPage;
      const Limit = limit || pageLimit;
      const Filter = { ...filters, ...filter };

      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);

      let params = {
        page: Page,
        limit: Limit,
        ...Filter,
      };

      const { data } = await ExportServices.getVehiclePayments(params);

      let formattedData = [];

      if (data?.funds?.rows) {
        data?.funds?.rows.forEach((vehicle) => {
          if (vehicle?.payments) {
            vehicle?.payments.forEach((payment) => {
              formattedData.push({
                payment_id: payment?.id,
                ev_id: vehicle?.ev_id,
                total_amount: vehicle?.total_amount,
                payment_medium: payment?.details?.[0]?.payment_medium?.toUpperCase() ?? "-",
                created_by: payment?.creator?.name ?? "-",
                vin: vehicle?.vehicle?.vin ?? "-",
                lot_number: vehicle?.vehicle?.lot_number ?? "-",
                customer_name: vehicle?.vehicle?.customer?.name ?? "-",
                customer_ref_id: vehicle?.vehicle?.customer?.ref_id ?? "-",
                vendor_paid: parseFloat(vehicle?.vehicle?.vendor_paid) || 0,
                final_price: parseFloat(vehicle?.vehicle?.final_price) || 0,
                payment_date: payment?.payment_date || vehicle?.created_at,
                vehicle_status: vehicle?.vehicle?.vehStatus ?? "-",
                title_status: vehicle?.vehicle?.title_status ?? "-",
                attachment: vehicle?.vehicle?.attachment || null,
                total_invoices: vehicle?.payments,
              });
            });
          }
        });
      }

      setExportDatas(formattedData);
      setTotalCount(data?.funds?.count);
    } catch (error) {
      ErrorToaster(error);
    }
  };


  const getFilteredData = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 15,
        filter: search,
      };
      const { data } = await ExportServices.getExportVehicles(params);
      setExportDatas(data?.vehicles?.rows);
      setTotalCount(data?.vehicles?.count);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const getManifest = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 15,
        search: search,
      };
      const { data } = await ExportServices.getManifest(params);

      //Custom Filter

      if (!search) {
        const resultArray = data?.filter?.vins.map((item) => {
          return { id: item, name: item };
        });
        setmanifestOptions(resultArray);
      } else {
        const filteredResults = data?.filter?.vins.filter((item) =>
          item.includes(search)
        );

        const resultArray = filteredResults.map((item) => {
          return { id: item, name: item };
        });

        setmanifestOptions(resultArray);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  // *For Handle Filter
  const handleFilter = (data) => {
    getExportVehicles(1, '', data);
  };

  const UpdateDamage = async (formData) => {
    setLoading(true);
    try {
      let obj = {};
      const { message } = await ExportServices.UpdateManifest(obj);
      SuccessToaster(message);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = () => {
    const headers = tableHead.filter((item) => item !== 'Action');
    const rows = ExportDatas?.map((item) => {
      const vins = (item?.details || [])
        .map((detail) => detail?.vehicle?.vin)
        .join(',');
      return [
        item?.id ?? '-',
        item?.created_at
          ? moment(item?.created_at).format('DD-MM-YYYY')
          : 'N/A',
        item?.details ? item?.details?.length : 0 ?? '-',
        vins ?? '-',
        item?.shipping_due
          ? parseFloat(item?.shipping_due).toFixed(2)
          : parseFloat(0).toFixed(2) ?? '-',
        item?.creator?.name ?? '-',
      ];
    });

    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const buf = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(new Blob([buf]), 'data.xlsx');
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.trim().toLowerCase();
    if (value !== "") {
      setSearchTerm(value);

      // Filter the original data based on the search term
      const filteredData = ExportDatas?.filter((item) => {
        const id = item?.details ? String(`GEVOTW-${item?.details[0]?.vehicle?.id}`).toLowerCase() : "";
        const vin = item?.details[0]?.vehicle?.vin ? item.details[0]?.vehicle?.vin?.toLowerCase() : "";
        const payDate = item?.payment_date
          ? moment(item?.payment_date).format('DD-MM-YYYY')
          : "";
        const customer = item?.details[0]?.vehicle?.customer ? item?.details[0]?.vehicle?.customer?.name.toLowerCase() : "";
        const customer_id = item?.details[0] ? String(`C-${item?.details[0]?.vehicle?.customer?.id}`).toLowerCase() : "";
        // const usd_total = item?.usd_total ? item?.usd_total.toLowerCase() : "";
        // const aed_total = item?.aed_total ? item?.aed_total.toLowerCase() : "";
        return id.includes(value) || vin.includes(value) || payDate.includes(value) || customer.includes(value) || customer_id.includes(value)
        // || creator.includes(value) || usd_total.includes(value) || aed_total.includes(value) || exchange_rate.includes(value);
      });

      setExportDatas(filteredData);
    } else {
      getExportVehicles()
    }
  };



  const handleCloseWhatsAppModal = () => {
    setOpenWhatsAppModal(false);
    setSelectedId(null);
  };
  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
    setSelectedId(null);
  };

  const deleteInvoice = async () => {
    try {
      await ExportServices.reverseVehicleInvoice({
        invoice_id: selectedDeleteInvoiceId.invoice_id,
        ev_id: selectedDeleteInvoiceId.ev_id

      });
      handleClose(false);
      await getExportVehicles();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPermissions(true);
    getManifest();
    getExportVehicles();
  }, []);
  const handleToggleMenu = (menuId, isOpen) => {
    if (isOpen && menuActive !== menuId) {
      setMenuActive(menuId);
    } else if (!isOpen) {
      setMenuActive(null);
    }
  };
  const handleClose = () => {
    setShowDeleteModal(false);
  };
  return (
    <>
      {/* {showDeleteModal ? (
        <DeleteConfirmationModal
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          deleteInvoice={deleteInvoice}
        />
      ) : null} */}
      <Dialog
        open={showDeleteModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography sx={{ p: 3, px: 5, fontSize: 17, fontFamily: FontFamily.openSans, fontWeight: "bold" }} id="alert-dialog-title">{"Are You Sure You Want To Delete Paid Invoice?"}</Typography>

        <Grid item xs={12} sm={12} sx={{ mb: 2, textAlign: "center" }}>
          <PrimaryButton
            title="No"
            type="submit"
            buttonStyle={{ minWidth: "120px" }}
            onClick={() => { handleClose() }}

          />
          <Box component={"span"} sx={{ mx: 1 }} />
          <PrimaryButton
            title="Yes"
            type="button"
            buttonStyle={{
              minWidth: "120px",
              bgcolor: Colors.danger,
              "&:hover": { bgcolor: Colors.danger },
            }}
            bgcolor={Colors?.danger}
            onClick={() => { deleteInvoice() }}
          />
        </Grid>
      </Dialog>
      <Box component={'form'} onSubmit={handleSubmit(UpdateDamage)}>
        <Box sx={{ width: '100%' }}>
          <Grid container>
            <Grid item md={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: 4,
                  ml: 4,
                  mr: 8,
                }}
              >
                <Typography
                  variant='h5'
                  sx={{
                    color: Colors.charcoalGrey,
                    fontFamily: FontFamily.NunitoRegular,
                    textAlign: 'left',
                  }}
                >
                  Export Shipping Invoices
                </Typography>
                {ExportDatas?.length > 0 && (
                  <Box
                    sx={{
                      textAlign: 'right',
                      p: 4,
                      display: 'flex',
                      gap: 2,
                    }}
                  >
                    <PrimaryButton
                      title='Download PDF'
                      type='button'
                      style={{ backgroundColor: Colors.bluishCyan }}
                      onClick={() => handleExportWithComponent(contentRef)}
                    />
                    <PrimaryButton
                      title={'Download Excel'}
                      onClick={() => downloadExcel()}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={3} style={{ marginLeft: "10px" }}>
              <InputField
                size={"small"}
                label={"Search"}
                placeholder={"Search"}
                register={register("search", {
                  onChange: (e) => handleFilter({ search: e.target.value })
                })}
              />
            </Grid>
          </Grid>

        </Box>

        <Grid
          container
          xs={10}
          sx={{
            borderRadius: '5px',
            m: 4,
            mt: 0,
          }}
        >
          {/* <Grid sm={12} md={3} item>
					{" "}
					<SelectField
						size={'small'}
						searchable={true}
						label={"VIN"}
						options={manifestOptions}
						selected={selectedManifest}
						onSelect={(value) => {
							setselectedManifest(value);
							console.log(value);
							handleFilter({ filter: value?.id });
						}}
						onSearch={(v) => getManifest(v)}
						// error={errors?.auctionHouses?.message}
						error={errors?.VIN?.message}
						register={register("VIN", {
							required: "Please select VIN.",
						})}
					/>
				</Grid> */}
        </Grid>

        <Box sx={{ m: 4, mb: 2 }}>
          {/* ========== Confirmation Dialog ========== */}
          <ConfirmationDialog
            open={confirmationDialog}
            onClose={() => setConfirmationDialog(false)}
            message={'Are you sure you want to delete this?'}
          // action={() => deleteBuyerId()}
          />

          {ExportDatas ? (
            <Fragment>
              <PDFExport
                ref={contentRef}
                landscape={true}
                paperSize='A4'
                margin={5}
                fileName='Export Vehicle Payments'
              >
                <Box className='pdf-show' sx={{ display: 'none' }}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      variant='h5'
                      sx={{
                        color: Colors.charcoalGrey,
                        fontFamily: FontFamily.NunitoRegular,
                        mb: 2,
                      }}
                    >
                      Export Vehicle Payments
                    </Typography>
                    <Box
                      sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        mt: 1.5,
                        color: Colors.charcoalGrey,
                      }}
                    >
                      <span>Date: &nbsp;&nbsp;</span>
                      {moment().format('MM-DD-YYYY')}
                    </Box>
                  </Box>
                </Box>
                {/* ========== Table ========== */}
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: '0px 8px 18px 0px #9B9B9B1A',
                    borderRadius: 2,
                    // maxHeight: 'calc(100vh - 330px)',
                    height: ExportDatas?.length < 2 ? "calc(100vh - 630px)" : "calc(100vh - 330px)",
                  }}
                  className='table-box'
                >
                  <Table stickyHeader sx={{ minWidth: 500 }}>
                    <TableHead>
                      <TableRow>
                        {tableHead.map((item, index) => (
                          <Cell className='pdf-table' key={index}>
                            {item}
                          </Cell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!loader ? (
                        ExportDatas.length > 0 ? (
                          <Fragment>
                            {ExportDatas.map((item, index) => {
                              const vendorPaid = parseFloat(item.vendor_paid);
                              const finalPrice = parseFloat(item.final_price);
                              return (
                                <Row key={index} sx={{ bgcolor: index % 2 !== 0 && '#EFF8E7' }}>
                                  <Cell className='pdf-table'>{item?.payment_id ?? '-'}</Cell>
                                  <Cell className='pdf-table'>
                                    {moment(item.payment_date).format('DD-MM-YYYY')}
                                  </Cell>
                                  {/* <Cell className='pdf-table'>{item?.total_invoices
                                      ? item?.total_invoices?.length
                                      : 0 ?? '-'}</Cell> */}
                                  <Cell className="pdf-table">GEVOTW-{item ? item?.payment_id : "-"}</Cell>
                                  <Cell className='pdf-table'>{item?.customer_name ?? '-'}</Cell>
                                  <Cell className='pdf-table'>{item?.customer_ref_id ?? '-'}</Cell>
                                  <Cell className='pdf-table'>{item?.vin ?? '-'}</Cell>
                                  {/* <Cell className='pdf-table'>{item?.lot_number ?? '-'}</Cell> */}

                                  {/* Payment Status */}
                                  <Cell className="pdf-table">
                                    {item.vendor_paid === 0 ? (
                                      <Box>
                                        <Box
                                          sx={{
                                            width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: `1px solid ${Colors.danger}`,
                                            borderRadius: "50%",
                                          }}
                                        >
                                          <Close sx={{ fontSize: "18px", color: Colors.danger }} />
                                        </Box>
                                        Unpaid
                                      </Box>
                                    ) : vendorPaid > 0 && vendorPaid < finalPrice ? (
                                      <Box>
                                        <Box
                                          sx={{
                                            width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: `1px solid ${Colors.danger}`,
                                            borderRadius: "50%",
                                          }}
                                        >
                                          <Close sx={{ fontSize: "18px", color: Colors.danger }} />
                                        </Box>
                                        Unpaid
                                      </Box>
                                    ) : (
                                      <Box>
                                        <Box
                                          sx={{
                                            width: "25px",
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: `1px solid ${Colors.primary}`,
                                            borderRadius: "50%",
                                          }}
                                        >
                                          <Check sx={{ fontSize: "18px", color: Colors.primary }} />
                                        </Box>
                                        Paid
                                      </Box>
                                    )}
                                  </Cell>

                                  <Cell className="pdf-table">
                                    {CommaSeparator(parseFloat(item?.total_amount).toFixed(2))}
                                  </Cell>
                                  <Cell className="pdf-table">{item?.created_by ?? "-"}</Cell>
                                  <Cell className="pdf-table">{item?.payment_medium ?? "-"}</Cell>

                                  {/* Attachment */}
                                  <Cell className="pdf-table">
                                    {item?.attachment ? (
                                      <Link
                                        className="icon"
                                        target="_blank"
                                        to={process.env.REACT_APP_IMAGE_BASE_URL + item.attachment}
                                        style={{ textAlign: "center" }}
                                      >
                                        <FiDownload color={Colors.primary} />
                                      </Link>
                                    ) : (
                                      <Typography>No Attachment</Typography>
                                    )}
                                  </Cell>

                                  <Cell>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                      }}
                                    >
                                      <ExportDropDownMenu
                                        item={item}
                                        permissions={permissions}
                                        navigate={navigate}
                                        Colors={Colors}
                                        isOpen={menuActive === item.payment_id}
                                        onToggle={(isOpen) => handleToggleMenu(item.payment_id, isOpen)}
                                        printInvoiceClick={() => {
                                          window.open(`/export-invoice/${item.ev_id}`, '_blank');
                                        }}
                                        handleViewDetail={() => {
                                          window.open(`/export-vehicle-details/${item.ev_id}`, '_blank');
                                        }}
                                        handleUpdate={() => {
                                          if (item?.vehicle_status !== 'Handover') {
                                            navigate(`/update-export-vehicle/${item.payment_id}`);
                                          }
                                        }}
                                        id={item.payment_id}
                                        isForward={true}
                                        emailClick={() => {
                                          setOpenEmailModal(true);
                                          setSelectedId({ path: 'export-vehicle-preview', id: item.ev_id });
                                        }}
                                        whatsAppClick={() => {
                                          setOpenWhatsAppModal(true);
                                          setSelectedId({ path: 'export-vehicle-preview', id: item.ev_id });
                                        }}
                                        handleDelete={() => {
                                          setShowDeleteModal(true);
                                          setSelectedDeleteInvoiceId({
                                            invoice_id: item.payment_id,
                                            ev_id: item.ev_id,
                                          });
                                        }}
                                        isDelete={true}
                                      />
                                    </Box>
                                  </Cell>
                                </Row>
                              );
                            })}
                          </Fragment>
                        ) : (
                          <Row>
                            <Cell colSpan={tableHead?.length + 1} align="center" sx={{ fontWeight: 600 }}>
                              No Data Found
                            </Cell>
                          </Row>
                        )
                      ) : (
                        <Row>
                          <Cell colSpan={tableHead?.length + 2} align="center" sx={{ fontWeight: 600 }}>
                            <Box className={classes.loaderWrap}>
                              <CircularProgress />
                            </Box>
                          </Cell>
                        </Row>
                      )}

                      {/* Render ForwardToWhatsAppModal */}
                      <WhatsAppModal
                        open={openWhatsAppModal}
                        onClose={handleCloseWhatsAppModal}
                        id={selectedId}
                      />
                      {/* Render ForwardToEmail */}
                      <EmailModal
                        open={openEmailModal}
                        onClose={handleCloseEmailModal}
                        id={selectedId}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </PDFExport>
              {/* ========== Pagination ========== */}
              <Pagination
                currentPage={currentPage}
                pageSize={pageLimit}
                onPageSizeChange={(size) => getExportVehicles(1, size.target.value)}
                tableCount={ExportDatas?.length}
                totalCount={totalCount}
                onPageChange={(page) => getExportVehicles(page, "")}
              />
            </Fragment>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </>
  );
}
export default ExportVehiclePayments;
