import React, { Fragment, useEffect, useRef, useState } from "react";
import {
	Box,
	CircularProgress,
	Grid,
	CardMedia,
	Typography,
	Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Colors from "assets/Style/Colors";
import { ErrorToaster } from "components/Toaster";
import { PrimaryButton } from "components/Buttons";
import { CommaSeparator, GeneratePDF, handleExportWithComponent } from "utils";

import {
	FontFamily,
	Images,
	InvoiceGlobal,
	InvoiceLocation,
	InvoiceMail,
	InvoicePhone,
} from "assets";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CurrencyServices from "services/Currency";
import { QRCodeCanvas } from "qrcode.react";
import ExportServices from "services/Export";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Print } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";

const useStyle = makeStyles({
	headingBg: {
		margin: "32px 0px",
		padding: "12px 0px",
		textAlign: "center",
	},
	heading: {
		color: Colors.white,
		textTransform: "uppercase",
		fontWeight: 300,
		fontFamily: FontFamily.NunitoRegular,
	},
	text: {
		color: Colors.smokeyGrey,
		fontWeight: 300,
		fontFamily: FontFamily.NunitoRegular,
	},
	tableCell: {
		backgroundColor: Colors.aliceBlue,
		border: "0.25px solid #D9D9D9",
		"& .MuiTypography-root": {
			padding: "4px 12px",
		},
	},
});

function ExportVehiclePreview() {

	const { id } = useParams();
	const contentRef = useRef(null);
	const classes = useStyle();

	const [loader, setLoader] = useState(true);
	const [isContentReady, setIsContentReady] = useState(false);

	// *For Invoice Detail
	const [invoiceDetail, setInvoiceDetail] = useState();
	const [paymentDetail, setPaymentDetail] = useState();

	// *For Currencies
	const [currencyExchangeRate, setCurrencyExchangeRate] = useState();
	// *For Get Invoice Detail
	const getExportInvoice = async () => {
		setLoader(true);
		try {
			let params = {
				ev_id: atob(id).split('-')[1],
			};
			const { data } = await ExportServices.getExportVehiclePreview(params);
			setInvoiceDetail(data?.vehicle);
			setPaymentDetail(data);
			getCurrencies(data?.currency);
		} catch (error) {
			ErrorToaster(error);
		} finally {
			setLoader(false);
		}
	};

	// *For Get Currencies
	const getCurrencies = async (currency) => {
		try {
			let params = {
				detailed: true,
			};
			const { data } = await CurrencyServices.getCurrenciesPreview(params);

			const rate = data?.currencies.find((e) => e.currency === currency)?.conversion_rate;

			setCurrencyExchangeRate(data.currencies[2].conversion_rate);
		} catch (error) {
			ErrorToaster(error);
		}
	};

	useEffect(() => {
		if (id) {
			getExportInvoice();
		}
	}, [id]);
	const handlePrint = useReactToPrint({
		content: () => {
		  if (!contentRef.current) {
			console.error("contentRef.current is null when attempting to print");
			return null;
		  }
		  return contentRef.current;
		},
		documentTitle: "Invoice",
		onBeforePrint: () => {
		  try {
			const content = contentRef.current;
			if (content && isContentReady) {
			  console.log("Applying transform styles before print");
			  content.style.transform = "scale(0.72)";
			  content.style.transformOrigin = "top center";
			} else {
			  console.warn("contentRef.current is not available or not ready in onBeforePrint");
			}
		  } catch (error) {
			console.error("Error in onBeforePrint:", error);
		  }
		},
		onAfterPrint: () => {
		  try {
			const content = contentRef.current;
			if (content) {
			  console.log("Resetting transform styles after print");
			  content.style.transform = "none";
			} else {
			  console.warn("contentRef.current is null or undefined in onAfterPrint");
			}
		  } catch (error) {
			console.error("Error in onAfterPrint:", error);
		  }
		},
	  });
	return (
		<Container>
			{!loader && (
				<Box sx={{ textAlign: "right", p: 4, alignItems: "center", justifyContent: "flex-end", display: "flex" }}>
					<PrimaryButton
						title="Download Invoice"
						type="button"
						style={{ backgroundColor: Colors.bluishCyan }}
						onClick={() => handleExportWithComponent(contentRef)}
					/>
					<Box
						component="div"
						sx={{
							display: "inline-block",
							marginLeft: 2,
							cursor: "pointer",
						}}
						onClick={() => handlePrint()}
					>
						<Print sx={{ height: 30, width: 30, cursor: "pointer", color: Colors.primary }} />
					</Box>
				</Box>
			)}
			<PDFExport ref={contentRef} fileName="Export Invoice">
				<Box
					sx={{
						width: "1000px",
						mx: 4,
						my: 2,
						bgcolor: Colors.white,
						boxShadow: "0px 8px 18px 0px #9B9B9B1A",
					}}
				>
					{!loader ? (
						<Box >
							<Grid container spacing={0}>
								<Grid item md={3.5}>
									<Box
										component={"img"}
										src={Images.logo}
										sx={{
											height: { xs: "80px", md: "180px" },
										  }}
									/>
								</Grid>
								<Grid item md={8.5}>
									<CardMedia image={Images.invoiceHeader} sx={{ mb: 2 }}>
										<Typography
											variant="h3"
											sx={{ py: 3, textAlign: "center", color: Colors.white }}
										>
											Galaxy World Wide Shipping
										</Typography>
									</CardMedia>
									<Grid
										container
										spacing={1.5}
										alignItems={"center"}
										justifyContent={"space-evenly"}
									>
										<Grid item md={4}>
											<Box
												sx={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<InvoicePhone />
												<Typography
													variant="body1"
													sx={{
														color: Colors.smokeyGrey,
														fontFamily: FontFamily.NunitoRegular,
													}}
												>
													+971 6 510 2000
												</Typography>
											</Box>
										</Grid>
										<Grid item md={6}>
											<Box
												sx={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<InvoiceMail />
												<Typography
													variant="body1"
													sx={{
														color: Colors.smokeyGrey,
														fontFamily: FontFamily.NunitoRegular,
													}}
												>
													info@galaxyshipping.com
												</Typography>
											</Box>
										</Grid>
										<Grid item md={4}>
											<Box
												sx={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<InvoiceGlobal />
												<Typography
													variant="body1"
													sx={{
														color: Colors.smokeyGrey,
														fontFamily: FontFamily.NunitoRegular,
													}}
												>
													https://galaxyshipping.com
												</Typography>
											</Box>
										</Grid>
										<Grid item md={6}>
											<Box
												sx={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<InvoiceLocation />
												<Typography
													variant="body1"
													sx={{
														color: Colors.smokeyGrey,
														fontFamily: FontFamily.NunitoRegular,
													}}
												>
													Ind Area#4 P.O Box 83126, Sharjah , UAE
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Box
								sx={{
									my: 5,
									position: "relative",
									bgcolor: Colors.bluishCyan,
									width: 1,
									height: "12px",
								}}
							>
								<Typography
									component={"span"}
									variant="h2"
									sx={{
										color: Colors.charcoalGrey,
										bgcolor: Colors.white,
										p: 2,
										letterSpacing: "3px",
										position: "absolute",
										right: "90px",
										top: "-40px",
									}}
								>
									EXPORT INVOICE
								</Typography>
							</Box>
							<Grid container spacing={0} justifyContent={"space-between"}>
								<Grid item md={5.5} sm={5.5} xs={5.5}>
									<Box sx={{ ml: 4, }}>
										<Grid container spacing={0.5}>
											<Grid item md={12} sm={12} xs={12}>
												<Typography
													variant="h4"
													className={classes.text}
													sx={{ mb: 1 }}
												>
													Invoice To:
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Customer ID:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography
													noWrap
													variant="body1"
													className={classes.text}
												>
													{invoiceDetail?.customer_id ?? "-"}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Customer Name:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.customer?.name ?? "-"}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Phone:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.customer?.uae_phone ?? "-"}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Email:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.customer?.email ?? "-"}
												</Typography>
											</Grid>
										</Grid>
									</Box>
									<Box className={classes.headingBg} sx={{ bgcolor: Colors.primary }}>
										<Typography variant="h4" className={classes.heading}>
											VEHICLE INFORMATION
										</Typography>
									</Box>
									<Box sx={{ ml: 4 }}>
										<Grid container spacing={1.34}>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													VIN #
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.vin ?? "-"}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Make:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.make?.name ?? "-"}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Model:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.model?.name ?? "-"}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Year:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.year ?? "-"}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Color:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.color ?? "-"}
												</Typography>
											</Grid>

											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Status
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.status?.name ?? "-"}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Booking Date:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{moment(invoiceDetail?.date).format("DD-MMM-YYYY")}
												</Typography>
											</Grid>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Handover Date:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.handover_date ? moment(invoiceDetail.handover_date).format("DD-MMM-YYYY") : "N/A"}
												</Typography>
											</Grid>
										</Grid>
									</Box>

									<Box
										className={classes.headingBg}
										sx={{ bgcolor: Colors.bluishCyan }}
									>
										<Typography variant="h4" className={classes.heading}>
											NOTES
										</Typography>
									</Box>
									<Box sx={{ ml: 4, mr: 2 }}>
										<Typography variant="body1" className={classes.text}>
											{invoiceDetail?.notes ?? "-"}
										</Typography>
									</Box>
								</Grid>
								<Grid item md={5.5} sm={5.5} xs={5.5}>
									<Box sx={{ mr: 3, mt: 0 }}>
										<Grid container spacing={0} justifyContent={"flex-end"}>
											<Grid item md={6} sm={6} xs={6} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													Invoice #:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													{parseFloat(invoiceDetail?.vendor_paid)?.toFixed(2) > 0 ? `GSI-${invoiceDetail?.id}` : `GEVOTW-${invoiceDetail?.id}`}
												</Typography>
											</Grid>

											<Grid item md={6} sm={6} xs={6} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													Invoice on:
												</Typography>
											</Grid>
											<Grid iitem md={6} sm={6} xs={6} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													{moment(invoiceDetail?.date).format(
														"DD-MMM-YYYY"
													)}
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6} className={classes.tableCell}>
												<Typography variant="body1"
													fontFamily={FontFamily.openSans} style={{  }} className={classes.text}>
													Received By:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6} className={classes.tableCell}>
												<Typography style={{  }} variant="body1"
													fontFamily={FontFamily.openSans} className={classes.text}>
													{invoiceDetail?.paymentDetails?.payment?.creator?.name ?? "-"}
												</Typography>
											</Grid>
											{/* <Grid item md={6} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													Creation on:
												</Typography>
											</Grid>
											<Grid item md={6} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													{moment(
														invoiceDetail?.invoice?.booking?.created_at
													).format("DD-MMM-YYYY hh:mm")}
												</Typography>
											</Grid> */}
											{/* <Grid item md={6} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													Last Updated on:
												</Typography>
											</Grid>
											<Grid item md={6} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													{moment(invoiceDetail?.invoice?.updated_at).format(
														"DD-MMM-YYYY hh:mm"
													)}
												</Typography>
											</Grid> */}
										</Grid>
									</Box>
									<Box
										className={classes.headingBg}
										sx={{ bgcolor: Colors.primary, marginTop:{xs: "17% !important", md:"19.2% !important"} }}
									>
										<Typography variant="h4" className={classes.heading}>
											Shipping Information
										</Typography>
									</Box>
									<Box sx={{ ml: 4, mb: 4 }}>
										<Grid container spacing={1.34}>
											<Grid item md={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Pick up from
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.pickup_from ?? "-"}
												</Typography>
											</Grid>
											<Grid container spacing={0} mt={"2%"}>
												{invoiceDetail?.uae_location && (
													<Grid container spacing={0} ml={1.1}>
														<Grid item md={5} sm={5} xs={5}>
															<Typography
																variant="body1"
																className={classes.text}
															>
																UAE Location:
															</Typography>
														</Grid>
														<Grid item md={6} sm={6} xs={6} ml={0.8}>
															<Typography
																variant="body1"
																className={classes.text}
															>
																{invoiceDetail?.uae_location ?? "-"}
															</Typography>
														</Grid>
													</Grid>
												)}
											</Grid>

											<Grid item mmd={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Final Destination:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.destination?.name ?? "-"}
												</Typography>
											</Grid>
											<Grid item mmd={5} sm={5} xs={5}>
												<Typography variant="body1" className={classes.text}>
													Payment at:
												</Typography>
											</Grid>
											<Grid item md={6} sm={6} xs={6}>
												<Typography variant="body1" className={classes.text}>
													{invoiceDetail?.payment_at ?? "-"}
												</Typography>
											</Grid>
										</Grid>
									</Box>
									<Box
										className={classes.headingBg}
										sx={{ bgcolor: Colors.primary, }}
									>
										<Typography variant="h4" className={classes.heading}>
											Commercial
										</Typography>
									</Box>
									<Box sx={{ mr: 3, mb: 8 }}>
										<Grid container spacing={0} justifyContent={"flex-end"}>

											<Grid item md={4} sm={4} xs={4}></Grid>
											<Grid item md={8} sm={8} xs={8}></Grid>

											<Grid item md={4}>
												<Typography
													variant="subtitle2"
													sx={{ fontFamily: FontFamily.NunitoRegular }}
												>
													Particular
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4}>
												<Typography
													variant="subtitle2"
													sx={{
														textAlign: "center",
														fontFamily: FontFamily.NunitoRegular,
													}}
												>
													USD
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4}>
												<Typography
													variant="subtitle2"
													sx={{
														textAlign: "center",
														fontFamily: FontFamily.NunitoRegular,
													}}
												>
													AED
												</Typography>
											</Grid>

											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													Price:
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.price
														? CommaSeparator(parseFloat(invoiceDetail?.price)?.toFixed(2))
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.price
														? CommaSeparator(parseFloat(
															invoiceDetail?.price *
															currencyExchangeRate
														)?.toFixed(2))
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													Discount:
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.discount
														? CommaSeparator(parseFloat(invoiceDetail?.discount)?.toFixed(
															2
														))
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.discount
														? CommaSeparator(parseFloat(
															invoiceDetail?.discount *
															currencyExchangeRate
														)?.toFixed(2))
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													Other Charges:
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.other_charges
														? parseFloat(invoiceDetail?.other_charges)?.toFixed(
															2
														)
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.other_charges
														? parseFloat(
															invoiceDetail?.other_charges *
															currencyExchangeRate
														)?.toFixed(2)
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography variant="body1" className={classes.text}>
													Final Price:
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.final_price
														? CommaSeparator(parseFloat(
															invoiceDetail?.final_price
														)?.toFixed(2))
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.final_price
														? CommaSeparator(parseFloat(
															invoiceDetail?.final_price *
															currencyExchangeRate
														)?.toFixed(2))
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "left" }}
												>
													Paid:
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{CommaSeparator(parseFloat(invoiceDetail?.vendor_paid)?.toFixed(2))

														?? "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{invoiceDetail?.vendor_paid
														? CommaSeparator(parseFloat(
															invoiceDetail?.vendor_paid *
															currencyExchangeRate
														)?.toFixed(2))
														: "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "left" }}
												>
													Balance:
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{CommaSeparator(parseFloat(invoiceDetail?.final_price) - parseFloat(invoiceDetail?.vendor_paid))

														?? "00"}
												</Typography>
											</Grid>
											<Grid item md={4} sm={4} xs={4} className={classes.tableCell}>
												<Typography
													variant="body1"
													className={classes.text}
													sx={{ textAlign: "center" }}
												>
													{CommaSeparator(parseFloat((parseFloat(invoiceDetail?.final_price) - parseFloat(invoiceDetail?.vendor_paid)) * currencyExchangeRate)) ?? "0.00"}
												</Typography>
											</Grid>
										</Grid>
									</Box>

								</Grid>

							</Grid>
							<Box
								className={classes.headingBg}
								sx={{ bgcolor: Colors.bluishCyan, width: "46%", mt: -4 }}
							>
								<Typography variant="h4" className={classes.heading}>
									PAYMENT HISTORY
								</Typography>
							</Box>
							<Grid
								container
								spacing={2}
								justifyContent="space-between"
								alignItems="center"
								sx={{
									
								}}
							>
								<Grid item md={5.6} sm={5.6} xs={5.6} sx={{
								}}>
									<Box sx={{}}>
										{paymentDetail?.pay_history?.rows.length > 0 ? (
											<Grid container spacing={0} justifyContent="flex-end">
												<Grid item xs={3} sm={3} md={3}>
													<Typography
														variant="subtitle2"
														sx={{ fontFamily: FontFamily.openSans, textAlign: "center" }}
													>
														Paid on
													</Typography>
												</Grid>
												<Grid item xs={3} sm={3} md={3}>
													<Typography
														variant="subtitle2"
														sx={{
															textAlign: "center",
															fontFamily: FontFamily.openSans,
														}}
													>
														Payment Mode
													</Typography>
												</Grid>
												<Grid item xs={3} sm={3} md={3}>
													<Typography
														variant="subtitle2"
														sx={{
															textAlign: "center",
															fontFamily: FontFamily.openSans,
														}}
													>
														{invoiceDetail?.invoice?.booking?.currency?.toUpperCase() ?? "USD"}
													</Typography>
												</Grid>
												<Grid item xs={3} sm={3} md={3}>
													<Typography
														variant="subtitle2"
														sx={{
															textAlign: "center",
															fontFamily: FontFamily.openSans,
														}}
													>
														AED
													</Typography>
												</Grid>
												{paymentDetail?.pay_history?.rows.map((item, index) => {
													const isAed = item?.payment?.payment_medium_name?.includes("AED");
													return (
														<Fragment key={index}>
															<Grid item xs={3} sm={3} md={3} className={classes.tableCell}>
																<Typography variant="body1" className={classes.text}>
																	{item
																		? moment(item?.payment_date ? item?.payment_date : item?.created_at).format("DD-MMM-YY")
																		: "-"}
																</Typography>
															</Grid>
															<Grid item xs={3} sm={3} md={3} className={classes.tableCell}>
																<Typography
																	variant="body1"
																	className={classes.text}
																	sx={{ textAlign: "center" }}
																>
																	{item?.payment_medium === "vault"
																		? "Wallet"
																		: item?.payment_medium === "bank"
																			? `BANK-${isAed ? "AED" : "USD"}`
																			: isAed
																				? "CASH-AED"
																				: "CASH-USD"}
																</Typography>
															</Grid>
															<Grid item xs={3} sm={3} md={3} className={classes.tableCell}>
																<Typography
																	variant="body1"
																	className={classes.text}
																	sx={{ textAlign: "right" }}
																>
																	{CommaSeparator(parseFloat(item?.amount)?.toFixed(2))}
																</Typography>
															</Grid>
															<Grid item xs={3} sm={3} md={3} className={classes.tableCell}>
																<Typography
																	variant="body1"
																	className={classes.text}
																	sx={{ textAlign: "right" }}
																>
																	{CommaSeparator(parseFloat(item?.amount * 3.675)?.toFixed(2))}
																</Typography>
															</Grid>
														</Fragment>
													);
												})}
											</Grid>
										) : (
											<Typography
												variant="subtitle1"
												sx={{
													textAlign: "center",
													fontFamily: FontFamily.openSans,
												}}
											>
												No Payment History
											</Typography>
										)}
									</Box>
								</Grid>
								<Grid item md={2} sm={2} xs={2} container justifyContent="flex-end">
									<Box sx={{ textAlign: "right", height: "100%", display: "flex", alignItems: "center" }}>
										<QRCodeCanvas
											value={
												window.location.origin +
												`/export-invoice-preview/${btoa("vehicleinvoice-" + id)}`
											}
											size={100} // Customize QR code size if necessary
										/>
									</Box>
								</Grid>
							</Grid>

							<Grid container spacing={0} justifyContent={"space-between"}>
								<Grid item md={9.5} sm={9.5} xs={9.5}>
									{/* <Box sx={{ pl: 4, pr: 3, mb: 3, mt: 4 }}>
									<Typography variant="body1" sx={{ mb: 2 }}>
										PLEASE READ CAREFULLY BELOW TERM & CONDITION:
									</Typography>
									<Typography
										variant="body2"
										className={classes.text}
										sx={{ mb: 1 }}
									>
										1 - I've clearly informed and the make the understand all
										the vehicle information, amount, charges and rates.
									</Typography>
									<Typography
										variant="body2"
										className={classes.text}
										sx={{ mb: 1 }}
									>
										2 - Kindly pay the due amount within 3 business days from
										the purchase date to avoid the Late Payment and Storages
										that will be charged once vehicle arrived to final
										destination (Further, If there are some special
										annousment/memo ignore this and follow that)
									</Typography>
									<Typography
										variant="body2"
										className={classes.text}
										sx={{ mb: 1 }}
									>
										3 - If vehicle got relisted, the relist charges customer has
										to pay within 3 days otherwise 15% Penalty will applied
										after 3 days as issued memo on 9/Jun/2022.
									</Typography>
									<Typography
										variant="body2"
										className={classes.text}
										sx={{ mb: 1 }}
									>
										4 - Galaxy Customer care department will inform you about
										the latest updates about rates and charges through WhatsApp
										and emails.
									</Typography>
								</Box> */}
								</Grid>

							</Grid>
							<Box sx={{ pl: 4, pr: 3, py: 2, bgcolor: Colors.primary, mt: 4, textAlign: "center" }}>
							<Typography
									variant="caption"
									sx={{ color: Colors.white, fontFamily: FontFamily.openSans,fontWeight:400, fontSize: 12, textAlign: "center" }}
								>
									Customer Care Contact: Mohammed Rafi 00971 54 583 6030 or Mr Hamdan 00974 54 583 6022.
								</Typography>
								<Typography
									variant="caption"
									sx={{ color: Colors.white, fontFamily: FontFamily.openSans, fontWeight: 700, fontSize: 12, textAlign: "center" }}
								>
									{''}  For complaints, Please WhatsApp @ +4917663203237
								</Typography>
								{/* <Typography
									variant="caption"
									sx={{ color: Colors.white, fontFamily: FontFamily.NunitoRegular }}
								>
									Ravin abdul kareem - +971528293801 (Kurdish , Arabic & English)
									Magsat Gylyjov - +97158666403 (Turken , Russian & English)
								</Typography> */}
							</Box>
						</Box>
					) : (
						<Box sx={{ textAlign: "center", py: 3 }}>
							<CircularProgress />
						</Box>
					)}
				</Box>
			</PDFExport>
		</Container>
	);
}

export default ExportVehiclePreview;
