import React, { Fragment, useRef } from "react";
import {
    Box,
    CardMedia,
    CircularProgress,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
import { PrimaryButton } from "components/Buttons";
import moment from "moment";
import LogoImage from "assets/Images/header_voucher.svg";
import { makeStyles } from "@mui/styles";
import Colors from "assets/Style/Colors";
import { QRCodeCanvas } from "qrcode.react";
import {
    FontFamily,
    Images,
    InvoiceGlobal,
    InvoiceLocation,
    InvoiceMail,
    InvoicePhone,
} from "assets";
import { CommaSeparator } from "utils";

const useStyle = makeStyles({
    headingBg: {
        margin: "32px 0px",
        padding: "12px 0px",
        textAlign: "center",
    },
    heading: {
        color: Colors.white,
        textTransform: "uppercase",
        fontWeight: 300,
        fontFamily: FontFamily.openSans,
    },
    text: {
        color: "rgba(0, 0, 0, 0.6)",
        fontWeight: 400,
        fontFamily: FontFamily.openSans,
        fontSize: 14
    },
    tableCell: {
        backgroundColor: Colors.aliceBlue,
        border: "0.5px solid rgba(0, 0, 0, 0.6)",
        "& .MuiTypography-root": {
            padding: "4px 12px",
        },
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    },
});
const PlanDetail = () => {
    const { state } = useLocation(); // Retrieve passed vehicle details
    const pdfExportComponent = useRef(null);
    const classes = useStyle();
    const vehicleDetails = state?.vehicles || []; // Fallback to empty array if no data is received
    const handleDownloadPDF = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };
    const bookingRefNo =  vehicleDetails?.booking_ref_no

    return (
        <Container>
            <Box sx={{ textAlign: "right", p: 4 }}>
                <PrimaryButton title="Download Plan" onClick={handleDownloadPDF} />
            </Box>

            <Box
                sx={{
                    width: "1000px",
                    mx: "auto",
                    my: 2,
                    bgcolor: Colors.white,
                    boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                    padding: "20px",
                }}
            >
                <PDFExport ref={pdfExportComponent} fileName={`Vehicle_Receipt_${bookingRefNo}`}>
                    <Box>
                        {/* 🚀 HEADER SECTION */}
                        <Grid container spacing={0}>
								<Grid item xs={3.5} sm={3.5} md={3.5}>
									<Box
										component={"img"}
										src={Images.logo}
										sx={{ height: "150px", ml: 2 }}
									/>
								</Grid>
								<Grid item xs={8.5} sm={8.5} md={8.5}>
									<CardMedia image={Images.invoiceHeader} sx={{ mb: 2 }}>
										<Typography
											variant="h3"
											sx={{ py: 3, textAlign: "center", color: Colors.white }}
										>
											Galaxy World Wide Shipping
										</Typography>
									</CardMedia>
									<Grid
										container
										spacing={1.5}
										alignItems={"center"}
										justifyContent={"space-evenly"}
									>
										<Grid item xs={4} sm={4} md={4}>
											<Box
												sx={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<InvoicePhone />
												<Typography
													variant="body1"
													fontFamily={FontFamily.openSans}
													sx={{
														color: Colors.smokeyGrey,
														fontFamily: FontFamily.openSans,
													}}
												>
													+971 6 510 2000
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={6} sm={6} md={6} >
											<Box
												sx={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<InvoiceMail />
												<Typography
													variant="body1"
													fontFamily={FontFamily.openSans}
													sx={{
														color: Colors.smokeyGrey,
														fontFamily: FontFamily.openSans,
													}}
												>
													info@galaxyshipping.com
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={4} sm={4} md={4}>
											<Box
												sx={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<InvoiceGlobal />
												<Typography
													variant="body1"
													fontFamily={FontFamily.openSans}
													sx={{
														color: Colors.smokeyGrey,
														fontFamily: FontFamily.openSans,
													}}
												>
													https://galaxyshipping.com
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={6} sm={6} md={6}>
											<Box
												sx={{
													display: "flex",
													gap: "5px",
													alignItems: "center",
												}}
											>
												<InvoiceLocation />
												<Typography
													variant="body1"
													fontFamily={FontFamily.openSans}
													sx={{
														color: Colors.smokeyGrey,
														fontFamily: FontFamily.openSans,
													}}
												>
													Ind Area#4 P.O Box 83126, Sharjah , UAE
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

                        {/* 🚀 SECTION TITLE */}
                        <Box sx={{ my: 5, position: "relative", bgcolor: Colors.bluishCyan, width: "100%", height: "12px" }}>
                            <Typography
                                component={"span"}
                                variant="h2"
                                sx={{
                                    color: Colors.charcoalGrey,
                                    bgcolor: Colors.white,
                                    p: 2,
                                    letterSpacing: "3px",
                                    position: "absolute",
                                    right: "90px",
                                    top: "-40px",
                                }}
                            >
                               Loading Plan
                            </Typography>
                        </Box>
                        <Grid item md={5.5}>
                            <Box sx={{ ml: 4 }}>
                                <Grid container spacing={1} direction="column">
                                    {/* Created By */}
                                    <Grid container spacing={2}>
                                        <Grid item md={3}>
                                            <Typography variant="body1" className={classes.text}>
                                                Created By:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9}>
                                            <Typography variant="body1" className={classes.text} sx={{
                                            color: Colors.smokeyGrey,
                                            fontFamily: FontFamily.openSans,
                                        }}>
                                                {vehicleDetails?.created_by || "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {/* Created On */}
                                    <Grid container spacing={2}>
                                        <Grid item md={3}>
                                            <Typography variant="body1" style={{ fontWeight: "bold" }} className={classes.text}>
                                                Created On:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9}>
                                            <Typography variant="body1" sx={{
                                            color: Colors.smokeyGrey,
                                            fontFamily: FontFamily.openSans,
                                        }} className={classes.text}>
                                                {vehicleDetails?.created_at ? moment(vehicleDetails?.created_at).format("DD-MMM-YYYY") : "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item md={3}>
                                            <Typography variant="body1" style={{ fontWeight: "bold" }} className={classes.text}>
                                                Towing Cost:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9}>
                                            <Typography variant="body1" sx={{
                                                color: Colors.smokeyGrey,
                                                fontFamily: FontFamily.openSans,
                                            }} className={classes.text}>
                                                {CommaSeparator(parseFloat(vehicleDetails?.towing_cost).toFixed(2)) ?? "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item md={3}>
                                            <Typography variant="body1" style={{ fontWeight: "bold" }} className={classes.text}>
                                                Shipping Cost:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9}>
                                            <Typography variant="body1" sx={{
                                                color: Colors.smokeyGrey,
                                                fontFamily: FontFamily.openSans,
                                            }} className={classes.text}>
                                                {CommaSeparator(parseFloat(vehicleDetails?.shipping_cost).toFixed(2)) ?? "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item md={3}>
                                            <Typography variant="body1" style={{ fontWeight: "bold" }} className={classes.text}>
                                                Total Cost:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9}>
                                            <Typography variant="body1" sx={{
                                                color: Colors.smokeyGrey,
                                                fontFamily: FontFamily.openSans,
                                            }} className={classes.text}>
                                                {CommaSeparator(parseFloat(vehicleDetails?.totalCost).toFixed(2)) ?? "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {/* 🚀 VEHICLE DETAILS */}
                        <Grid container spacing={2}>


                            <Grid container spacing={0} justifyContent={"space-between"} sx={{ my: 5 }}>
                                <Grid item md={12}>
                                    <Box className={classes.headingBg} sx={{ bgcolor: Colors.primary }}>
                                        <Typography variant="h4" className={classes.heading}>
                                            Vehicles In The Plan
                                        </Typography>
                                    </Box>

                                    <Box sx={{ mx: 2 }}>
                                        {vehicleDetails?.vehicles.length > 0 ? (
                                            <>
                                                {/* Table Header */}
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    columns={12}
                                                    justifyContent={"flex-end"}
                                                    sx={{
                                                        border: "0.5px solid rgba(0,0,0,0.6)",
                                                        backgroundColor: "#f0f0f0",
                                                    }}
                                                >
                                                    {["VIN", "Lot", "Model", "Location","Load Port", "Destination", ].map(
                                                        (head, idx) => (
                                                            <Grid key={idx} item md={2} style={{ textAlign: "center" }}>
                                                                <Typography variant="subtitle2">{head}</Typography>
                                                            </Grid>
                                                        )
                                                    )}
                                                </Grid>

                                                {/* Table Data */}
                                                <Grid container spacing={0} columns={12} justifyContent={"flex-end"} textAlign={"center"}>
                                                    {vehicleDetails?.vehicles?.map((vehicle, index) => (
                                                        <Fragment key={index}>
                                                            <Grid item md={2} className={classes.tableCell}>
                                                                <Typography variant="body2" sx={{fontFamily: FontFamily.openSans,}} className={classes.text}>{vehicle.vin}</Typography>
                                                            </Grid>
                                                            <Grid item md={2} className={classes.tableCell}>
                                                                <Typography variant="body2" sx={{fontFamily: FontFamily.openSans,}} className={classes.text}>{vehicle.lot_number}</Typography>
                                                            </Grid>
                                                            <Grid item md={2} className={classes.tableCell}>
                                                                <Typography variant="body2" sx={{fontFamily: FontFamily.openSans,}} className={classes.text}>{vehicle.veh_model?.name}</Typography>
                                                            </Grid>
                                                            <Grid item md={2} className={classes.tableCell}>
                                                                <Typography variant="body2" sx={{fontFamily: FontFamily.openSans,}} className={classes.text}>{vehicle.source || "-"}</Typography>
                                                            </Grid>
                                                            
                                                            <Grid item md={2} className={classes.tableCell}>
                                                                <Typography variant="body2" sx={{fontFamily: FontFamily.openSans,}} className={classes.text}>{vehicle.loading_port || "-"}</Typography>
                                                            </Grid>
                                                            <Grid item md={2} className={classes.tableCell}>
                                                                <Typography variant="body2" sx={{fontFamily: FontFamily.openSans,}} className={classes.text}>{vehicle.destination || "-"}</Typography>
                                                            </Grid>
                                                        </Fragment>
                                                    ))}
                                                </Grid>

                                            </>
                                        ) : (
                                            <Typography textAlign="center" color="gray">
                                                No vehicle details available
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                      

                        {/* 🚀 CONTACT SUPPORT */}
                        <Box sx={{ pl: 4, pr: 3, py: 1, bgcolor: Colors.primary }}>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: Colors.white,
                                    fontFamily: FontFamily.openSans,
                                    fontWeight: 400
                                }}
                            >
                                Customer care Contact: Mohammed husni - +971523195682 (Arabic
                                & English ) Ardamehr Shoev - +971545836028 (English ,Arabic,
                                Tajik & Farsi)
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: Colors.white,
                                    fontFamily: FontFamily.openSans,
                                    fontWeight: 400

                                }}
                            >
                                Ravin abdul kareem - +971528293801 (Kurdish , Arabic &
                                English) Magsat Gylyjov - +97158666403 (Turken , Russian &
                                English)
                            </Typography>
                        </Box>
                    </Box>
                </PDFExport>
            </Box>
        </Container>
    );
};

export default PlanDetail;
